import { product } from './'

export const products = (data, region = 'es', locale = 'es') => {
  const items = data.products ?? data.packs
  const productsPage = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": items.map((productItem, idx) => ({
      "@type": "ListItem",
      "position": `${idx + 1}`,
      "item": product(productItem, region, locale, false, data?.slug?.[locale])
    }))
  }

  return JSON.stringify(productsPage)
}
