import { memo, useMemo } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { mq, getRelative, getP15_1 } from '@/styles'

const Section = styled.section`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 3;
  
  ${mq.greaterThan('tablet')`
    grid-column: 6 / span 2;
    grid-row: 1 / span 2;
  `}
       
  li {
    align-items: center;
    display: flex;
    margin-bottom: ${getRelative(15, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${getRelative(15, 'desktop')};
    `}

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  a {
    color: ${({ theme }) => theme.colors.background};
    display: inline-block;
    font-family: ${({ theme }) => theme.fonts.basier};
    ${getP15_1()}
    font-size: ${getRelative(15, 'mobile')};
    opacity: 1;
    text-decoration: none;
    transition: 300ms opacity ease-out;

    &:hover {
      opacity: .4;
    }
  }
 
`
const NEXT_LOCALE = process.env.NEXT_PUBLIC_LOCALE

const getListItems=(categories) => categories.map((link,idx)=> {
  const { title , url} = link
  return(
    <li key={idx}>
      <Link href={`/shop/${url?.slug[NEXT_LOCALE]}`}>
          {title}
      </Link>
    </li>
    )

}) 

interface ISectionPagesNav {
  categories: any
} 
export const SectionPagesNav = memo(({categories}:ISectionPagesNav) => {
  // const { locale } = useRouter() || { locale: 'es' }
  const ListItems = useMemo(() => getListItems(categories), [categories])
  

  return (
    <Section>
      <ul className='nav-list'>
        {ListItems}
      </ul>
    </Section>
  )
})
