"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFormattedLocal = void 0;
var DEFAULT_INTERPOLATION = {
    prefix: '\{{( ?',
    sufix: ' ?)\}}',
    globalRegex: /\{{(.*?)\}}/
};
var createRegex = function (key) { return new RegExp(DEFAULT_INTERPOLATION.prefix + key + DEFAULT_INTERPOLATION.sufix, 'g'); };
var getFormattedLocal = function (locale, interpolationObject, currentLanguage) {
    var formattedLocal = locale + '';
    var keys = Object.keys(interpolationObject);
    var values = Object.values(interpolationObject);
    if (!keys.length)
        throw new Error("Interpolation object can't be empty");
    keys.forEach(function (item, idx) {
        var re = createRegex(item);
        var formattedText = formattedLocal.replace(re, values[idx]);
        if (formattedText === formattedLocal)
            throw new Error("No interpolation found with key \"".concat(item, "\" and language \"").concat(currentLanguage, "\""));
        formattedLocal = formattedText + '';
    });
    return formattedLocal;
};
exports.getFormattedLocal = getFormattedLocal;
