import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { mq, getRelative, hex2Rgba, getP53, getP15_1 } from '@/styles'
import { usei18n } from '@dy/commons/i18n'

type ProductSpecification = {
  name: string,
  value: string | number,
  type: 'weight' | 'energy'
}

type TypeSectionMoreInfo = {
  data: ProductSpecification[]
}

const H2 = styled.h2`
  ${getP53()}
  color: ${({ theme }) => theme.colors.greendark};
  margin: ${getRelative(60, 'mobile')} 0 ${getRelative(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin: ${getRelative(80, 'desktop')} 0 ${getRelative(20, 'desktop')};
  `}
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  tr:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.greenlight, .2)};
  }

  td {
    ${getP15_1()}
    padding: ${getRelative(16.5, 'mobile')} 0;
    vertical-align: top;
    width: 50%;

    ${mq.greaterThan('tablet')`
      padding: ${getRelative(16.5, 'desktop')} 0;
    `}

    &:nth-child(odd) {
      color: ${({ theme }) => theme.colors.greendark};
    }
  }
`

export const getInfo = details => details.map((detail, idx) => {
  return (
    <tr key={idx}>
      <td>{detail.name}</td><td>{detail.value}</td>
    </tr>
  )
})

export const SectionMoreInfo = memo<TypeSectionMoreInfo>(({ data }) => {
  const { layout } = usei18n()
  const Information = useMemo(() => getInfo(data), [data])

  return (
    <section>
      <H2>{layout.product_page.product_description.more_info.title}</H2>
      <Table>
        <tbody>
          {Information}
        </tbody>
      </Table>
    </section>
  )
})
