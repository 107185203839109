import { useMemo } from 'react'
import styled from 'styled-components'
import { mq, getRelative, createGrid } from '@/styles'
import { CallUs } from './CallUs'
import { SectionPagesNav } from './SectionPagesNav'
import { TypePage, SectionPages } from './SectionPages'
import { PreFooter } from './PreFooter'
import dynamic from 'next/dynamic'

const DynamicSectionRrss = dynamic(() => import('./SectionRrss'))

const FooterStyled = styled.footer`
  ${createGrid()}
  background-color: ${({ theme }) => theme.colors.greenlight};
  padding: ${getRelative(70, 'mobile')} ${getRelative(20, 'mobile')} ${getRelative(50, 'mobile')};
  position: relative;
  z-index: 2;

  ${mq.greaterThan('tablet')`
    padding: ${getRelative(100, 'desktop')} 0 ${getRelative(60, 'desktop')};
  `}

  
`
interface IFooter {
  pages?: TypePage[],
}

export const Footer = ({pages = []}:IFooter) => {
  const categoriesFooter = useMemo(()=> pages.filter((el)=> el?.url?.page === 'category'),[pages])
  const pagesFooter = useMemo(()=> pages.filter((el)=> el?.url?.page !== 'category'),[pages])
  return (
    <>
      <PreFooter/>
      <FooterStyled>
        <CallUs />
        {pages?.length > 0 && <SectionPagesNav categories={categoriesFooter}/>}
        {pages?.length > 0 && <SectionPages pages={pagesFooter}/>}
        <DynamicSectionRrss />
      </FooterStyled>
    </>
  )
}
