import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, getRelative, createUnderline } from '@/styles'
import { listVariants, itemVariants } from './ModalMenuMobile'

const Ul = styled(motion.ul)`
  display: none;

  ${mq.greaterThan('tablet')`
    display: flex;
    grid-column: 4 / span 3;
  `}

  button,
  a {
    display: inline-block;
    font-family: ${({ theme }) => theme.fonts.platform};
    padding-bottom: 4px;

    ${mq.greaterThan('tablet')`
      font-weight: bold;
      padding: 0 ${getRelative(15, 'desktop')};
      position: relative;
      ${createUnderline({ height: 2, color: 'greenlight' })}
    `}

    > span {
      right: ${getRelative(30, 'desktop')};
      max-width: calc(100% - ${getRelative(30, 'desktop')});
    }
  }
`

const getListItems = (links, pathname, locale, menuMobile, increaseLevel, firstCategory, theresPacks) => {
  const Links = []

  for (let idx = 0; idx < links.length; idx++) {
    const linkData = links[idx]
    const { name, slug } = linkData
    const isShopLink = slug === '/shop'
    const _name = Translations[locale][name]

    if (!theresPacks && name === 'Packs') continue

    if (isShopLink && firstCategory) {
      //@ts-ignore
      window.NO_URL_KLK = `/shop/${firstCategory.slug[locale]}`
    }

    Links.push(
      <motion.li key={idx} variants={itemVariants}>
        {menuMobile && isShopLink ?
          <button onClick={increaseLevel} className={pathname.includes(slug) ? 'active' : ''}>{_name}<span></span></button>
        :
          <Link href={isShopLink && firstCategory ? `/shop/${firstCategory.slug[locale]}` : slug} >
            <a className={pathname.includes(slug) ? 'active' : ''}>{_name}<span></span></a>
          </Link>
        }
      </motion.li>
    )
  }

  return Links
}

const Translations = {
  'it': {
    'Home': 'Home',
    'Tienda': 'Negozio',
    'Mis habituales': 'Il solito',
    'Packs': 'Packs',
  },
  'es': {
    'Home': 'Home',
    'Tienda': 'Tienda',
    'Mis habituales': 'Mis habituales',
    'Packs': 'Packs',
  },
  'en': {
    'Home': 'Home',
    'Tienda': 'Shop',
    'Mis habituales': 'My Favourites',
    'Packs': 'Packs',
  },
  'fr': {
    'Home': 'Accueil',
    'Tienda': 'Boutique',
    'Mis habituales': 'Mon historique',
    'Packs': 'Packs',
  },
}

const fakeList = [{ name: 'Tienda', slug: '/shop'}, { name: 'Mis habituales', slug: '/account/usual-purchases'}, { name: 'Packs', slug: '/packs'}]

interface INavList {
  footer?: boolean,
  theresPacks?: boolean,
  menuMobile?: boolean,
  increaseLevel?: null | (() => void),
  firstCategory?: any | null,
  isActive?: boolean
}

export const NavList = memo(({ footer = false, menuMobile = false, increaseLevel = null, firstCategory = null, isActive = false, theresPacks = false }:INavList) => {
  const { locale, pathname } = useRouter() || { pathname: '', locale: 'es' }
  const ListItems = useMemo(() => getListItems(fakeList, pathname, locale, menuMobile, increaseLevel, firstCategory, theresPacks), [fakeList, pathname, locale, firstCategory])

  return (
    <Ul className='nav-list' initial='inactive' animate={isActive || !menuMobile ? 'active' : 'exit'} exit='exit' variants={listVariants}>
      {(footer || menuMobile) && <motion.li variants={itemVariants}>
        <Link href='/'>
          <a className={pathname === '/' ? 'active' : ''}>{Translations[locale].Home}</a>
        </Link>
      </motion.li>}
      {ListItems}
    </Ul>
  )
})
