export interface ModalState {
  [id: string]: {
    isActive: boolean,
    payload?: any
  }
}

export const TRIGGER_MODAL = 'MODAL/TRIGGER'

interface TriggerModalAction {
  type: typeof TRIGGER_MODAL;
  payload: ModalState;
}

export type ModalActionTypes = TriggerModalAction;
