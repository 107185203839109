import { memo } from 'react'
import styled from 'styled-components'
import { mq, getRelative, getMargin10, getP10 } from '@/styles'
import { Figure as Fig } from '@dy/commons/components'
import { parserMedia } from '@/utils'

const Figure = styled(Fig)<any>`
  height: auto;
  width: ${({ size, theme: { col } }) => size === 'small' ? `${getRelative(col.mobile * 4, 'mobile')}` : size === 'medium' ? `${getRelative(col.mobile * 6, 'mobile')}` : '100%'};

  ${mq.greaterThan<any>('tablet')`
    width: ${({ size, theme: { col } }) => size === 'small' ? `${getRelative(col.desktop * 4, 'desktop')}` : size === 'medium' ? `${getRelative(col.desktop * 8, 'desktop')}` : '100%'};
  `}

  > span {
    position: relative !important;
    height: auto;
    width: 100%;
  }

  img {
    position: relative !important;
    height: 100% !important;
    width: 100% !important;
  }

  figcaption {
    color: ${({ theme }) => theme.colors.greendark};
    font-family: ${({ theme }) => theme.fonts.platform};
    font-style: normal;
    ${getP10()}
    ${getMargin10(true)}
    opacity: .5;
    text-align: center;
    text-transform: uppercase;
  }
`

type ContentTypeImage = {
  col: { from: number, expand: number},
  row: { from: number, expand: number},
  media: any,
  alt: string,
  caption?: string,
  type: 'image' | 'media',
  media_caption?: string
  media_size?: 'small' | 'medium' | 'big'
}

interface IContentImage {
  data: ContentTypeImage
}

export const ContentImage = memo(({ data }:IContentImage) => {
  const { media, media_caption, media_size } = data

  return (
    <Figure media={parserMedia({...media, media_caption})} size={media_size}/>
  )
})
