import { ProductInterface } from 'redux/product/product.types'
import { SeoInterface, SlugInterface } from '../types/global/global.types'

export interface CategoryInterface {
  name: string,
  seo: SeoInterface,
  slug: SlugInterface,
  description: string,
  products: ProductInterface[]
}

export const FETCH_CATEGORY_PENDING = 'CATEGORY/FETCH_PENDING'
export const FETCH_CATEGORY_SUCCESS = 'CATEGORY/FETCH_SUCCESS'
export const FETCH_CATEGORY_ERROR   = 'CATEGORY/FETCH_ERROR'

export interface CategoryFetchSuccessAction {
  type: typeof FETCH_CATEGORY_SUCCESS,
  payload: CategoryInterface
}

export interface CategoryFetchPendingAction {
  type: typeof FETCH_CATEGORY_PENDING,
  payload: boolean
}

export interface CategoryFetchErrorAction {
  type: typeof FETCH_CATEGORY_ERROR,
  payload: TypeError
}