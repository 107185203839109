import React, { FC, Fragment, useState, useEffect, memo, useMemo } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'

import { useMediaType } from '@/hooks'
import { defaultGridOptions, getRawRelative as getRelative, mq } from '@/styles'

interface GridOptions {
  readonly columns?: number,
  readonly columnWidth?: number,
  readonly gutterWidth?: number,
  readonly maxWidth?: number
}

interface GridProps {
  readonly mobile?: GridOptions,
  readonly tablet?: GridOptions,
  readonly desktop?: GridOptions,
}

interface ColumnProps {
  readonly columnIndex: number,
  readonly color: string,
  readonly border: string,
}

const overrideDefaultGridOptions = (gridOptions: any): any => {
  return {
    mobile: {
      ...defaultGridOptions.mobile,
      ...gridOptions.mobile
    },
    tablet: {
      ...defaultGridOptions.tablet,
      ...gridOptions.tablet
    },
    desktop: {
      ...defaultGridOptions.desktop,
      ...gridOptions.desktop
    },
  }
}

// TODO:-  Change the `any` type to their proper interface -> GridProps
// - It does complain when is inside the `mq`
const SketchGrid = styled.div<GridProps>`
  display: grid;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  pointer-events: none;
  user-select: none;
  grid: ${ ({ mobile }) => `100% / repeat(${mobile.columns}, ${getRelative(mobile.columnWidth, mobile.maxWidth)}%)`};
  column-gap: ${(props: GridProps) => `${getRelative(props.mobile.gutterWidth, props.mobile.maxWidth)}%`};
  justify-content: center;
  padding: 0 ${getRelative(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    padding: 0;
    grid: ${(props: any) => `100% / repeat(${props.tablet.columns}, ${getRelative(props.tablet.columnWidth, props.tablet.maxWidth)}%)`};
    column-gap: ${(props: any) => `${getRelative(props.tablet.gutterWidth, props.tablet.maxWidth)}%`};
  `}

  ${mq.greaterThan('desktop-xs')`
    grid: ${ (props: any) => `100% / repeat(${props.desktop.columns}, ${getRelative(props.desktop.columnWidth, props.desktop.maxWidth)}%)`};
    column-gap: ${(props: any) => `${getRelative(props.desktop.gutterWidth, props.desktop.maxWidth)}%`};
  `}
`

const Column = styled.span<ColumnProps>`
  grid-area: ${({ columnIndex }) => `${1}/${columnIndex + 1}/${2}/${columnIndex + 1}`};
  ${({ border }) => {
    return css`border: ${border};`
  }}
`

const getColumns = (columns: number, color: string, border: string) => {
  const Columns = []

  for (let idx = 0; idx < columns; idx++) {
    Columns.push(<Column color={color} border={border} key={idx} columnIndex={idx} />)
  }

  return Columns
}


// TODO:- give proper type
interface SusyProps {
  gridOptions?: any,
  columnColor: string,
  columnBorder: string,
  hide: boolean,
  navCollapsed?:boolean
}

declare global {
  interface Window { hideSusy: any }
}

const isClient = () => typeof window === 'object';

const Susy: FC<SusyProps> = memo(({ gridOptions = defaultGridOptions, columnColor = 'rgba(86, 202, 202, .15)', columnBorder = 'none', hide = false, navCollapsed }) => {
  const mediaType = useMediaType()
  const [isHidden, setVisible] = useState(hide)
  const finalGridOptions = useMemo(() => overrideDefaultGridOptions(gridOptions), [gridOptions])
  const Columns = useMemo(() => getColumns(finalGridOptions[mediaType].columns, columnColor, columnBorder), [mediaType])

  useEffect(() => {
    window.hideSusy = setVisible
  }, [])

  return isClient() && !isHidden ? ReactDOM.createPortal(
    <SketchGrid {...finalGridOptions} navCollapsed={navCollapsed}>
      {Columns}
    </SketchGrid>,
    document.body) :
    <Fragment></Fragment>
})

export default Susy
