"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInput = void 0;
var react_1 = require("react");
var useInput = function (initialValue) {
    if (initialValue === void 0) { initialValue = ''; }
    var _a = __read((0, react_1.useState)(initialValue), 2), value = _a[0], setValue = _a[1];
    var reset = function () { return setValue(initialValue); };
    var onChange = function (_a) {
        var target = _a.target;
        switch (target.type) {
            case 'number':
            case 'range':
                setValue(parseFloat(target.value));
                break;
            default:
                setValue(target.value);
        }
    };
    return [value, onChange, reset];
};
exports.useInput = useInput;
