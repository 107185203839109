"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMargin200 = exports.getMargin150 = exports.getMargin120 = exports.getMargin100 = exports.getMargin80 = exports.getMargin70 = exports.getMargin60 = exports.getPadding50 = exports.getMargin50 = exports.getMargin40 = exports.getMargin30 = exports.getMargin20 = exports.getMargin10 = void 0;
var styled_components_1 = require("styled-components");
var _1 = require("./");
var getMargin10 = function (top) {
    if (top === void 0) { top = false; }
    if (top) {
        return (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      margin-top: ", ";\n\n      ", "\n    "], ["\n      margin-top: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(10, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        margin-top: ", ";\n      "], ["\n        margin-top: ", ";\n      "])), (0, _1.getRelative)(10, 'desktop')));
    }
    else {
        return (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      margin-bottom: ", ";\n\n      ", "\n    "], ["\n      margin-bottom: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(10, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        margin-bottom: ", ";\n      "], ["\n        margin-bottom: ", ";\n      "])), (0, _1.getRelative)(10, 'desktop')));
    }
};
exports.getMargin10 = getMargin10;
var getMargin20 = function (top) {
    if (top === void 0) { top = false; }
    if (top) {
        return (0, styled_components_1.css)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      margin-top: ", ";\n\n      ", "\n    "], ["\n      margin-top: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(16, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        margin-top: ", ";\n      "], ["\n        margin-top: ", ";\n      "])), (0, _1.getRelative)(20, 'desktop')));
    }
    else {
        return (0, styled_components_1.css)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      margin-bottom: ", ";\n\n      ", "\n    "], ["\n      margin-bottom: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(16, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        margin-bottom: ", ";\n      "], ["\n        margin-bottom: ", ";\n      "])), (0, _1.getRelative)(20, 'desktop')));
    }
};
exports.getMargin20 = getMargin20;
var getMargin30 = function () {
    return (0, styled_components_1.css)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    margin-bottom: ", ";\n\n    ", "\n  "], ["\n    margin-bottom: ", ";\n\n    ", "\n  "])), (0, _1.getRelative)(25, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      margin-bottom: ", ";\n    "], ["\n      margin-bottom: ", ";\n    "])), (0, _1.getRelative)(30, 'desktop')));
};
exports.getMargin30 = getMargin30;
var getMargin40 = function (top) {
    if (top === void 0) { top = false; }
    if (top) {
        return (0, styled_components_1.css)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n      margin-top: ", ";\n\n      ", "\n    "], ["\n      margin-top: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(28, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n        margin-top: ", ";\n      "], ["\n        margin-top: ", ";\n      "])), (0, _1.getRelative)(40, 'desktop')));
    }
    else {
        return (0, styled_components_1.css)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n      margin-bottom: ", ";\n\n      ", "\n    "], ["\n      margin-bottom: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(28, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n        margin-bottom: ", ";\n      "], ["\n        margin-bottom: ", ";\n      "])), (0, _1.getRelative)(40, 'desktop')));
    }
};
exports.getMargin40 = getMargin40;
var getMargin50 = function () {
    return (0, styled_components_1.css)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    margin-bottom: ", ";\n\n    ", "\n  "], ["\n    margin-bottom: ", ";\n\n    ", "\n  "])), (0, _1.getRelative)(40, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n      margin-bottom: ", ";\n    "], ["\n      margin-bottom: ", ";\n    "])), (0, _1.getRelative)(50, 'desktop')));
};
exports.getMargin50 = getMargin50;
var getPadding50 = function () {
    return (0, styled_components_1.css)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    padding-bottom: ", ";\n\n    ", "\n  "], ["\n    padding-bottom: ", ";\n\n    ", "\n  "])), (0, _1.getRelative)(40, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n      padding-bottom: ", ";\n    "], ["\n      padding-bottom: ", ";\n    "])), (0, _1.getRelative)(50, 'desktop')));
};
exports.getPadding50 = getPadding50;
var getMargin60 = function (top) {
    if (top === void 0) { top = false; }
    if (top) {
        return (0, styled_components_1.css)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n      margin-top: ", ";\n\n      ", "\n    "], ["\n      margin-top: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(48, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n        margin-top: ", ";\n      "], ["\n        margin-top: ", ";\n      "])), (0, _1.getRelative)(60, 'desktop')));
    }
    else {
        return (0, styled_components_1.css)(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n      margin-bottom: ", ";\n\n      ", "\n    "], ["\n      margin-bottom: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(48, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n        margin-bottom: ", ";\n      "], ["\n        margin-bottom: ", ";\n      "])), (0, _1.getRelative)(60, 'desktop')));
    }
};
exports.getMargin60 = getMargin60;
var getMargin70 = function () {
    return (0, styled_components_1.css)(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n    margin-bottom: ", ";\n\n    ", "\n  "], ["\n    margin-bottom: ", ";\n\n    ", "\n  "])), (0, _1.getRelative)(56, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n      margin-bottom: ", ";\n    "], ["\n      margin-bottom: ", ";\n    "])), (0, _1.getRelative)(70, 'desktop')));
};
exports.getMargin70 = getMargin70;
var getMargin80 = function () {
    return (0, styled_components_1.css)(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n    margin-bottom: ", ";\n\n    ", "\n  "], ["\n    margin-bottom: ", ";\n\n    ", "\n  "])), (0, _1.getRelative)(60, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n      margin-bottom: ", ";\n    "], ["\n      margin-bottom: ", ";\n    "])), (0, _1.getRelative)(80, 'desktop')));
};
exports.getMargin80 = getMargin80;
var getMargin100 = function () {
    return (0, styled_components_1.css)(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n    margin-bottom: ", ";\n\n    ", "\n  "], ["\n    margin-bottom: ", ";\n\n    ", "\n  "])), (0, _1.getRelative)(70, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n      margin-bottom: ", ";\n    "], ["\n      margin-bottom: ", ";\n    "])), (0, _1.getRelative)(100, 'desktop')));
};
exports.getMargin100 = getMargin100;
var getMargin120 = function (top) {
    if (top === void 0) { top = false; }
    if (top) {
        return (0, styled_components_1.css)(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n      margin-top: ", ";\n\n      ", "\n    "], ["\n      margin-top: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(96, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n        margin-top: ", ";\n      "], ["\n        margin-top: ", ";\n      "])), (0, _1.getRelative)(120, 'desktop')));
    }
    else {
        return (0, styled_components_1.css)(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n      margin-bottom: ", ";\n\n      ", "\n    "], ["\n      margin-bottom: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(96, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n        margin-bottom: ", ";\n      "], ["\n        margin-bottom: ", ";\n      "])), (0, _1.getRelative)(120, 'desktop')));
    }
};
exports.getMargin120 = getMargin120;
var getMargin150 = function (top) {
    if (top === void 0) { top = false; }
    if (top) {
        return (0, styled_components_1.css)(templateObject_34 || (templateObject_34 = __makeTemplateObject(["\n      margin-top: ", ";\n\n      ", "\n    "], ["\n      margin-top: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(120, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n        margin-top: ", ";\n      "], ["\n        margin-top: ", ";\n      "])), (0, _1.getRelative)(150, 'desktop')));
    }
    else {
        return (0, styled_components_1.css)(templateObject_36 || (templateObject_36 = __makeTemplateObject(["\n      margin-bottom: ", ";\n\n      ", "\n    "], ["\n      margin-bottom: ", ";\n\n      ", "\n    "])), (0, _1.getRelative)(120, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_35 || (templateObject_35 = __makeTemplateObject(["\n        margin-bottom: ", ";\n      "], ["\n        margin-bottom: ", ";\n      "])), (0, _1.getRelative)(150, 'desktop')));
    }
};
exports.getMargin150 = getMargin150;
var getMargin200 = function () {
    return (0, styled_components_1.css)(templateObject_38 || (templateObject_38 = __makeTemplateObject(["\n    margin-bottom: ", ";\n\n    ", "\n  "], ["\n    margin-bottom: ", ";\n\n    ", "\n  "])), (0, _1.getRelative)(150, 'mobile'), _1.mq.greaterThan('tablet')(templateObject_37 || (templateObject_37 = __makeTemplateObject(["\n      margin-bottom: ", ";\n    "], ["\n      margin-bottom: ", ";\n    "])), (0, _1.getRelative)(200, 'desktop')));
};
exports.getMargin200 = getMargin200;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33, templateObject_34, templateObject_35, templateObject_36, templateObject_37, templateObject_38;
