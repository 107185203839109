export const getProductQuery = /* GraphQL */ `
query getProduct(
  $hasLocale: Boolean = false
  $locale: String = "null"
  $path: String!
  $includeTax: Boolean = false
  $first: Int = 100
) {
  site {
    route(path: $path) {
      node {
        __typename
        ... on Product {
          ...productInfo
          variants(first: $first) {
            edges {
              node {
                entityId
                defaultImage {
                  urlOriginal
                  altText
                  isDefault
                }
                inventory {
                  aggregated {
                    availableToSell
                    warningLevel
                  }
                  isInStock
                }
                productOptions {
                  edges {
                    node {
                      __typename
                      entityId
                      displayName
                      ...multipleChoiceOption
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

fragment productInfo on Product {
  entityId
  name
  path
  brand {
    entityId
  }
  filters
  description
  prices(includeTax: $includeTax) {
    ...productPrices
  }
  images {
    edges {
      node {
        urlOriginal
        altText
        isDefault
      }
    }
  }
  variants(first: $first) {
    edges {
      node {
        entityId
        defaultImage {
          urlOriginal
          altText
          isDefault
        }
      }
    }
  }
  productOptions {
    edges {
      node {
        __typename
        entityId
        displayName
        ...multipleChoiceOption
      }
    }
  }
  localeMeta: metafields(namespace: $locale, keys: ["name", "description"])
    @include(if: $hasLocale) {
    edges {
      node {
        key
        value
      }
    }
  }
}
fragment productPrices on Prices {
  price {
    value
    currencyCode
  }
  salePrice {
    value
    currencyCode
  }
  retailPrice {
    value
    currencyCode
  }
  bulkPricing {
    minimumQuantity
    maximumQuantity
    ... on BulkPricingFixedPriceDiscount {
      price
    }
    ... on BulkPricingPercentageDiscount {
      percentOff
    }
    ... on BulkPricingRelativePriceDiscount {
      priceAdjustment
    }
  }
}

fragment multipleChoiceOption on MultipleChoiceOption {
  values {
    edges {
      node {
        label
        ...swatchOption
      }
    }
  }
}

fragment swatchOption on SwatchOptionValue {
  isDefault
  hexColors
}
`