"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadAddThis = void 0;
// thirdParty library called AddThis to display sharing buttons
var loadAddThis = function (callback) {
    var existingScript = document.getElementById('addThisWidgetScript');
    if (!existingScript) {
        var script = document.createElement('script');
        script.src = 'https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-60019fec524812d8';
        script.id = 'addThisWidgetScript';
        document.body.appendChild(script);
        script.onload = function () {
            if (callback)
                callback();
        };
    }
    if (existingScript && callback)
        callback();
};
exports.loadAddThis = loadAddThis;
