import { memo, useMemo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { mq, getRelative, createGrid } from '@/styles'
import { useMediaType } from '@dy/commons/hooks'
import { AnimatePresence, motion } from 'framer-motion'

interface IRoundHighlightSection {
  data:any
}

const ComponentWrapper = styled.section`
  ${createGrid()}
  margin: ${getRelative(40, 'mobile')} auto;
  padding-left: 0;

  ${mq.greaterThan('tablet')`
    margin: ${getRelative(40, 'desktop')} auto;
    padding-left: ${getRelative(200, 'desktop')};
  `}

  ul {
    display: flex;
    flex-direction: row;
    grid-area: 1 / 1 / span 1 / span 6;
    justify-content: flex-start;

    ${mq.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 12;
    `}
  }

  li {
    align-items: center;
    display: flex;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    transition: all 2s ease-in-out; 
    height: ${getRelative(360, 'mobile')};
    width: ${getRelative(360, 'mobile')};

    ${mq.greaterThan('tablet')`
      height: ${getRelative(360, 'desktop')};
      width: ${getRelative(360, 'desktop')};
    `}

    &:hover #rotatingText {
      animation-play-state: paused;
    }
    
    &:hover {
      transform: scale(1.05);
    }
    a {
      margin: 0 auto;
    }
  }

  .image_cropper {
    display: grid;
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    margin: 0 auto;
    width: ${getRelative(273, 'mobile')};
    height: ${getRelative(273, 'mobile')};
    position: relative;
    overflow: hidden;
    border-radius: 50%;

    ${mq.greaterThan('tablet')`
      width:${getRelative(273, 'desktop')};
      height: ${getRelative(273, 'desktop')};
    `}
     .placeholder {
        background-color: rgba(0, 0, 0, .15);
        display: block;
        margin: 0 auto;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

    img {
      display: inline;
      margin: 0 auto;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
  svg {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    width: ${getRelative(360, 'mobile')};
    height: ${getRelative(360, 'mobile')};

    ${mq.greaterThan('tablet')`
      width:${getRelative(360, 'desktop')};
      height: ${getRelative(360, 'desktop')};
    `}
  }

  .circle {
    display: grid;
    align-items: center;
    justify-content: center;
    height: ${getRelative(360, 'mobile')};
    width: ${getRelative(360, 'mobile')};
    ${mq.greaterThan('tablet')`
      height: ${getRelative(360, 'desktop')};
      width: ${getRelative(360, 'desktop')};
    `}
  }
  #rotatingText {
    -webkit-animation: rotating 14s linear infinite;
    -moz-animation: rotating 14s linear infinite;
    -ms-animation: rotating 14s linear infinite;
    -o-animation: rotating 14s linear infinite;
    animation: rotating 14s linear infinite;
    
    @-webkit-keyframes rotating {
      from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @keyframes rotating {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

`

const logoVariants = {
  initial: {
    opacity: 0,
    transition: {
      ease: 'easeIn'
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
      duration: .3,
    }
  },
  exit: {
    opacity: 0,
    transition: {
      ease: 'easeIn'
    }
  },
}

const NEXT_LOCALE = process.env.NEXT_PUBLIC_LOCALE

const getCircles = (data) => data.map((circle, idx)=> {
  const {url, item_type} = circle
  let href = ''
  if(item_type === 'products') {
    href = `/product/${url?.slug?.[NEXT_LOCALE]}`
  } else if(item_type === 'categories') {
    href = `/shop/${url?.slug?.[NEXT_LOCALE]}`
  } else if(item_type === 'page') {
    href = `/page/${url?.slug?.[NEXT_LOCALE]}`
  } else {
    href = url?.slug?.[NEXT_LOCALE]
  }

  const diameter = 500 // Diameter in px
  const fontSize = 32 // Font size in px
  const whitespace = '\u00A0'
  let string = ''

  string = whitespace + circle?.title + whitespace 
  
  const circumference = Math.PI * diameter;
  const numberOfCharacters = circumference / fontSize;
  const repeatNthTimes = numberOfCharacters / string.length
  let nthTimes = Math.ceil(repeatNthTimes)
  let letterSpacing = Math.floor(numberOfCharacters) / (string.length * nthTimes);

  return(
    <>
      <AnimatePresence initial={false} key={idx} exitBeforeEnter>
        <motion.li key={href} variants={logoVariants} initial='initial' exit='exit' animate='animate'>
          <a href={href}>
            <>
              <motion.div key={circle?.title} className='circle' variants={logoVariants} initial='initial' exit='exit' animate='animate'>
                  <svg id="rotatingText" width="100%" height="100%" viewBox="-179 -179 358 358">
                      <path id="circlePath" fill="none" d="
                            M -144, 0
                            a 144,144 0 1,1 288,0
                            a 144,144 0 1,1 -288,0
                          " />
                      <text id="text" fontFamily="Basier Circle, sans-serif" fontSize="32" fontWeight="bold" fill="#5F895E" lengthAdjust="spacing">
                        <textPath spacing={'auto'} letterSpacing={letterSpacing} id="textPath" href="#circlePath" lengthAdjust="spacing"> {string.repeat(nthTimes)}</textPath>
                      </text>
                  </svg>
                <motion.div key={circle?.image?.default?.['2x']?.regular} className={'image_cropper'} variants={logoVariants} initial='initial' exit='exit' animate='animate'>
                {circle?.image ?
                  <img src={circle?.image?.default?.['2x']?.regular}></img>
                :
                  <div className='placeholder'></div>
                }
                </motion.div> 
              </motion.div>
            </>
          </a>
        </motion.li>
      </AnimatePresence>
    </>
  )
})

const getNewCategoryArray = (prevCategoryArray, images) => {
  const missingCategoryArray = images.filter(img => {
    return !prevCategoryArray.includes(img)
  })

  const newArrayCategory = Math.floor(Math.random() * missingCategoryArray.length)
  const oldArrayCategory = Math.floor(Math.random() * prevCategoryArray.length)

  const updatedArray = [...prevCategoryArray]
  updatedArray[oldArrayCategory] = missingCategoryArray[newArrayCategory]
  return updatedArray
}

export const RoundHighlightSection = memo<IRoundHighlightSection>(({data}) => {
  const mediaType = useMediaType()
  const mobile = useMemo(() => mediaType === 'mobile', [mediaType])

  const [circlesArray, setCircleArray] = useState([])

  useEffect(() => {
    if(mobile) {
      setCircleArray(data.slice(0, 1))
    } else {
      setCircleArray(data)
    }
    
  }, [mobile])

  const Circles = useMemo(() => getCircles(circlesArray), [circlesArray])

  useEffect(() => {
    if((data.length > circlesArray.length) && mobile ) {
      const interval = setInterval(() => {
        setCircleArray(prevCategoryArray => getNewCategoryArray(prevCategoryArray, data))
      }, 7000);
  
      return () => clearInterval(interval);
    }
    
  }, [circlesArray, mobile, Circles])


  return (
    <ComponentWrapper>
      <ul>
        {Circles}
      </ul>
    </ComponentWrapper>
  )
})
