import styled from 'styled-components'
import { mq, getRelative, hideScrollbar } from '@/styles'
import { Accordion } from './accordion/Accordion'

const CategoriesStyled = styled.section`
  display: none;

  ${mq.greaterThan('tablet')`
    display: block;
    grid-column: 1 / span 3;
    height: calc(100vh - 80px);
    min-height: calc(100vh - 80px);
    overflow: auto;
    padding: ${getRelative(20, 'desktop')} ${getRelative(50, 'desktop')} ${getRelative(50, 'desktop')} ${getRelative(30, 'desktop')};
    position: sticky;
    top: 80px;
    z-index: 60;
    ${hideScrollbar()}
  `}
`

export const FaqsCategories = ({ categories, activeIndex, activeCategory, activeQuestion }) => {
  return (
    <CategoriesStyled>
      <Accordion items={categories} activeIndex={activeIndex} activeCategory={activeCategory} activeQuestion={activeQuestion} />
    </CategoriesStyled>
  )
}
