"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = exports.transition = exports.variantsError = exports.variantsLabel = exports.variantsSpan = exports.DEFAULT_INPUT_STATE = exports.parsePlaceHolder = exports.P = exports.Label = exports.Span = exports.Div = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var styled_components_1 = __importStar(require("styled-components"));
var framer_motion_1 = require("framer-motion");
var styles_1 = require("../../styles");
var hooks_1 = require("../../hooks");
exports.Div = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: ", ";\n  opacity: ", ";\n  padding-top: ", ";\n  position: relative;\n  transition: opacity 300ms ", ";\n  width: 100%;\n\n  input, span, label, p {\n    cursor: inherit;\n    font-family: ", ";\n  }\n\n  ", "\n\n  ", ";\n  ", ";\n"], ["\n  display: flex;\n  margin-bottom: ", ";\n  opacity: ", ";\n  padding-top: ", ";\n  position: relative;\n  transition: opacity 300ms ", ";\n  width: 100%;\n\n  input, span, label, p {\n    cursor: inherit;\n    font-family: ", ";\n  }\n\n  ", "\n\n  ", ";\n  ", ";\n"])), (0, styles_1.getRelative)(20.5, 'mobile'), function (_a) {
    var disable = _a.disable;
    return disable ? .5 : 1;
}, (0, styles_1.getRelative)(13, 'mobile'), function (_a) {
    var theme = _a.theme;
    return theme.easing;
}, function (_a) {
    var theme = _a.theme;
    return theme.fonts.family;
}, styles_1.mq.greaterThan('tablet')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-bottom: ", ";\n    padding-top: ", ";\n  "], ["\n    margin-bottom: ", ";\n    padding-top: ", ";\n  "])), (0, styles_1.getRelative)(20.5, 'desktop'), (0, styles_1.getRelative)(13, 'desktop')), function (_a) {
    var disable = _a.disable;
    return disable ? (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["cursor: not-allowed;"], ["cursor: not-allowed;"]))) : '';
}, function (_a) {
    var hasIcon = _a.hasIcon;
    return hasIcon ? (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    input {\n      padding-right: ", ";\n\n      ", "\n    }\n  "], ["\n    input {\n      padding-right: ", ";\n\n      ", "\n    }\n  "])), (0, styles_1.getRelative)(40, 'mobile'), styles_1.mq.greaterThan('tablet')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        padding-right: ", ";\n      "], ["\n        padding-right: ", ";\n      "])), (0, styles_1.getRelative)(40, 'desktop'))) : '';
});
exports.Span = (0, styled_components_1.default)(framer_motion_1.motion.span)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 0;\n  height: 1px;\n  background-color: ", ";\n  transition: background-color 300ms ", ";\n  left: 0;\n  width: 0%;\n"], ["\n  position: absolute;\n  bottom: 0;\n  height: 1px;\n  background-color: ", ";\n  transition: background-color 300ms ", ";\n  left: 0;\n  width: 0%;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.orange;
}, function (_a) {
    var theme = _a.theme;
    return theme.easing;
});
exports.Label = (0, styled_components_1.default)(framer_motion_1.motion.label)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n\tfont-size: ", ";\n  line-height: ", ";\n  letter-spacing: 0;\n  font-weight: normal;\n  color: ", ";\n  bottom: 0;\n  user-select: none;\n  pointer-events: none;\n  transition: color 300ms ", ";\n  opacity: ", ";\n\n  ", "\n\n  ~ figure {\n    img {\n      transform: rotate(", "deg);\n    }\n  }\n"], ["\n  position: absolute;\n\tfont-size: ", ";\n  line-height: ", ";\n  letter-spacing: 0;\n  font-weight: normal;\n  color: ", ";\n  bottom: 0;\n  user-select: none;\n  pointer-events: none;\n  transition: color 300ms ", ";\n  opacity: ", ";\n\n  ", "\n\n  ~ figure {\n    img {\n      transform: rotate(", "deg);\n    }\n  }\n"])), (0, styles_1.getRelative)(18, 'mobile'), (0, styles_1.getRelative)(25, 'mobile'), function (_a) {
    var colors = _a.theme.colors, active = _a.active, textColor = _a.textColor, error = _a.error;
    return error ? colors.input_error : active ? colors["input_text_".concat(textColor)] : colors.input_black;
}, function (_a) {
    var theme = _a.theme;
    return theme.ease;
}, function (_a) {
    var dirty = _a.dirty;
    return dirty ? '.5' : '1';
}, styles_1.mq.greaterThan('tablet')(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    font-size: ", ";\n    line-height: ", ";\n  "], ["\n    font-size: ", ";\n    line-height: ", ";\n  "])), (0, styles_1.getRelative)(18, 'desktop'), (0, styles_1.getRelative)(25, 'desktop')), function (_a) {
    var active = _a.active;
    return active ? -180 : 0;
});
exports.P = (0, styled_components_1.default)(framer_motion_1.motion.p)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\tfont-family: ", ";\n  font-size: ", ";\n  line-height: ", ";\n  font-weight: normal;\n  color: ", ";\n  position: absolute;\n  bottom: 0;\n  opacity: 0;\n  pointer-events: none;\n\n  ", "\n"], ["\n\tfont-family: ", ";\n  font-size: ", ";\n  line-height: ", ";\n  font-weight: normal;\n  color: ", ";\n  position: absolute;\n  bottom: 0;\n  opacity: 0;\n  pointer-events: none;\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.family;
}, (0, styles_1.getRelative)(10, 'mobile'), (0, styles_1.getRelative)(10, 'mobile'), function (_a) {
    var theme = _a.theme, error = _a.error;
    return error ? theme.colors.input_error : 'rgba(0,0,0, .5)';
}, styles_1.mq.greaterThan('tablet')(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    font-size: ", ";\n    line-height: ", ";\n  "], ["\n    font-size: ", ";\n    line-height: ", ";\n  "])), (0, styles_1.getRelative)(10, 'desktop'), (0, styles_1.getRelative)(10, 'desktop')));
var FancyValue = styled_components_1.default.span(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background-color: transparent;\n  bottom: 0;\n  font-family: ", ";\n  font-size: ", ";\n  left: 0;\n  line-height: ", ";\n  padding-bottom: ", ";\n  pointer-events: none;\n  position: absolute;\n  user-select: none;\n  width: 100%;\n\n  ", "\n"], ["\n  background-color: transparent;\n  bottom: 0;\n  font-family: ", ";\n  font-size: ", ";\n  left: 0;\n  line-height: ", ";\n  padding-bottom: ", ";\n  pointer-events: none;\n  position: absolute;\n  user-select: none;\n  width: 100%;\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.family;
}, (0, styles_1.getRelative)(18, 'mobile'), (0, styles_1.getRelative)(25, 'mobile'), (0, styles_1.getRelative)(8.5, 'mobile'), styles_1.mq.greaterThan('tablet')(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    font-size: ", ";\n    line-height: ", ";\n    padding-bottom: ", ";\n  "], ["\n    font-size: ", ";\n    line-height: ", ";\n    padding-bottom: ", ";\n  "])), (0, styles_1.getRelative)(18, 'desktop'), (0, styles_1.getRelative)(25, 'desktop'), (0, styles_1.getRelative)(8.5, 'desktop')));
var defaultPlaceHolderStyles = function (color) { return (0, styled_components_1.css)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  transition: opacity 300ms ", ";\n  color: ", ";\n"], ["\n  transition: opacity 300ms ", ";\n  color: ", ";\n"])), function (_a) {
    var ease = _a.ease;
    return ease;
}, function (_a) {
    var theme = _a.theme;
    return color ? color : theme.colors.input_black;
}); };
var parsePlaceHolder = function (styles, color) {
    if (color === void 0) { color = null; }
    return ((0, styled_components_1.css)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */\n    ", "\n    ", "\n  }\n  ::-moz-placeholder { /* Firefox 19+ */\n    ", "\n    ", "\n  }\n  :-ms-input-placeholder { /* IE 10+ */\n    ", "\n    ", "\n  }\n  :-moz-placeholder { /* Firefox 18- */\n    ", "\n    ", "\n  }\n"], ["\n  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */\n    ", "\n    ", "\n  }\n  ::-moz-placeholder { /* Firefox 19+ */\n    ", "\n    ", "\n  }\n  :-ms-input-placeholder { /* IE 10+ */\n    ", "\n    ", "\n  }\n  :-moz-placeholder { /* Firefox 18- */\n    ", "\n    ", "\n  }\n"])), defaultPlaceHolderStyles(color), styles, defaultPlaceHolderStyles(color), styles, defaultPlaceHolderStyles(color), styles, defaultPlaceHolderStyles(color), styles));
};
exports.parsePlaceHolder = parsePlaceHolder;
var InputBase = styled_components_1.default.input(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font-size: ", ";\n  line-height: ", ";\n  padding-bottom: ", ";\n  width: 100%;\n  font-family: ", ";\n  border-bottom: 1px solid ", ";\n  background-color: transparent;\n  color: ", ";\n\n  ", "\n\n  ", "\n\n  &:-webkit-autofill,\n  &:-webkit-autofill:hover,\n  &:-webkit-autofill:focus {\n    -webkit-box-shadow: none;\n    box-shadow: none;\n    background : none;\n    -webkit-text-fill-color: none;\n    transition: background-color 5000s ease-in-out 0s;\n  }\n"], ["\n  font-size: ", ";\n  line-height: ", ";\n  padding-bottom: ", ";\n  width: 100%;\n  font-family: ", ";\n  border-bottom: 1px solid ", ";\n  background-color: transparent;\n  color: ", ";\n\n  ", "\n\n  ", "\n\n  &:-webkit-autofill,\n  &:-webkit-autofill:hover,\n  &:-webkit-autofill:focus {\n    -webkit-box-shadow: none;\n    box-shadow: none;\n    background : none;\n    -webkit-text-fill-color: none;\n    transition: background-color 5000s ease-in-out 0s;\n  }\n"])), (0, styles_1.getRelative)(18, 'mobile'), (0, styles_1.getRelative)(25, 'mobile'), (0, styles_1.getRelative)(8.5, 'mobile'), function (_a) {
    var theme = _a.theme;
    return theme.fonts.family;
}, function (_a) {
    var theme = _a.theme;
    return (0, styles_1.hex2Rgba)(theme.colors.greendark, .4);
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.greendark;
}, styles_1.mq.greaterThan('tablet')(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    font-size: ", ";\n    line-height: ", ";\n    padding-bottom: ", ";\n  "], ["\n    font-size: ", ";\n    line-height: ", ";\n    padding-bottom: ", ";\n  "])), (0, styles_1.getRelative)(18, 'desktop'), (0, styles_1.getRelative)(25, 'desktop'), (0, styles_1.getRelative)(8.5, 'desktop')), function (_a) {
    var theme = _a.theme, textColor = _a.textColor, placeholderActive = _a.placeholderActive;
    return (0, exports.parsePlaceHolder)("opacity: ".concat(placeholderActive ? .3 : 0, ";"), theme.colors["input_text_".concat(textColor)]);
});
exports.DEFAULT_INPUT_STATE = {
    hover: false,
    focus: false,
    activated: false,
};
exports.variantsSpan = {
    active: { width: '100%' },
    inactive: { width: '0%' },
};
exports.variantsLabel = {
    active: function (mobile) { return ({
        opacity: 1,
        y: mobile ? (0, styles_1.getRelative)(-37, 'mobile') : (0, styles_1.getRelative)(-37, 'desktop'),
        fontSize: mobile ? (0, styles_1.getRelative)(10, 'mobile') : (0, styles_1.getRelative)(12, 'desktop'),
        lineHeight: mobile ? (0, styles_1.getRelative)(10, 'mobile') : (0, styles_1.getRelative)(14, 'desktop'),
        // textTransform: 'uppercase',
        transition: {
            type: 'tween',
            duration: .3,
            ease: [.25, .1, .25, 1],
        }
    }); },
    inactive: function (mobile) { return ({
        opacity: .4,
        y: mobile ? (0, styles_1.getRelative)(-16, 'mobile') : (0, styles_1.getRelative)(-16, 'desktop'),
        fontSize: mobile ? (0, styles_1.getRelative)(18, 'mobile') : (0, styles_1.getRelative)(18, 'desktop'),
        lineHeight: mobile ? (0, styles_1.getRelative)(25, 'mobile') : (0, styles_1.getRelative)(25, 'desktop'),
        textTransform: 'initial',
        transition: {
            type: 'tween',
            duration: .3,
            ease: [.25, .1, .25, 1],
        }
    }); }
};
exports.variantsError = {
    active: function (mobile) { return ({ pointerEvents: 'initial', opacity: 1, y: mobile ? (0, styles_1.getRelative)(25, 'mobile') : (0, styles_1.getRelative)(19, 'desktop') }); },
    inactive: function (mobile) { return ({ pointerEvents: 'none', opacity: 0, y: mobile ? (0, styles_1.getRelative)(20, 'mobile') : (0, styles_1.getRelative)(19, 'desktop') }); },
};
exports.transition = __assign(__assign({}, (0, styles_1.getTransition)()), { type: 'tween' });
exports.Input = (0, react_1.memo)((0, react_1.forwardRef)(function (_a, ref) {
    var name = _a.name, _b = _a.reset, reset = _b === void 0 ? false : _b, _c = _a.type, type = _c === void 0 ? 'text' : _c, _d = _a.label, label = _d === void 0 ? '' : _d, _e = _a.placeholder, placeholder = _e === void 0 ? '' : _e, _f = _a.initialInputState, initialInputState = _f === void 0 ? exports.DEFAULT_INPUT_STATE : _f, _g = _a.initialValue, initialValue = _g === void 0 ? '' : _g, _h = _a.onChange, onChange = _h === void 0 ? null : _h, _j = _a.onBlur, onBlur = _j === void 0 ? null : _j, _k = _a.disabled, disabled = _k === void 0 ? false : _k, _l = _a.autofocus, autofocus = _l === void 0 ? false : _l, _m = _a.error, error = _m === void 0 ? false : _m, _o = _a.className, className = _o === void 0 ? '' : _o, _p = _a.icon, icon = _p === void 0 ? null : _p, _q = _a.children, children = _q === void 0 ? null : _q, _r = _a.onFocus, onFocus = _r === void 0 ? null : _r, _s = _a.onKeyUp, onKeyUp = _s === void 0 ? null : _s, _t = _a.errorMessage, errorMessage = _t === void 0 ? '' : _t, _u = _a.infoMessage, infoMessage = _u === void 0 ? '' : _u, _v = _a.autoComplete, autoComplete = _v === void 0 ? 'new-password' : _v, _w = _a.spellcheck, spellcheck = _w === void 0 ? false : _w, _x = _a.textColor, textColor = _x === void 0 ? 'dark' : _x, _y = _a.fancyValue, fancyValue = _y === void 0 ? null : _y, _z = _a.placeHolderAlwaysActive, placeHolderAlwaysActive = _z === void 0 ? false : _z, role = _a.role;
    var _0 = __read((0, react_1.useState)(initialValue ? initialValue : ''), 2), value = _0[0], setValue = _0[1];
    var _1 = __read((0, react_1.useState)(initialInputState), 2), inputState = _1[0], setState = _1[1];
    var _2 = __read((0, react_1.useState)(type), 2), currentInputType = _2[0], setInputType = _2[1];
    var baseRef = (0, react_1.useRef)(null);
    var mediaType = (0, hooks_1.useMediaType)();
    var hover = inputState.hover, focus = inputState.focus, activated = inputState.activated;
    var handleChange = (0, react_1.useCallback)(function (_a) {
        var target = _a.target;
        onChange && onChange(target.value, target.value);
        setValue(target.value);
        handleStateChange('activated', target.value.length > 0)();
    }, [onChange]);
    var handleStateChange = (0, react_1.useCallback)(function (keys, values) { return function () {
        var _a, _b;
        var newState = __assign({}, inputState);
        if (typeof keys === 'string') {
            newState = __assign(__assign({}, newState), (_a = {}, _a[keys] = values, _a));
        }
        else {
            for (var i = 0; i < keys.length; i++) {
                var key = keys[i];
                var value_1 = values[i];
                newState = __assign(__assign({}, newState), (_b = {}, _b[key] = value_1, _b));
            }
        }
        setState(newState);
    }; }, [inputState]);
    var handleInputFocus = (0, react_1.useCallback)(function () {
        onFocus && onFocus();
        handleStateChange('focus', true)();
    }, []);
    var handleInputBlur = (0, react_1.useCallback)(function (e) {
        onBlur && onBlur(e, value, value);
        handleStateChange(['focus', 'activated'], [false, value.length > 0])();
    }, [value]);
    (0, react_1.useEffect)(function () {
        if (initialValue || initialValue === '') {
            setValue(initialValue);
            handleStateChange('activated', initialValue.length > 0)();
        }
    }, [initialValue]);
    (0, react_1.useEffect)(function () {
        if (reset) {
            setValue(initialValue);
            handleStateChange('activated', initialValue.length > 0)();
        }
    }, [reset]);
    (0, react_1.useEffect)(function () {
        if (autofocus) {
            baseRef.current.getElementsByTagName('input')[0].focus();
            handleInputFocus();
        }
        else {
            handleStateChange('activated', value.length > 0)();
        }
        return function () { return handleStateChange('activated', value.length > 0)(); };
    }, [autofocus]);
    var _3 = __read((0, react_1.useMemo)(function () {
        if ((0, react_1.isValidElement)(icon)) {
            if (type === 'password') {
                var Icon_1 = (0, jsx_runtime_1.jsx)("button", __assign({ type: 'button', className: 'input-pws-btn', onClick: function (e) {
                        e.preventDefault();
                        setInputType(currentInputType === 'password' ? 'text' : 'password');
                    } }, { children: icon }));
                return [Icon_1, true];
            }
            return [icon, true];
        }
        return [null, false];
    }, [icon, currentInputType]), 2), Icon = _3[0], hasIcon = _3[1];
    var labelActive = !disabled && focus || activated ? 'active' : 'inactive';
    var spanActive = !disabled && hover || focus || activated || error ? 'active' : 'inactive';
    var placeHolderActive = placeHolderAlwaysActive || focus || activated;
    var mobile = mediaType === 'mobile';
    return ((0, jsx_runtime_1.jsxs)(exports.Div, __assign({ ref: baseRef, hasIcon: hasIcon, className: className, disable: disabled, onMouseEnter: handleStateChange('hover', true), onMouseLeave: handleStateChange('hover', false) }, { children: [(0, jsx_runtime_1.jsx)(exports.Label, __assign({ dirty: value.length > 0 && !focus, error: error, custom: mobile, active: labelActive === 'active' && focus, variants: exports.variantsLabel, initial: ['inactive', 'active', 'inactive'], animate: labelActive, textColor: textColor }, { children: label })), fancyValue && (0, jsx_runtime_1.jsx)(FancyValue, { children: fancyValue }), (0, jsx_runtime_1.jsx)(InputBase, __assign({ "data-status": activated, name: name, autoCorrect: 'nope', spellCheck: spellcheck, ref: ref, autoComplete: autoComplete, type: currentInputType, disabled: disabled, onFocus: handleInputFocus, onBlur: handleInputBlur, placeholder: placeholder, onChange: handleChange, placeholderActive: placeHolderActive, defaultValue: initialValue, textColor: textColor, fancyValue: !!fancyValue }, (onKeyUp && { onKeyUp: onKeyUp }), { role: role })), (0, jsx_runtime_1.jsx)(exports.Span, { custom: mobile, error: error, transition: exports.transition, variants: exports.variantsSpan, animate: spanActive, textColor: textColor }), (0, jsx_runtime_1.jsx)(exports.P, __assign({ custom: mobile && type === 'password', error: error, transition: exports.transition, variants: exports.variantsError, animate: (error || infoMessage) ? 'active' : 'inactive' }, { children: infoMessage ? infoMessage : errorMessage })), hasIcon && Icon, children && children] })));
}));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
