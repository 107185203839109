import { memo } from 'react'
import styled from 'styled-components'
import { mq, getRelative } from '@/styles'

interface IValuesComponent {
  image: string,
  circle: string,
  className?: string
}

const ComponentWrapper = styled.div`
  position: relative;

  figure {
    width: ${getRelative(195, 'mobile')};
    height: ${getRelative(195, 'mobile')};

    ${mq.greaterThan('tablet')`
      width: ${getRelative(320, 'desktop')};
      height: ${getRelative(320, 'desktop')};
    `}
  }

  .image {
    padding: ${getRelative(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding: ${getRelative(30, 'desktop')};
    `}
  }

  .circle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-animation: rotating 14s linear infinite;
    -moz-animation: rotating 14s linear infinite;
    -ms-animation: rotating 14s linear infinite;
    -o-animation: rotating 14s linear infinite;
    animation: rotating 14s linear infinite;

    @-webkit-keyframes rotating {
      from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @keyframes rotating {
      from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  &.top-left {
    grid-area: 1 / 1 / span 1 / span 3;
    margin-left: -${getRelative(5, 'mobile')};

    ${mq.greaterThan('tablet')`
      grid-area: 1 / 2 / span 1 / span 3;
      margin: 0 ${getRelative(15, 'desktop')};
    `}
  }

  &.bottom-left {
    grid-area: 3 / 1 / span 1 / span 3;
    margin-left: -${getRelative(5, 'mobile')};

    ${mq.greaterThan('tablet')`
      grid-area: 2 / 1 / span 1 / span 4;
      margin: 0 ${getRelative(75, 'desktop')};
    `}
  }

  &.top-right {
    grid-area: 1 / 4 / span 1 / span 3;
    margin: ${getRelative(57, 'mobile')} 0 0 -${getRelative(5, 'mobile')};


    ${mq.greaterThan('tablet')`
      grid-area: 1 / 9 / span 1 / span 3;
      margin: 0 ${getRelative(15, 'desktop')};
    `}
  }

  &.bottom-right {
    grid-area: 3 / 4 / span 1 / span 3;
    margin: ${getRelative(50, 'mobile')} 0 0 -${getRelative(5, 'mobile')};

    ${mq.greaterThan('tablet')`
      grid-area: 2 / 9 / span 1 / span 4;
      margin: 0 ${getRelative(75, 'desktop')};
    `}
  }
`

export const ValuesComponent = memo<IValuesComponent>(({ image, circle, className }) => {
  return (
    <ComponentWrapper className={className}>
      <figure className='circle'>
        <img src={circle} alt='Circle icon' />
      </figure>
      <figure className='image'>
        <img src={image} alt='Value image'/>
      </figure>
    </ComponentWrapper>
  )
})
