import { CategoryInterface } from 'redux/category/category.types'
import { SeoInterface, SlugInterface, MediumInterface, ContentInterface, TagInterface } from '../types/global/global.types'

export interface ArticleInterface {
  seo: SeoInterface,
  title: string,
  slug: SlugInterface,
  author: string,
  publish_date: string,
  media: MediumInterface,
  image_caption: string,
  contents: ContentInterface[],
  tags: TagInterface[],
  categories: CategoryInterface[]
}

export const FETCH_ARTICLE_PENDING = 'ARTICLE/FETCH_PENDING'
export const FETCH_ARTICLE_SUCCESS = 'ARTICLE/FETCH_SUCCESS'
export const FETCH_ARTICLE_ERROR   = 'ARTICLE/FETCH_ERROR'

export interface ArticleFetchSuccessAction {
  type: typeof FETCH_ARTICLE_SUCCESS,
  payload: ArticleInterface
}

export interface ArticleFetchPendingAction {
  type: typeof FETCH_ARTICLE_PENDING,
  payload: boolean
}

export interface ArticleFetchErrorAction {
  type: typeof FETCH_ARTICLE_ERROR,
  payload: TypeError
}