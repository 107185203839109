import * as Comlink from "comlink"

export const getFiltersFrom = async (products, { no_type, no_filters }) => {
  const worker = new Worker("/workers/filters.worker.js");
  const obj = Comlink.wrap(worker) as any;

  const filters = await obj.getFilters(products, { no_type, no_filters })

  return filters
}

export const getProductsFiltered = async (products, filters) => {
  const worker = new Worker("/workers/filters.worker.js");
  const obj = Comlink.wrap(worker) as any;

  const newProducts = await obj.getProducts(products, filters)

  return newProducts
}

export const getFinalFilters = async (allFilters, filters, baseCategory) => {

  const _filters = allFilters[baseCategory]
  return { ...filters, [baseCategory]: _filters }

}