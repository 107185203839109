import type { FC, ReactNode, Dispatch, SetStateAction } from 'react'
import { createContext, useEffect, useState } from 'react'
import useCart from '@custom-bigcommerce/storefront-data-hooks/cart/use-cart'
import { slugify } from '@/utils'
import { callAPI } from '@/api'

type ContextType = {
  preOrderCart: boolean,
  setPreOrderCart: Dispatch<SetStateAction<boolean>>
}

export const PreOrderCartContext = createContext<ContextType | undefined>(undefined)

interface Props {
  children?: ReactNode
}
const NEXT_PUBLIC_LOCALE = process.env.NEXT_PUBLIC_LOCALE
export const PreOrderCartProvider: FC<Props> = ({ children }) => {
  const [preOrderCart, setPreOrderCart] = useState<boolean>(false)
  const { data: dataCart } = useCart()

  useEffect(() => {
    console.log('🛒 Cart type:', preOrderCart ? 'preorder' : 'normal')
  }, [preOrderCart])

  useEffect(() => {
    async function checkProducts(slugsArray) {
      for (let slug of slugsArray) {
        const [data] = await callAPI({ type: 'product', params: { slug, locale:NEXT_PUBLIC_LOCALE }})
        if (data?.is_preorder_only) {
          setPreOrderCart(true)
          break;
        }
      }
    }

    const cartItems = dataCart?.line_items?.physical_items
    if (Array.isArray(cartItems)) {
      const slugs = cartItems.map(item => slugify(item.name))
      checkProducts(slugs)
    } else {
      setPreOrderCart(false)
    }
  }, [dataCart])

  return <PreOrderCartContext.Provider value={{ preOrderCart, setPreOrderCart }}>
    {children}
  </PreOrderCartContext.Provider>
}
