import { memo, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { mq, getRelative, getP30 } from '@/styles'
import { SliderFlickity, ProductCard } from '@dy/commons/components'
import { pushAnalyticsEvent } from '@dy/commons/utils'
import { usePreOrderCart } from '../../hooks'
import { usei18n } from '@dy/commons/i18n'
interface ISectionRelated {
  data: any,
  readonly ga_list: {
    id: string,
    name: string
  }
}

const Section = styled.section`
  margin-right: -${getRelative(20, 'mobile')};
  margin-left: -${getRelative(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin-right: -${getRelative(30, 'desktop')};
    margin-left: -${getRelative(30, 'desktop')};
  `}

  .flickity-slider dd:first-of-type, h2 {
    padding-left: ${getRelative(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-left: ${getRelative(30, 'desktop')};
    `}
  }

  .slider--flickity .previous {
    svg { display: none; }
    ::after {
      position: absolute;
      top: 0;
      left: 0;
      content: url('/images/svg/i--arrow-left.svg');
      z-index: 100;
    }
  }

  .slider--flickity .next {
    svg { display: none; }
    ::after {
      position: absolute;
      top: 0;
      left: 0;
      content: url('/images/svg/i--arrow-right.svg');
      z-index: 100;
    }
  }
/* [aria-hidden='true'] {
    > article {
      pointer-events: none;
    }
  } */
`

const Dt = styled.dt`
  margin: ${getRelative(60, 'mobile')} 0 ${getRelative(24, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin: ${getRelative(60, 'desktop')} 0 ${getRelative(10, 'desktop')};
  `}

  h2 {
    ${getP30()}
    color: ${({ theme }) => theme.colors.orange};
    font-family: ${({ theme }) => theme.fonts.basier};
    text-align: left;
  }
`

const getSlides = (products, ga_list, preOrderCart) => products.map((product, idx) => {
  return (
    <dd key={idx}>
      <ProductCard data={{ ...product, variant: { ...product.variant, ga_list, idx: idx + 1 } }} narrow validForCartType={(preOrderCart && product.is_preorder_only) || (!preOrderCart && !product.is_preorder_only)} preOrderCart={preOrderCart}/>
    </dd>
  )
})

export const SectionRelated = memo<ISectionRelated>(({ data, ga_list }) => {
  const { preOrderCart } = usePreOrderCart()
  const Slides = useMemo(() => getSlides(data, ga_list, preOrderCart), [data, preOrderCart])
  const { layout } = usei18n()
  const [ref, inView] = useInView({
    rootMargin: '20px', // TOP  RIGHT
    threshold: 0,
    triggerOnce: true
  })

  useEffect(() => {
    if(inView) {
      pushAnalyticsEvent('view_item_list', { products: data, ga_list })
    }
  }, [inView])

  return (
    <Section ref={ref}>
      <dl>
        <Dt><h2>{layout.product_page['bottom-page'].related}</h2></Dt>
        <SliderFlickity className={'slider--product-card'} loop={false} pageDots={false} groupCells={'90%'} cellAlign={'left'} draggable={'>2'} buttons={true}>
          {Slides}
        </SliderFlickity>
      </dl>
    </Section>
  )
})
