"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrollToTop = exports.slugify = void 0;
var slugify = function (text) { return text
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-') // Replace spaces with -
    // eslint-disable-next-line no-useless-escape
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    // eslint-disable-next-line no-useless-escape
    .replace(/\-\-+/g, '-'); }; // Replace multiple - with single -
exports.slugify = slugify;
var scrollToTop = function () {
    window === null || window === void 0 ? void 0 : window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
};
exports.scrollToTop = scrollToTop;
