import { useState, useEffect, useCallback } from 'react'
import { fetchProduct } from '@/api'

export const usePriceGetter = (slug, includeTax = false): any => {
  const [data, setData] = useState({ prices: {} })

  const getPrices = useCallback(async (slug, includeTax) => {
    const [res] = await fetchProduct(slug, includeTax)
    const prices = res.product?.prices ? res.product.prices : {}

    setData({ prices })
  }, [])

  useEffect(() => {
    getPrices(slug, includeTax)
  }, [slug, includeTax])

  return { ...data }
}