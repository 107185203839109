import { memo, useRef } from 'react'
import styled from 'styled-components'
import { useScroll, motion, useTransform, useSpring } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { mq, createGrid, getRelative } from '@/styles'

const Section = styled.section`
  ${createGrid()}
  align-items: center;
  position: relative;
  padding: 0;
  padding-right: 0;

`

const PastaWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  transform: translateY(-17%);
  user-select: none;
  pointer-events: none;
  z-index: 1;
  left: 0;
  right: 0;
  height: ${getRelative(2000, 'mobile')};

  ${mq.greaterThan('tablet')`
    height: ${getRelative(2500, 'desktop')};
    transform: translateY(-8%);
  `}
`

const ImageOverlay = styled(motion.div)`
  z-index: 1;
  position: absolute;
  user-select: none;
  pointer-events: none;

  &.bestseller-left {
    left: ${getRelative(50, 'mobile')};
    top: ${getRelative(1500, 'mobile')};

    figure {
      height: ${getRelative(418, 'mobile')};
      width: ${getRelative(240, 'mobile')};
    }
  }

  &.bestseller-right {
    right: ${getRelative(-40, 'mobile')};
    top: ${getRelative(1350, 'mobile')};

    figure {
      height: ${getRelative(391, 'mobile')};
      width: ${getRelative(288, 'mobile')};
    }
  }

  ${mq.greaterThan('tablet')`
    &.bestseller-left {
      left: ${getRelative(-20, 'desktop')};
      top: ${getRelative(1900, 'desktop')};

      figure {
        height: ${getRelative(844, 'desktop')};
        width: ${getRelative(507, 'desktop')};
      }
    }

    &.bestseller-right {
      right: ${getRelative(-70, 'desktop')};
      top: ${getRelative(1400, 'desktop')};

      figure {
        height: ${getRelative(842, 'desktop')};
        width: ${getRelative(621, 'desktop')};
      }
    }
  `}
`


export const BestsellerSection = memo(() => {
  const containerRef = useRef(null)
  const [ref] = useInView({
    rootMargin: '20px', // TOP  RIGHT
    threshold: 0,
    triggerOnce: true
  })

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end end"]
  })

  const spring = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })
  const translateY = useTransform(spring, [0, 1], [-250, 0]);
  const translateX = useTransform(spring, [0, 1], [70, -180]);
  const y = useTransform(spring, [0, 1], [-340, -120]);
  const x = useTransform(spring, [0, 1], [-200, 250]);


  return (
    <Section ref={ref} className='bestseller-wrapper'>
      <PastaWrapper ref={containerRef} className='pasta-wrapper'>
        <ImageOverlay style={{ translateY, translateX }} className='bestseller-left'>
          <figure>
            <img src='/images/svg/pasta-left.svg' alt='Pasta left' width='507' height='844'/>
          </figure>
        </ImageOverlay>
        <ImageOverlay style={{ y, x }} className='bestseller-right'>
          <figure>
            <img src='/images/svg/pasta-right.svg'  alt='Pasta right' width='608' height='693' />
          </figure>
        </ImageOverlay>
      </PastaWrapper>
    </Section>
  )
})
