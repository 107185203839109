"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcPriceWithTax = exports.currencyFormatter = exports.currencyBasedOnRegion = exports.currency = void 0;
var currency = function (price, region) {
    if (region === void 0) { region = 'es-ES'; }
    return (Number(price || 0).toLocaleString(region, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
};
exports.currency = currency;
exports.currencyBasedOnRegion = {
    'AD': { code: 'EUR' },
    'AL': { code: 'EUR' },
    'AS': { code: 'USD' },
    'AT': { code: 'EUR' },
    'AU': { code: 'AUD' },
    'BQ': { code: 'USD' },
    'CH': { code: 'EUR' },
    'DE': { code: 'EUR' },
    'DEU': { code: 'EUR' },
    'EC': { code: 'USD' },
    'ES': { code: 'EUR' },
    'ESP': { code: 'EUR' },
    'FM': { code: 'USD' },
    'FR': { code: 'EUR' },
    'FRA': { code: 'EUR' },
    'GB': { code: 'GBP' },
    'GBR': { code: 'GBP' },
    'GR': { code: 'EUR' },
    'GU': { code: 'USD' },
    'HT': { code: 'USD' },
    'IE': { code: 'EUR' },
    'IM': { code: 'GBP' },
    'IO': { code: 'USD' },
    'IT': { code: 'EUR' },
    'ITA': { code: 'EUR' },
    'JE': { code: 'GBP' },
    'MC': { code: 'EUR' },
    'MH': { code: 'USD' },
    'MP': { code: 'USD' },
    'PA': { code: 'USD' },
    'PR': { code: 'USD' },
    'PT': { code: 'EUR' },
    'PW': { code: 'USD' },
    'SE': { code: 'EUR' },
    'SV': { code: 'EUR' },
    'UM': { code: 'USD' },
    'US': { code: 'USD' },
};
var currencyFormatter = function (_a) {
    var _b, _c;
    var price = _a.price, localeRegion = _a.localeRegion, _d = _a.decimals, decimals = _d === void 0 ? 2 : _d, _e = _a.round, round = _e === void 0 ? true : _e, _f = _a.trailingZeros, trailingZeros = _f === void 0 ? false : _f;
    var region = localeRegion.split('-')[1];
    var digits = (!round && price % 1 !== 0) ? decimals : trailingZeros ? decimals : 0;
    return new Intl.NumberFormat(localeRegion, {
        style: 'currency',
        currency: (_c = (_b = exports.currencyBasedOnRegion === null || exports.currencyBasedOnRegion === void 0 ? void 0 : exports.currencyBasedOnRegion[region]) === null || _b === void 0 ? void 0 : _b.code) !== null && _c !== void 0 ? _c : 'EUR',
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
    }).format(round ? Math.round(price) : price);
};
exports.currencyFormatter = currencyFormatter;
var calcPriceWithTax = function (_a) {
    var price = _a.price, tax = _a.tax;
    if (!price || typeof price !== 'number')
        return null;
    var vat = typeof tax === 'number' ? tax : 0;
    var taxAsValue = price * vat / 100;
    return Math.round(price + taxAsValue);
};
exports.calcPriceWithTax = calcPriceWithTax;
