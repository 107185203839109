import { memo, useEffect, useMemo, useCallback, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { mq, createGrid, getRelative, getTransition, hex2Rgba, getP141, getP113, getP93_2, getP53, getP26, getP30_1, getP20_3, getP15_1 } from '@/styles'
import { Button, Figure } from '@dy/commons/components'
import { useSlider, SLIDE_TIME } from '@/hooks'
import { parserMedia } from '@dy/commons/utils'

export interface IProductHighlightSection {
  data: any
}

const Section = styled.section<any>`
  margin-bottom: ${getRelative(150, 'mobile')};
  height: ${getRelative(447, 'mobile')};

  > div:not(.second) {
    height: inherit;
  }

  .contentWrapper {
    ${createGrid()}
    height: inherit;

    figure {
      user-select: none;
      pointer-events: none;
      filter: drop-shadow(8px 13px 1px rgb(78 57 14 / 7%));

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    &.media {
      color: #FFF;
    }

    &[data-length='1'] {

      figure {
        grid-area: 1 / 2 / span 1 / span 4;
        filter: none;

        &.figure--video {
          grid-area: 1 / 1 / span 2 / span 6;
          filter: none;
          height: ${getRelative(447, 'mobile')};
          overflow: hidden;

          iframe {
          transform: ${({ $videoAspectRatio }) => 'scale(' + $videoAspectRatio + ')'} !important;
          width: 100% !important;

          @media (min-aspect-ratio: 16/9) {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
          }
        }

        div[data-vimeo-initialized='true'] {
          @media (min-aspect-ratio: 16/9) {
            height: 0;
            overflow: hidden;
            padding-bottom: 56.25%;
            position: relative;
            transform: ${({ $videoAspectRatio }) => 'scale(' + $videoAspectRatio + ')'} !important;
          }
        }
        }

        &.product-highlight-image-center {
          grid-area: 1 / 1 / span 2 / span 6;
          filter: none;
        }
      }

      .product-highlight-pos-center {
        grid-area: 1 / 2 / span 2 / span 4;
        align-self: center;
        margin: 0;
      }
    }

    &[data-length='2'] {
      figure:nth-of-type(1) { grid-area: 1 / 1 / span 1 / span 4; }

      figure:nth-of-type(2) { grid-area: 1 / 3 / span 1 / span 4; }
    }

    &[data-length='3'] {
      figure:nth-of-type(1) { grid-area: 1 / 1 / span 1 / span 4; }

      figure:nth-of-type(2) { grid-area: 1 / 2 / span 1 / span 4; }

      figure:nth-of-type(3) { grid-area: 1 / 3 / span 1 / span 4; }
    }
  }


  ${mq.greaterThan('tablet')`
    margin-bottom: ${getRelative(200, 'desktop')};
    height: ${getRelative(715, 'desktop')};

    .contentWrapper {
      height: unset;

      &[data-length] figure {
        height: ${getRelative(700, 'desktop')};

        &.product-highlight-image-left {
          width: ${getRelative(672, 'desktop')};
          grid-area: 1 / 1 / span 1 / span 6;
          filter: drop-shadow(25px 40px 4px rgb(78 57 14 / 7%));
        }
        &.product-highlight-image-center {
          width: ${getRelative(672, 'desktop')};
          grid-area: 1 / 4 / span 1 / span 6;
          filter: drop-shadow(25px 40px 4px rgb(78 57 14 / 7%));
        }
        &.product-highlight-image-right {
          width: ${getRelative(672, 'desktop')};
          grid-area: 1 / 7 / span 1 / span 6;
          filter: drop-shadow(25px 40px 4px rgb(78 57 14 / 7%));
        }
      }

      &[data-length='1'] {
        figure {
          &.product-highlight-image-center {
            grid-area: 1 / 1 / span 1 / span 12;
            margin: 0 ${getRelative(50, 'desktop')};
            width: auto;
            filter: none;
          }

          &.figure--video {
            grid-area: 1 / 1 / span 1 / span 12;
            height: ${getRelative(715, 'desktop')};

          }
        }

        .product-highlight-pos-center {
          grid-area: 1 / 3 / span 1 / span 8;
        }
      }
    }
  `}
`

const TextWrapper = styled.div<any>`
  grid-area: 1 / 2 / span 2 / span 4;
  width: 100%;
  ${({ text_valign }) => text_valign === 'top' ? css`padding-top: ${getRelative(20, 'mobile')}; align-self: start;` : text_valign === 'bottom' ? css`padding-bottom: ${getRelative(30, 'mobile')}; align-self: end;` : css`align-self: center;` };
  z-index: 1;

  h2 {
    ${({ title_size }) => title_size === 'xl' ? getP141() : title_size === 'l' ? getP113() : title_size === 'm' ? getP93_2() : getP53() };
  }

  p {
    font-family: ${({ theme }) => theme.fonts.basier};
    ${({ subtitle_size }) => subtitle_size === 'l' ? css`${getP30_1()} font-weight: 100;` : subtitle_size === 'm' ? css`${getP30_1()} font-weight: 100;` : getP15_1() };
    margin: ${getRelative(15, 'mobile')} 0 ${getRelative(20, 'mobile')};
  }

  .product-highlight-align-left { text-align: center; }
  .product-highlight-align-center { text-align: center; }
  .product-highlight-align-right { text-align: center; }

  ${mq.greaterThan<any>('tablet')`
    grid-area: 1 / 5 / span 1 / span 4;
    ${({ text_valign }) => text_valign === 'top' ? css`padding-top: ${getRelative(60, 'desktop')}; align-self: start;` : text_valign === 'bottom' ? css`padding-bottom: ${getRelative(70, 'desktop')}; align-self: end;` : css`align-self: center;` };

    &.product-highlight-pos-left {
      grid-area: 1 / 2 / span 1 / span 5;

      p {
        &.product-highlight-align-left {
          margin: ${getRelative(15, 'desktop')} calc(100vw / 12) ${getRelative(30, 'desktop')} 0;
        }
        &.product-highlight-align-center {
          margin: ${getRelative(15, 'desktop')} calc(100vw / 24) ${getRelative(30, 'desktop')};
        }
        &.product-highlight-align-right {
          margin: ${getRelative(15, 'desktop')} 0 ${getRelative(30, 'desktop')} calc(100vw / 12);
        }
      }
    }
    &.product-highlight-pos-center {
      grid-area: 1 / 3 / span 1 / span 8;

      p {
        &.product-highlight-align-left {
          margin: ${getRelative(15, 'desktop')} calc(100vw / 12 * 4) ${getRelative(30, 'desktop')} 0;
        }
        &.product-highlight-align-center {
          margin: ${getRelative(15, 'desktop')} calc(100vw / 6) ${getRelative(30, 'desktop')};
        }
        &.product-highlight-align-right {
          margin: ${getRelative(15, 'desktop')} 0 ${getRelative(30, 'desktop')} calc(100vw / 12 * 4);
        }
      }
    }
    &.product-highlight-pos-right {
      grid-area: 1 / 7 / span 1 / span 5;

      p {
        &.product-highlight-align-left {
          margin: ${getRelative(15, 'desktop')} calc(100vw / 12) ${getRelative(30, 'desktop')} 0;
        }
        &.product-highlight-align-center {
          margin: ${getRelative(15, 'desktop')} calc(100vw / 24) ${getRelative(30, 'desktop')};
        }
        &.product-highlight-align-right {
          margin: ${getRelative(15, 'desktop')} 0 ${getRelative(30, 'desktop')} calc(100vw / 12);
        }
      }
    }

    .product-highlight-align-left { text-align: left; }
    .product-highlight-align-center { text-align: center; }
    .product-highlight-align-right { text-align: right; }

    button.product-highlight-align-left { margin: 0 auto 0 0; }
    button.product-highlight-align-right { margin: 0 0 0 auto; }

    p {
      ${({ subtitle_size }) => subtitle_size === 'l' ? css`${getP30_1()} font-weight: 100;` : subtitle_size === 'm' ? css`${getP26()} font-weight: 100;` : getP20_3() };

      &.home-header-text-subtitle {
        ${({ subtitle_size }) => subtitle_size === 'm' ? css`letter-spacing: -1.5px;` : ''};
      }
    }
  `}
`

const TimelineContainer = styled.div`
  margin-top: ${getRelative(20, 'mobile')};

  .span-container {
    margin: 0 ${getRelative(82, 'mobile')};
    background-color: ${({ theme }) => hex2Rgba(theme.colors.greenlight, 0.3)};
    position: relative;
    display: block;
    height: ${getRelative(2, 'mobile')};
    z-index: -1;
    border-radius: ${getRelative(2, 'mobile')};

    span {
      background-color: ${({ theme }) => theme.colors.greenlight};
      position: absolute;
      display: block;
      height: ${getRelative(2, 'mobile')};
      border-radius: ${getRelative(2, 'mobile')};
    }
  }

  ${mq.greaterThan('tablet')`
    margin-top: ${getRelative(20, 'desktop')};

    .span-container {
      margin: 0 ${getRelative(360, 'desktop')};
      height: ${getRelative(3, 'desktop')};
      border-radius: ${getRelative(3, 'desktop')};

      span {
        height: ${getRelative(3, 'desktop')};
        border-radius: ${getRelative(3, 'desktop')};
      }
    }

  `}
`
interface ITimeline {
  currentIdx: number,
  nSlides: number
}

const Timeline = memo<ITimeline>(({ currentIdx, nSlides }) => {
  const controls = useAnimation()

  const [spanVariants] = useMemo(() => {
    const variants = {}
    const percentage = 100 / nSlides

    for (let idx = 0; idx < nSlides; idx++) {
      variants[idx] = {
        width: (idx + 1) * percentage + '%',
        transition: {
          ease: 'linear',
          duration: SLIDE_TIME / 1000,
        },
      }
      variants[idx + 'prev'] = {
        width: (idx) * percentage + '%',
        transition: {
          ease: 'linear',
          duration: 0.1,
        },
      }
    }

    return [variants]
  }, [nSlides])

  const changeSpan = useCallback(async (idx) => {
    await controls.start(idx + 'prev')
    controls.start(idx + '')
  }, [])

  useEffect(() => {
    changeSpan(currentIdx)

    return () => {
      controls.stop()
    }
  }, [currentIdx])

  return (
    <TimelineContainer className='second' >
      <span className='span-container'><motion.span variants={spanVariants} animate={controls} /></span>
    </TimelineContainer>
  )
})

const imageVariants = {
  initial: {
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: 1,
      delay: .3,
    }
  },
  exit: {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  },
}

const text_variants_title = {
  inactive: {
    opacity: 0,
    y: '30px'
  },
  active: {
    opacity: 1,
    y: '0px',
    transition: { 
      ...getTransition(.5),
      delay: .3
    }
  }
}

const text_variants_subtitle = {
  inactive: {
    opacity: 0,
    y: '30px'
  },
  active: {
    opacity: 1,
    y: '0px',
    transition: { 
      ...getTransition(.5),
      delay: .4
    }
  }
}

const text_variants_button = {
  inactive: {
    opacity: 0,
    y: '30px'
  },
  active: {
    opacity: 1,
    y: '0px',
    transition: { 
      ...getTransition(.5),
      delay: .5
    }
  }
}

const getSlide = (slides, idx) => {
  const { title, subtitle, text_position: position, text_align: alignment, media, left_image, center_image, right_image, link, main_type, text_valign, title_size, subtitle_size } = slides[idx]
  let images

  if (main_type === 'product') {
    images = [left_image, center_image, right_image]
  } else {
    images = [media]
  }

  const ImageList = [];

  images.forEach((img, index) => {
    ImageList.push(
      <Figure lazy={false} key={index} className={'product-highlight-image-' + (main_type === 'media' ? 'center' : img?.position)} media={parserMedia(img)} />
    )
  })

  const filteredImages = images.filter(el => el != null)

  return (
    <div className={main_type === 'media' ? 'media contentWrapper' : 'contentWrapper'} data-length={filteredImages.length}>
      { ImageList }
      <TextWrapper className={'product-highlight-pos-' + position + ' product-highlight-wrapper'} title_size={title_size} subtitle_size={subtitle_size} text_valign={text_valign}>
        <motion.h2 className={'product-highlight-align-' + alignment + ' product-highlight-text-title'} variants={text_variants_title} animate={'active'} initial='inactive'>{title}</motion.h2>
        <motion.p className={'product-highlight-align-' + alignment + ' product-highlight-text-subtitle'} variants={text_variants_subtitle} animate={'active'} initial='inactive'>{subtitle}</motion.p>
        <Button className={'product-highlight-align-' + alignment + ' product-highlight-text-button'} variants={text_variants_button} animate={'active'} initial='inactive'>
          <a href={link.path}>{link.title}</a>
        </Button>
      </TextWrapper>
    </div>
  )
}

export const ProductHighlightSection = memo<IProductHighlightSection>(({ data }) => {
  const { currentSlide, triggerRef } = useSlider(data.length)
  const videooRef = useRef(null)
  const [videoAspectRatio, setVideoAspectRatio] = useState(null)

  const nSlides = useMemo(() => data.length, [data.length])
  const Slides = useMemo(() => getSlide(data, currentSlide), [currentSlide])

  useEffect(() => {
    if (!videooRef.current) return

    const aspectRatio = videooRef.current.clientWidth / videooRef.current.scrollHeight
    
    if(aspectRatio > (16 / 9)) {
      setVideoAspectRatio(aspectRatio / (16 / 9))
    } else {
      setVideoAspectRatio((16 / 9) / aspectRatio)
    }
  }, [])

  return (
    <Section ref={(ref) => { triggerRef(ref); videooRef.current = ref }} className='product-highlight-wrapper' $videoAspectRatio={videoAspectRatio}>
      <AnimatePresence exitBeforeEnter>
        <motion.div key={currentSlide} variants={imageVariants} initial='initial' exit='exit' animate='animate'>
          {Slides}
        </motion.div>
      </AnimatePresence>
      <Timeline nSlides={nSlides} currentIdx={currentSlide}/>
    </Section>
  )
})
