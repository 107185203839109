import { memo, useMemo } from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import { useMount } from '@/hooks'
import { mq, getRelative } from '@/styles'

const Button = styled.button`
  order: 0;

  ${mq.greaterThan('tablet')`
    display: none;
  `}

  figure {
    align-items: center;
    display: flex;
    height: ${getRelative(17, 'mobile')};
    justify-content: center;
    position: relative;
    width: ${getRelative(22, 'mobile')};
  }
`
interface IBurger {
  menuActive: boolean,
  onClick: () => void
}

const Svgs = {
  'burger': () => ({
    alt: 'Icon burger',
    src: '/images/svg/i--burger.svg'
  }),
  'close': () => ({
    alt: 'Icon close',
    src: '/images/svg/i--burger-close.svg'
  })
}

const getSvg = (menuActive) => {
  const type = menuActive ? 'close' : 'burger'
  return (Svgs[type]())
}

export const Burger = memo(({ menuActive, onClick }:IBurger) => {
  const isMounted = useMount()
  const img = useMemo(() => getSvg(menuActive), [menuActive])

  return isMounted && (
    <Button className='burger' onClick={onClick}>
      <figure key='burger'>
        <Image src={img.src} alt={img.alt} layout='fill'/>
      </figure>
    </Button>
  )
})
