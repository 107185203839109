"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputSelect = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var styled_components_1 = __importStar(require("styled-components"));
var framer_motion_1 = require("framer-motion");
var ArrowSelect_1 = require("./ArrowSelect");
var dropdown_1 = require("../dropdown");
var styles_1 = require("../../styles");
var hooks_1 = require("../../hooks");
var Div = styled_components_1.default.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: ", ";\n  opacity: ", ";\n  padding-top: ", ";\n  position: relative;\n  transition: opacity 300ms ", ";\n  width: 100%;\n  color: ", ";\n\n  input, span, label, p {\n    cursor: inherit;\n  }\n\n  ", "\n\n  ", ";\n  ", ";\n"], ["\n  display: flex;\n  margin-bottom: ", ";\n  opacity: ", ";\n  padding-top: ", ";\n  position: relative;\n  transition: opacity 300ms ", ";\n  width: 100%;\n  color: ", ";\n\n  input, span, label, p {\n    cursor: inherit;\n  }\n\n  ", "\n\n  ", ";\n  ", ";\n"])), (0, styles_1.getRelative)(20.5, 'mobile'), function (_a) {
    var disable = _a.disable;
    return disable ? .5 : 1;
}, (0, styles_1.getRelative)(13, 'mobile'), function (_a) {
    var theme = _a.theme;
    return theme.easing;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.greendark;
}, styles_1.mq.greaterThan('tablet')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-bottom: ", ";\n    padding-top: ", ";\n  "], ["\n    margin-bottom: ", ";\n    padding-top: ", ";\n  "])), (0, styles_1.getRelative)(20.5, 'desktop'), (0, styles_1.getRelative)(13, 'desktop')), function (_a) {
    var disable = _a.disable;
    return disable ? (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["cursor: not-allowed;"], ["cursor: not-allowed;"]))) : '';
}, function (_a) {
    var hasIcon = _a.hasIcon;
    return hasIcon ? (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    input {\n      padding-right: ", ";\n\n      ", "\n    }\n  "], ["\n    input {\n      padding-right: ", ";\n\n      ", "\n    }\n  "])), (0, styles_1.getRelative)(40, 'mobile'), styles_1.mq.greaterThan('tablet')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        padding-right: ", ";\n      "], ["\n        padding-right: ", ";\n      "])), (0, styles_1.getRelative)(40, 'desktop'))) : '';
});
var Span = (0, styled_components_1.default)(framer_motion_1.motion.span)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 0;\n  height: 1px;\n  background-color: ", ";\n  transition: background-color 300ms ", ";\n  left: 0;\n  width: 0%;\n"], ["\n  position: absolute;\n  bottom: 0;\n  height: 1px;\n  background-color: ", ";\n  transition: background-color 300ms ", ";\n  left: 0;\n  width: 0%;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.orange;
}, function (_a) {
    var theme = _a.theme;
    return theme.easing;
});
var Label = (0, styled_components_1.default)(framer_motion_1.motion.label)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  color: ", ";\n  bottom: 0;\n  user-select: none;\n  pointer-events: none;\n  transition: color 300ms ", ";\n  ", "\n\n  ", "\n\n  ~ figure {\n    img, svg {\n      transform: rotate(", "deg);\n    }\n  }\n"], ["\n  position: absolute;\n  color: ", ";\n  bottom: 0;\n  user-select: none;\n  pointer-events: none;\n  transition: color 300ms ", ";\n  ", "\n\n  ", "\n\n  ~ figure {\n    img, svg {\n      transform: rotate(", "deg);\n    }\n  }\n"])), function (_a) {
    var colors = _a.theme.colors, active = _a.active, textColor = _a.textColor, error = _a.error;
    return error ? colors.input_error : active ? colors["input_text_".concat(textColor)] : colors.greendark;
}, function (_a) {
    var theme = _a.theme;
    return theme.ease;
}, (0, styles_1.getP12_1)(), styles_1.mq.lessThan('tablet')(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    font-size: 10px;\n    line-height: 13px;\n  "], ["\n    font-size: 10px;\n    line-height: 13px;\n  "]))), function (_a) {
    var active = _a.active;
    return active ? -180 : 0;
});
var P = (0, styled_components_1.default)(framer_motion_1.motion.p)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\tfont-family: ", ";\n  font-size: ", ";\n  line-height: ", ";\n  font-weight: normal;\n  color: ", ";\n  position: absolute;\n  bottom: 0;\n  opacity: 0;\n  pointer-events: none;\n\n  ", "\n"], ["\n\tfont-family: ", ";\n  font-size: ", ";\n  line-height: ", ";\n  font-weight: normal;\n  color: ", ";\n  position: absolute;\n  bottom: 0;\n  opacity: 0;\n  pointer-events: none;\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.family;
}, (0, styles_1.getRelative)(10, 'mobile'), (0, styles_1.getRelative)(10, 'mobile'), function (_a) {
    var theme = _a.theme, error = _a.error;
    return error ? theme.colors.input_error : 'rgba(0,0,0, .5)';
}, styles_1.mq.greaterThan('tablet')(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    font-size: ", ";\n    line-height: ", ";\n  "], ["\n    font-size: ", ";\n    line-height: ", ";\n  "])), (0, styles_1.getRelative)(10, 'desktop'), (0, styles_1.getRelative)(10, 'desktop')));
var FancyValue = styled_components_1.default.span(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  background-color: transparent;\n  bottom: 0;\n  left: 0;\n  padding-bottom: ", ";\n  pointer-events: none;\n  position: absolute;\n  user-select: none;\n  width: 100%;\n  ", "\n\n  ", "\n\n  ", "\n"], ["\n  background-color: transparent;\n  bottom: 0;\n  left: 0;\n  padding-bottom: ", ";\n  pointer-events: none;\n  position: absolute;\n  user-select: none;\n  width: 100%;\n  ", "\n\n  ", "\n\n  ", "\n"])), (0, styles_1.getRelative)(8.5, 'mobile'), (0, styles_1.getP18_1)(), styles_1.mq.lessThan('tablet')(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    font-size: 16px;\n    line-height: 19px;\n  "], ["\n    font-size: 16px;\n    line-height: 19px;\n  "]))), styles_1.mq.greaterThan('tablet')(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    padding-bottom: ", ";\n  "], ["\n    padding-bottom: ", ";\n  "])), (0, styles_1.getRelative)(8.5, 'desktop')));
var defaultPlaceHolderStyles = function (color) { return (0, styled_components_1.css)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  transition: opacity 300ms ease-out;\n  color: ", ";\n"], ["\n  transition: opacity 300ms ease-out;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return color ? color : theme.colors.input_black;
}); };
var parsePlaceHolder = function (styles, color) {
    if (color === void 0) { color = null; }
    return ((0, styled_components_1.css)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */\n    ", "\n    ", "\n  }\n  ::-moz-placeholder { /* Firefox 19+ */\n    ", "\n    ", "\n  }\n  :-ms-input-placeholder { /* IE 10+ */\n    ", "\n    ", "\n  }\n  :-moz-placeholder { /* Firefox 18- */\n    ", "\n    ", "\n  }\n"], ["\n  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */\n    ", "\n    ", "\n  }\n  ::-moz-placeholder { /* Firefox 19+ */\n    ", "\n    ", "\n  }\n  :-ms-input-placeholder { /* IE 10+ */\n    ", "\n    ", "\n  }\n  :-moz-placeholder { /* Firefox 18- */\n    ", "\n    ", "\n  }\n"])), defaultPlaceHolderStyles(color), styles, defaultPlaceHolderStyles(color), styles, defaultPlaceHolderStyles(color), styles, defaultPlaceHolderStyles(color), styles));
};
var InputBase = styled_components_1.default.input(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  ", "\n  padding-bottom: ", ";\n  width: 100%;\n  border-bottom: 1px solid ", ";\n  background-color: transparent;\n  color: ", ";\n\n  ", "\n\n  ", "\n\n  ", "\n\n  &:-webkit-autofill,\n  &:-webkit-autofill:hover,\n  &:-webkit-autofill:focus {\n    -webkit-box-shadow: 0 0 0px 1000px ", " inset;\n    box-shadow: 0 0 0px 1000px ", " inset;\n    -webkit-text-fill-color: ", ";\n    transition: background-color 5000s ease-in-out 0s;\n  }\n"], ["\n  ", "\n  padding-bottom: ", ";\n  width: 100%;\n  border-bottom: 1px solid ", ";\n  background-color: transparent;\n  color: ", ";\n\n  ", "\n\n  ", "\n\n  ", "\n\n  &:-webkit-autofill,\n  &:-webkit-autofill:hover,\n  &:-webkit-autofill:focus {\n    -webkit-box-shadow: 0 0 0px 1000px ", " inset;\n    box-shadow: 0 0 0px 1000px ", " inset;\n    -webkit-text-fill-color: ", ";\n    transition: background-color 5000s ease-in-out 0s;\n  }\n"])), (0, styles_1.getP18_1)(), (0, styles_1.getRelative)(8.5, 'mobile'), function (_a) {
    var colors = _a.theme.colors, textColor = _a.textColor;
    return (0, styles_1.hex2Rgba)(colors["input_text_".concat(textColor)], .4);
}, function (_a) {
    var fancyValue = _a.fancyValue, theme = _a.theme;
    return fancyValue ? 'rgba(0,0,0,0)' : theme.colors.greendark;
}, styles_1.mq.lessThan('tablet')(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    font-size: 16px;\n    line-height: 19px;\n  "], ["\n    font-size: 16px;\n    line-height: 19px;\n  "]))), styles_1.mq.greaterThan('tablet')(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    padding-bottom: ", ";\n  "], ["\n    padding-bottom: ", ";\n  "])), (0, styles_1.getRelative)(8.5, 'desktop')), function (_a) {
    var theme = _a.theme, textColor = _a.textColor, placeholderActive = _a.placeholderActive;
    return parsePlaceHolder("opacity: ".concat(placeholderActive ? .3 : 0, ";"), theme.colors["input_text_".concat(textColor)]);
}, function (_a) {
    var theme = _a.theme, textColor = _a.textColor;
    return textColor === 'dark' ? theme.colors.background : 'rgb(35,35,35)';
}, function (_a) {
    var theme = _a.theme, textColor = _a.textColor;
    return textColor === 'dark' ? theme.colors.background : 'rgb(35,35,35)';
}, function (_a) {
    var theme = _a.theme, textColor = _a.textColor;
    return theme.colors["input_text_".concat(textColor)];
});
var DEFAULT_INPUT_STATE = {
    hover: false,
    focus: false,
    activated: false,
};
var variantsSpan = {
    active: { width: '100%' },
    inactive: { width: '0%' },
};
var variantsLabel = {
    active: function (mobile) { return ({
        opacity: 1,
        y: mobile ? (0, styles_1.getRelative)(-37, 'mobile') : (0, styles_1.getRelative)(-37, 'desktop'),
        // textTransform: 'uppercase',
        transition: {
            type: 'tween',
            duration: .3,
            ease: [.25, .1, .25, 1],
        }
    }); },
    inactive: function (mobile) { return ({
        opacity: .4,
        y: mobile ? (0, styles_1.getRelative)(-16, 'mobile') : (0, styles_1.getRelative)(-16, 'desktop'),
        textTransform: 'initial',
        transition: {
            type: 'tween',
            duration: .3,
            ease: [.25, .1, .25, 1],
        }
    }); }
};
var variantsError = {
    active: function (mobile) { return ({ pointerEvents: 'initial', opacity: 1, y: mobile ? (0, styles_1.getRelative)(25, 'mobile') : (0, styles_1.getRelative)(19, 'desktop') }); },
    inactive: function (mobile) { return ({ pointerEvents: 'none', opacity: 0, y: mobile ? (0, styles_1.getRelative)(20, 'mobile') : (0, styles_1.getRelative)(19, 'desktop') }); },
};
var transition = __assign(__assign({}, (0, styles_1.getTransition)()), { type: 'tween' });
var Input = (0, react_1.memo)((0, react_1.forwardRef)(function (_a, ref) {
    var name = _a.name, _b = _a.reset, reset = _b === void 0 ? false : _b, _c = _a.type, type = _c === void 0 ? 'text' : _c, _d = _a.label, label = _d === void 0 ? '' : _d, _e = _a.placeholder, placeholder = _e === void 0 ? '' : _e, _f = _a.initialInputState, initialInputState = _f === void 0 ? DEFAULT_INPUT_STATE : _f, _g = _a.initialValue, initialValue = _g === void 0 ? '' : _g, _h = _a.onChange, onChange = _h === void 0 ? null : _h, _j = _a.onBlur, onBlur = _j === void 0 ? null : _j, _k = _a.disabled, disabled = _k === void 0 ? false : _k, _l = _a.autofocus, autofocus = _l === void 0 ? false : _l, _m = _a.error, error = _m === void 0 ? false : _m, _o = _a.className, className = _o === void 0 ? '' : _o, _p = _a.icon, icon = _p === void 0 ? null : _p, _q = _a.children, children = _q === void 0 ? null : _q, _r = _a.onFocus, onFocus = _r === void 0 ? null : _r, _s = _a.errorMessage, errorMessage = _s === void 0 ? '' : _s, _t = _a.infoMessage, infoMessage = _t === void 0 ? '' : _t, _u = _a.autoComplete, autoComplete = _u === void 0 ? 'new-password' : _u, _v = _a.spellcheck, spellcheck = _v === void 0 ? false : _v, _w = _a.textColor, textColor = _w === void 0 ? 'dark' : _w, _x = _a.fancyValue, fancyValue = _x === void 0 ? null : _x, _y = _a.placeHolderAlwaysActive, placeHolderAlwaysActive = _y === void 0 ? false : _y, _z = _a.readOnly, readOnly = _z === void 0 ? false : _z;
    var _0 = __read((0, react_1.useState)(initialValue ? initialValue : ''), 2), value = _0[0], setValue = _0[1];
    var _1 = __read((0, react_1.useState)(initialInputState), 2), inputState = _1[0], setState = _1[1];
    var _2 = __read((0, react_1.useState)(type), 2), currentInputType = _2[0], setInputType = _2[1];
    var baseRef = (0, react_1.useRef)(null);
    var mediaType = (0, hooks_1.useMediaType)();
    var hover = inputState.hover, focus = inputState.focus, activated = inputState.activated;
    var handleChange = (0, react_1.useCallback)(function (_a) {
        var target = _a.target;
        onChange && onChange(target.value, target.value);
        setValue(target.value);
        handleStateChange('activated', target.value.length > 0)();
    }, [onChange]);
    var handleStateChange = (0, react_1.useCallback)(function (keys, values) { return function () {
        var _a, _b;
        var newState = __assign({}, inputState);
        if (typeof keys === 'string') {
            newState = __assign(__assign({}, newState), (_a = {}, _a[keys] = values, _a));
        }
        else {
            for (var i = 0; i < keys.length; i++) {
                var key = keys[i];
                var value_1 = values[i];
                newState = __assign(__assign({}, newState), (_b = {}, _b[key] = value_1, _b));
            }
        }
        setState(newState);
    }; }, [inputState]);
    var handleInputFocus = (0, react_1.useCallback)(function () {
        onFocus && onFocus();
        handleStateChange('focus', true)();
    }, []);
    var handleInputBlur = (0, react_1.useCallback)(function (e) {
        onBlur && onBlur(e, value, value);
        handleStateChange(['focus', 'activated'], [false, value.length > 0])();
    }, [value]);
    (0, react_1.useEffect)(function () {
        if (initialValue || initialValue === '') {
            setValue(initialValue);
            handleStateChange('activated', initialValue.length > 0)();
        }
        else {
            setValue('');
            handleStateChange('activated', false)();
        }
    }, [initialValue]);
    (0, react_1.useEffect)(function () {
        if (reset) {
            setValue(initialValue);
            handleStateChange('activated', initialValue.length > 0)();
        }
    }, [reset]);
    (0, react_1.useEffect)(function () {
        if (autofocus) {
            baseRef.current.getElementsByTagName('input')[0].focus();
            handleInputFocus();
        }
        else {
            handleStateChange('activated', value.length > 0)();
        }
        return function () { return handleStateChange('activated', value.length > 0)(); };
    }, [autofocus]);
    var _3 = __read((0, react_1.useMemo)(function () {
        if ((0, react_1.isValidElement)(icon)) {
            if (type === 'password') {
                var Icon_1 = (0, jsx_runtime_1.jsx)("button", __assign({ type: 'button', className: 'input-pws-btn', onClick: function (e) {
                        e.preventDefault();
                        setInputType(currentInputType === 'password' ? 'text' : 'password');
                    } }, { children: icon }));
                return [Icon_1, true];
            }
            return [icon, true];
        }
        return [null, false];
    }, [icon, currentInputType]), 2), Icon = _3[0], hasIcon = _3[1];
    var labelActive = !disabled && focus || activated ? 'active' : 'inactive';
    var spanActive = !disabled && hover || focus || error ? 'active' : 'inactive';
    var placeHolderActive = placeHolderAlwaysActive || focus || activated;
    var mobile = mediaType === 'mobile';
    return ((0, jsx_runtime_1.jsxs)(Div, __assign({ ref: baseRef, hasIcon: hasIcon, className: className, disable: disabled, onMouseEnter: handleStateChange('hover', true), onMouseLeave: handleStateChange('hover', false) }, { children: [(0, jsx_runtime_1.jsx)(Label, __assign({ dirty: value.length > 0 && !focus, error: error, custom: mobile, active: labelActive === 'active' && focus, variants: variantsLabel, initial: ['inactive', 'active', 'inactive'], animate: labelActive, textColor: textColor }, { children: label })), fancyValue ? (0, jsx_runtime_1.jsx)(FancyValue, { children: fancyValue }) : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), (0, jsx_runtime_1.jsx)(InputBase, { value: value, readOnly: true, name: name, autoCorrect: 'nope', spellCheck: spellcheck, ref: ref, autoComplete: autoComplete, type: currentInputType, disabled: disabled, onFocus: handleInputFocus, onBlur: handleInputBlur, placeholder: placeholder, onChange: handleChange, placeholderActive: placeHolderActive, textColor: textColor, fancyValue: !!fancyValue }), (0, jsx_runtime_1.jsx)(Span, { custom: mobile, error: error, transition: transition, variants: variantsSpan, animate: spanActive, textColor: textColor }), (0, jsx_runtime_1.jsx)(P, __assign({ custom: mobile && type === 'password', error: error, transition: transition, variants: variantsError, animate: (error || infoMessage) ? 'active' : 'inactive' }, { children: infoMessage ? infoMessage : errorMessage })), hasIcon && Icon, children && children] })));
}));
var OptionsList = (0, styled_components_1.default)(framer_motion_1.motion.ul)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  background-color: ", ";\n  box-shadow: 0 15px 15px rgba(0,0,0,.1);\n  left: 0;\n  top: 100%;\n  height: 0;\n  max-height: 200px;\n  opacity: 0;\n  overflow-y: auto;\n  padding: 0;\n  position: absolute;\n  width: 100%;\n  z-index: 10;\n\n  ", "\n\n  li {\n    ", "\n    color: ", ";\n    opacity: .4;\n    cursor: pointer;\n    padding: 10px 30px 10px 15px;\n    width: 100%;\n    background-color: rgba(255,255,255,1);\n    transition: opacity 300ms ", ", background-color 300ms ", ";\n\n    &:active,\n    &:hover,\n    &.active {\n      opacity: 1;\n      background-color: ", ";\n    }\n  }\n"], ["\n  background-color: ", ";\n  box-shadow: 0 15px 15px rgba(0,0,0,.1);\n  left: 0;\n  top: 100%;\n  height: 0;\n  max-height: 200px;\n  opacity: 0;\n  overflow-y: auto;\n  padding: 0;\n  position: absolute;\n  width: 100%;\n  z-index: 10;\n\n  ", "\n\n  li {\n    ", "\n    color: ", ";\n    opacity: .4;\n    cursor: pointer;\n    padding: 10px 30px 10px 15px;\n    width: 100%;\n    background-color: rgba(255,255,255,1);\n    transition: opacity 300ms ", ", background-color 300ms ", ";\n\n    &:active,\n    &:hover,\n    &.active {\n      opacity: 1;\n      background-color: ", ";\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.white;
}, styles_1.mq.greaterThan('tablet')(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    padding: 0;\n  "], ["\n    padding: 0;\n  "]))), (0, styles_1.getP18_1)(), function (_a) {
    var theme = _a.theme;
    return theme.colors.greendark;
}, function (_a) {
    var theme = _a.theme;
    return theme.ease;
}, function (_a) {
    var theme = _a.theme;
    return theme.ease;
}, function (_a) {
    var theme = _a.theme, textColor = _a.textColor;
    return (0, styles_1.hex2Rgba)(theme.colors["input_text_".concat(textColor)], .1);
});
var optionsListVariants = {
    collapsed: {
        height: '0',
        opacity: 0,
        transitionEnd: {
            display: 'none',
        },
        transition: __assign({}, (0, styles_1.getTransition)())
    },
    expanded: {
        height: 'auto',
        opacity: 1,
        display: 'block',
        transition: __assign({}, (0, styles_1.getTransition)())
    }
};
var getOptions = function (options) {
    return options.map(function (option, idx) { return ((0, jsx_runtime_1.jsx)("li", __assign({ "data-value": option.value }, { children: option.name }), idx)); });
};
var TIMEOUT_ID = null;
exports.InputSelect = (0, react_1.memo)(function (_a) {
    var options = _a.options, _b = _a.customReset, customReset = _b === void 0 ? false : _b, _c = _a.className, className = _c === void 0 ? '' : _c, _d = _a.label, label = _d === void 0 ? '' : _d, _e = _a.name, name = _e === void 0 ? '' : _e, _f = _a.inputRef, inputRef = _f === void 0 ? null : _f, _g = _a.onChange, onChange = _g === void 0 ? null : _g, _h = _a.initialValue, initialValue = _h === void 0 ? '' : _h, _j = _a.initialFanacyValue, initialFanacyValue = _j === void 0 ? '' : _j, _k = _a.disabled, disabled = _k === void 0 ? false : _k, _l = _a.error, error = _l === void 0 ? false : _l, _m = _a.textColor, textColor = _m === void 0 ? 'dark' : _m;
    var _o = __read((0, react_1.useState)(initialValue), 2), currentValue = _o[0], setValue = _o[1];
    var _p = __read((0, react_1.useState)(initialFanacyValue), 2), fancyValue = _p[0], setFancyValue = _p[1];
    var _q = __read((0, react_1.useState)(__spreadArray([], __read(options), false)), 2), currentOptions = _q[0], setOptions = _q[1];
    var allOptions = (0, react_1.useRef)(options);
    var handleSearch = (0, react_1.useCallback)(function (_a) {
        var value = _a.value, valid = _a.valid;
        return __awaiter(void 0, void 0, void 0, function () {
            var data;
            return __generator(this, function (_b) {
                if (valid) {
                    data = allOptions.current.filter(function (option) { return option.name.includes(value); });
                    setOptions(data);
                }
                else {
                    setOptions(allOptions.current);
                }
                return [2 /*return*/];
            });
        });
    }, []);
    var handleInputChange = function (value) {
        var valid = value.length > 1;
        TIMEOUT_ID && clearTimeout(TIMEOUT_ID);
        TIMEOUT_ID = setTimeout(function () { return handleSearch({ value: value, valid: valid }); }, 300);
    };
    var hangleChange = (0, react_1.useCallback)(function (value, text) {
        onChange && onChange(value);
        setValue(value);
        setFancyValue(text);
    }, []);
    var _r = __read((0, dropdown_1.useDropdown)({ onChange: hangleChange }), 4), triggerRef = _r[0], dropdownRef = _r[1], expanded = _r[2], toggleExpanded = _r[3];
    var refFunc = function (ref) {
        if (ref) {
            triggerRef.current = ref;
            inputRef && inputRef(ref);
        }
    };
    var Options = (0, react_1.useMemo)(function () { return getOptions(currentOptions); }, [currentOptions]);
    (0, react_1.useEffect)(function () {
        if (typeof initialValue === 'string') {
            setValue(initialValue);
        }
        else {
            setValue(null);
            setFancyValue(null);
        }
    }, [initialValue]);
    (0, react_1.useEffect)(function () {
        allOptions.current = __spreadArray([], __read(options), false);
        setOptions(options);
    }, [options]);
    (0, react_1.useEffect)(function () {
        if (customReset) {
            setValue('');
            setFancyValue(null);
        }
    }, [customReset]);
    return ((0, jsx_runtime_1.jsx)(Input, __assign({ readOnly: true, autofocus: false, onChange: handleInputChange, error: error, icon: (0, jsx_runtime_1.jsx)(ArrowSelect_1.ArrowSelect, {}), disabled: disabled, initialValue: currentValue, fancyValue: fancyValue, name: name, onFocus: toggleExpanded, className: className, label: label, ref: refFunc }, { children: (0, jsx_runtime_1.jsx)(OptionsList, __assign({ ref: dropdownRef, initial: 'collapsed', variants: optionsListVariants, animate: expanded ? 'expanded' : 'collapsed', textColor: textColor }, { children: Options })) })));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20;
