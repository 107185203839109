import { memo } from 'react'
import styled from 'styled-components'
import { mq, getRelative,getMargin30, getMargin60, getP93_1, getP26_2, createGrid } from '@/styles'

const Header = styled.header`
  ${createGrid()}
  ${getMargin60()}
  padding-top: 70px;
  align-content: flex-end;
  align-items: center;

  ${mq.greaterThan('tablet')`
    padding-top: 120px;
  `}

  h1, p {
    grid-column: 1 / span 6;
    text-align: center;
    width: 100%;
  }

  h1 {
    color: ${({ theme }) => theme.colors.greenlight};
    font-family: ${({ theme }) => theme.fonts.basier};
    ${getP26_2()}
    font-weight: 400;
    ${getMargin30()}
    padding-top: ${getRelative(50, 'mobile')};

    ${mq.greaterThan('tablet')`
      grid-column: 3 / span 8;
      padding-top: ${getRelative(60, 'desktop')};
    `}
  }

  p {
    color: ${({ theme }) => theme.colors.greendark};
    font-family: ${({ theme }) => theme.fonts.platform};
    ${getP93_1()}
    font-weight: bold;
    margin-bottom: 0;

    ${mq.greaterThan('tablet')`
      grid-column: 2 / span 10;
      padding: 0 ${getRelative(60, 'desktop')};
    `}
  }
`

export const HeaderContents = memo(({ children }) => {
  return (
    <Header>
      {children}
    </Header>
  )
})
