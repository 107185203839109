import { PageInterface, PageFetchSuccessAction, PageFetchPendingAction, PageFetchErrorAction, FETCH_PAGE_SUCCESS, FETCH_PAGE_PENDING, FETCH_PAGE_ERROR } from './page.types'

interface PageState {
  pending: boolean,
  data: PageInterface,
  error: TypeError
} 

export const InitialState: PageState = {
  pending: false,
  data: null,
  error: null
}

const pageFetchPending = (state: PageState, _: PageFetchPendingAction) => {
  return {
    ...state,
    pending: true,
    error: null
  }
}

const pageFetchSuccess = (state: PageState, action: PageFetchSuccessAction) => {
  return {
    ...state,
    pending: false,
    error: null,
    data: action.payload
  }
}

const pageFetchError = (state: PageState, action: PageFetchErrorAction) => {
  return {
    ...state,
    pending: false,
    error: action.payload
  }
}

export const reducers = {
  [FETCH_PAGE_PENDING]: pageFetchPending,
  [FETCH_PAGE_SUCCESS]: pageFetchSuccess,
  [FETCH_PAGE_ERROR]: pageFetchError
}