import React, { useMemo } from 'react'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components'
import { motion } from 'framer-motion';
import 'flickity/css/flickity.css';

import { Store } from 'redux'
import { CommerceProvider } from '@custom-bigcommerce/storefront-data-hooks'
import { PreOrderCartProvider } from '../context'

import { Reset, theme } from '@/styles'
import { Provideri18n } from '@dy/commons/i18n'

import { wrapper } from '../redux/store'
import { GoogleTagManager, CookieFirst } from '@/components'

export interface IProps {
  store?: Store,
  layoutProps?: any,
}

const STAGING = process.env.SITE_ENVIRONMENT === 'staging' || process.env.SITE_ENVIRONMENT === 'preview'
const PRO = process.env.SITE_ENVIRONMENT !== 'development' && process.env.SITE_ENVIRONMENT !== 'local' && !STAGING


// const variants = {
//   active: {
//     opacity: 1,
//     minHeight: '0vh',
//     transition: {
//       ...getTransition()
//     }
//   },
//   exit: {
//     opacity: 0,
//     transition: {
//       ...getTransition()
//     }
//   },
//   start: {
//     opacity: 0,
//     minHeight: '100vh',
//     transition: {
//       ...getTransition()
//     }
//   }
// }

const Noop = ({ children }) => <>{children}</>
const REGION = process.env.NEXT_PUBLIC_REGION

const MyApp = ({ Component, pageProps }: AppProps & IProps) => {
  const { pathname, asPath } = useRouter() || { pathname: '', asPath: '' }
  const { layoutLocale, locales, layout } = pageProps
  const Layout = (Component as any).Layout || Noop

  const motionKey = useMemo(() => {
    // if (typeof window !== 'undefined') {
    //   window['dataLayer'] = window['dataLayer'] || []
    //   window['dataLayer'].push({ 'event': 'virtual_pageview' })
    // }

    return pathname === '/shop/[...slug]' || pathname === '/faqs/[[...slug]]' ? pathname : asPath
  }, [pathname, asPath])

  return (
    <>
    <Head>
      <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=2.0' />
    </Head>
    <Reset/>
    <Provideri18n value={{ layout: layoutLocale, page: locales }}>
      <ThemeProvider theme={theme}>
        <CommerceProvider locale={'en-US'}>
          <PreOrderCartProvider>
            <motion.div key={motionKey} initial={{ opacity: 0 }} animate={{ opacity: 1}}>
              <Layout data={layout}>
                {/* <AnimatePresence exitBeforeEnter initial={false}> */}
                  {PRO && <GoogleTagManager id={'GTM-M55KKJ4'} />}
                  <CookieFirst region={REGION}/>
                  <Component {...pageProps} />
                {/* </AnimatePresence> */}
                {/* <AnimatePresence exitBeforeEnter>
                  <motion.div key={motionKey} variants={variants} initial='start' animate='active' exit='exit' className='main'>
                    <Component {...pageProps} />
                  </motion.div>
                </AnimatePresence> */}
              </Layout>
            </motion.div>
          </PreOrderCartProvider>
        </CommerceProvider>
      </ThemeProvider>
    </Provideri18n>
    </>
  )
}

//@ts-ignore
export default wrapper.withRedux(MyApp)
