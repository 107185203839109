import { createContext, Dispatch, SetStateAction, useState } from 'react'

type ActiveItemType = {
  index: number | null,
  slug: null,
  hash: string | null,
}
const initialState: ActiveItemType = {
  index: null,
  slug: null,
  hash: null,
}

type SetActiveItemType = Dispatch<SetStateAction<ActiveItemType>>
type UseAccordionContextType = [ ActiveItemType, SetActiveItemType ]
type AccordionContextType = {
  activeItem: ActiveItemType,
  setActiveItem: SetActiveItemType,
  collapseAll: () => void
}

export const AccordionContext = createContext<AccordionContextType | null>(null)

export const useAccordion = () => {
  const [activeItem, setActiveItem]:UseAccordionContextType = useState(initialState)
  
  const collapseAll = () => {
    setActiveItem(initialState)
  }

  return [activeItem, setActiveItem, collapseAll] as const
}
