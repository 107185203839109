import Link from 'next/link'
import { FC, ReactNode, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useAccordionItem } from './useAccordionItem'
import { mq, getRelative, hex2Rgba, getP18_2 ,getP15_2 } from '@/styles'

const AccordionTitle = styled.dt`
  color: ${({ theme }) => theme.colors.greendark};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${getRelative(20, 'mobile')};
  transition: 200ms margin-bottom ease-out, 200ms color ease-in-out;
  width: 100%;

  a {
    ${getP18_2()}
    margin-bottom: 0;
    opacity: 1;

    ${mq.greaterThan('tablet')`
      margin-bottom: 0;
    `}
  }

  ${mq.greaterThan('tablet')`
    margin-bottom: ${getRelative(20, 'desktop')};
  `}

  &:focus {
    outline: 0;
  }

  &:hover {
   color: ${({ theme }) => theme.colors.greendark};
  }

  &[aria-expanded='true'] {
    color: ${({ theme }) => theme.colors.greendark};
    margin-bottom: ${getRelative(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${getRelative(20, 'desktop')};
    `}

    span {
      opacity: 1;
    }
  }
`

const LinkAsTitle = styled.a<any>`
  ${getP15_2()}
  color: inherit;
  display: block;
  font-weight: 500;
  margin-bottom: ${getRelative(20, 'mobile')};
  opacity: ${({ active }) => active ? 1 : .4};
  text-decoration: none;
  transition: 200ms opacity ease-in-out;
  width: 100%;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${mq.greaterThan('tablet')`
    margin-bottom: ${getRelative(20, 'desktop')};
  `}

  &:hover {
    opacity: 1;
  }
`

const AccordionContent = styled(motion.dd)`
  ${getP15_2()}
  color: ${({ theme }) => hex2Rgba(theme.colors.greendark, 1)};
  margin-bottom: ${getRelative(30, 'mobile')};
  overflow: hidden;

  ${mq.greaterThan('tablet')`
    margin-bottom: ${getRelative(30, 'desktop')};
    padding-left: ${getRelative(20, 'desktop')};
  `}

  &:focus {
    outline: 0;
  }
`

const Chevron = styled(motion.span)`
  display: inline-block;
  margin-left: 10px;
  opacity: .3;
  user-select: none;
`

const transition = {
  ease: [.25, .1, .25, 1],
  duration: .3,
  type: 'tween'
}

const expandVariants = {
  expanded: {
    height: 'auto',
    display: 'block',
    opacity: 1,
    transition
  },
  collapsed: {
    height: 0,
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
    transition
  },
}

interface iAccordionItem {
  data: {
    id: string | number
    name:  ReactNode | string,
    faqs: {
      hash: string
    }[],
    slug?: any
  },
  index: number,
  activeCategory: any,
  activeQuestion: any,
}

const getFaqs = (faqs = [], slug, locale, asPath, activeQuestion) => faqs.map((faq, idx) => {
  return (
    <Link key={idx} href={`/faqs/${slug[locale ?? 'es']}#${faq.hash}`} passHref>
      <LinkAsTitle active={faq.hash === asPath.split('#')[1] || faq.hash === activeQuestion}>
        {faq.question}
      </LinkAsTitle>
    </Link>
  )
})

export const AccordionItem:FC<iAccordionItem> = ({ data: { id, name, faqs, slug }, index, activeCategory, activeQuestion }):JSX.Element => {
  const { locale, query, asPath } = useRouter() || { locale: '', query: {}, asPath: '' }
  const [trigger, accordion, expanded, toggleExpanded, onKeyDown] = useAccordionItem(index, slug, locale)
  const triggerId = `${id}__trigger`
  const regionId  = `${id}__region`
  const href = `/faqs/${slug[locale]}`

  // @ts-ignore
  const active = useMemo(() => (slug[locale] === query?.slug || slug[locale] === activeCategory), [query, activeCategory])

  const onClick = () => {
    toggleExpanded()
  }

  useEffect(() => {
    if(active && !expanded) toggleExpanded()
  }, [active])

  const Faqs = useMemo(() => getFaqs(faqs, slug, locale, asPath, activeQuestion), [faqs, slug, locale, asPath, activeQuestion])
  const firstQuestionHash = faqs?.[0]?.hash

  if ((Faqs as ReactNode[]).length === 0) return <></>

  return (
    <>
      <AccordionTitle ref={trigger} id={triggerId} role='button' onClick={onClick} tabIndex={0} aria-expanded={expanded} aria-controls={regionId} onKeyDown={onKeyDown} >
        <Link href={`${href}#${firstQuestionHash}`} passHref>
          <LinkAsTitle active={active}>
            {name}
          </LinkAsTitle>
        </Link>
        <Chevron aria-hidden={true} animate={{ rotate: expanded ? 90 : 0}} transition={transition}>
          <img src='/images/svg/i--chevron.svg' alt='Icon chevron' width='6' height='8'/>
        </Chevron>
      </AccordionTitle>
      <AccordionContent ref={accordion} role='region' id={regionId}  aria-labelledby={triggerId} tabIndex={-1} animate={expanded ? 'expanded' : 'collapsed'} initial='collapsed' variants={expandVariants}>
        {Faqs}
      </AccordionContent>
    </>
  )
}
