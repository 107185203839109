import { memo } from 'react'
import styled from 'styled-components'

import { mq, createGrid, getRelative } from '@/styles'
import { MarqueeSlider } from '@dy/commons/components'

interface IMarqueeSection {
  data: any
}

const Section = styled.section`
  ${createGrid()}
  padding: 0;
  margin-bottom: ${getRelative(40, 'mobile')};

  div {
    grid-area: 1 / 1 / span 1 / span 6;
    white-space: nowrap;
    overflow: scroll;
  }

  ${mq.greaterThan('tablet')`
    margin-bottom: ${getRelative(50, 'desktop')};

    div {
      grid-area: 1 / 1 / span 1 / span 12;
    }
  `}
`

export const MarqueeSection = memo<IMarqueeSection>(({ data }) => {
  return (
    <Section>
      <MarqueeSlider staticText={false} data={data.replace(/&nbsp;/g, ' ')}></MarqueeSlider>
    </Section>
  )
})
