"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.months = void 0;
exports.months = {
    '1': 'ENE',
    '2': 'FEB',
    '3': 'MAR',
    '4': 'ABR',
    '5': 'MAY',
    '6': 'JUN',
    '7': 'JUL',
    '8': 'AGO',
    '9': 'SEP',
    '10': 'OCT',
    '11': 'NOV',
    '12': 'DIC'
};
