"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputValidator = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var Input_1 = require("./Input");
var utils_1 = require("../../utils");
exports.InputValidator = (0, react_1.memo)((0, react_1.forwardRef)(function (_a, ref) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, format = _a.format, _c = _a.initialValue, initialValue = _c === void 0 ? '' : _c, _d = _a.label, label = _d === void 0 ? '' : _d, name = _a.name;
    var _e = __read((0, react_1.useState)(true), 2), valid = _e[0], setValid = _e[1];
    var onBlur = function (e) {
        var value = e.target.value;
        setValid(value && value.length > 0 ? utils_1.validate[format](value) : true);
    };
    return ((0, jsx_runtime_1.jsx)(Input_1.Input, { name: name, label: label, initialValue: initialValue, error: !valid, className: className, errorMessage: "El ".concat(format.toUpperCase(), " introducido no es v\u00E1lido"), onBlur: onBlur, ref: ref }));
}));
