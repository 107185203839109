"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccordionItem = void 0;
var react_1 = require("react");
var useAccordion_1 = require("./useAccordion");
var useAccordionItem = function (index) {
    var triggerRef = (0, react_1.useRef)(null);
    var accordionRef = (0, react_1.useRef)(null);
    var _a = __read((0, react_1.useState)(false), 2), expanded = _a[0], setExpanded = _a[1];
    var _b = (0, react_1.useContext)(useAccordion_1.AccordionContext), activeIndex = _b.activeIndex, setActiveIndex = _b.setActiveIndex, collapseAll = _b.collapseAll;
    var onToggle = (0, react_1.useCallback)(function () {
        var _a;
        collapseAll();
        setActiveIndex(Object.assign(activeIndex, (_a = {}, _a[index] = !activeIndex[index], _a)));
        setExpanded(!expanded);
    }, [activeIndex, setActiveIndex, expanded]);
    (0, react_1.useEffect)(function () {
        if (!!activeIndex[index] !== expanded)
            setExpanded(!expanded);
    }, [activeIndex, setActiveIndex]);
    var onKeyDown = function (e) {
        switch (e.key) {
            case ' ':
            case 'Enter':
                e.preventDefault();
                onToggle();
                break;
            case 'ArrowDown':
                e.preventDefault();
                if (triggerRef.current && triggerRef.current.nextElementSibling && triggerRef.current.nextElementSibling.nextElementSibling) {
                    triggerRef.current.nextElementSibling.nextElementSibling.focus();
                }
                break;
            case 'ArrowUp':
                e.preventDefault();
                if (triggerRef.current && triggerRef.current.previousElementSibling && triggerRef.current.previousElementSibling.previousElementSibling) {
                    triggerRef.current.previousElementSibling.previousElementSibling.focus();
                }
                break;
            case 'Home':
            case 'End':
                e.preventDefault();
                break;
            default:
                break;
        }
    };
    return [triggerRef, accordionRef, expanded, onToggle, onKeyDown, activeIndex, setExpanded];
};
exports.useAccordionItem = useAccordionItem;
