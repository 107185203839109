const getCookies = (name) => {
  const nameEQ = `${name}=`;
  if (typeof window === 'undefined') return null
  const arrayOfCookies = document.cookie.split(';');

  for(let rawCookie of arrayOfCookies) {
    let cookie = rawCookie;
    while (cookie.charAt(0) == ' ') cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) == 0) return  cookie.substring(nameEQ.length, cookie.length);
  }

  return null;
}

const DY_CMS_URL = process.env.DY_CMS_URL
const DY_API_URL = `${DY_CMS_URL}${process.env.DY_API_VERSION}`
const cookieRegion = getCookies('NEXT_REGION')
const REGION = typeof cookieRegion === 'string' ? cookieRegion : process.env.NEXT_PUBLIC_REGION

type TypeParams = {
  locale?: string,
  slug?: string,
  customer_id?: string | number,
  category_id?: string,
  categorySlug?: string,
}

interface ICallAPI {
  type: string,
  params?: TypeParams,
  body?: any,
  accept?: string,
  contentType?: string,
  query?: any,
}

export const URLS = (params: TypeParams, query, timestamp = Math.floor((Date.now() / (1000*60*60*24)))) => ({
  'stores': {
    url: `${DY_API_URL}/stores.json?timestamp=${timestamp}`,
    method: 'GET'
  },
  'layout': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/layout.json?timestamp=${timestamp}`,
    method: 'GET'
  },
  'home': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/pages/home.json?timestamp=${timestamp}`,
    method: 'GET'
  },
  'pages': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/pages.json?timestamp=${timestamp}`,
    method: 'GET'
  },
  'page': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/pages/${params.slug}.json?timestamp=${timestamp}`,
    method: 'GET'
  },
  'products': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/products.json?timestamp=${timestamp}`,
    method: 'GET'
  },
  'product': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/products/${params.slug}.json?timestamp=${timestamp}`,
    method: 'GET'
  },
  'packs': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/products/packs.json?timestamp=${timestamp}`,
    method: 'GET'
  },
  'categories': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/categories/product.json?timestamp=${timestamp}`,
    method: 'GET',
    cache: 'no-store',
  },
  'category': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/categories/product/${params.slug}.json?timestamp=${timestamp}`,
    method: 'GET',
    cache: 'no-store',
  },
  'faqs': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/faqs.json?timestamp=${timestamp}`,
    method: 'GET'
  },
  'topsellers': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/products/top_sellers`,
    method: 'GET'
  },
  'similars': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/products/${params.slug}/similar`,
    method: 'GET'
  },
  'relateds': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/products/${params.slug}/related`,
    method: 'GET'
  },
  'user-usual-purchases': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/customers/${params.customer_id}/products`,
    method: 'GET'
  },
  'user-usual-purchases_limit': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/customers/${params.customer_id}/products_limit`,
    method: 'GET'
  },
  'search': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/search?q=${query.q}`,
    method: 'GET'
  },
  'search-products': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/products/search?q=${query.q}`,
    method: 'GET'
  },
  'brand': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/brands/${params.slug}`,
    method: 'GET'
  },
  'brands': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/brands/`,
    method: 'GET'
  },
  'brand-categories': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/brands/${params.slug}/categories/`,
    method: 'GET'
  },
  'brand-category': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/brands/${params.slug}/categories/${params.categorySlug}`,
    method: 'GET'
  },
  'stock-notifications': {
    url: `${DY_API_URL}/${REGION}/${params.locale}/stock_notifications`,
    method: 'POST',
  }
})

const urlFor = ({ type,  body,  params,  accept, contentType, query }): [string, any] => {
  if (!URLS(params, query)[type]) throw new Error(`api/urls.ts -> No URL of type ${type}`)
  const { url, method, cache } = URLS(params, query)[type]
  const options = {
    method,
    body: method === 'GET' ? null : JSON.stringify(body),
    headers: {
      'Accept': accept,
      'Content-Type': contentType,
    },
    ...(cache && { cache, next: { revalidate: 600 }})
  }

  return [url, options]
}


const defaultParams = {
  locale: process.env.NEXT_PUBLIC_LOCALE
}

export const callAPI = async ({ type, body = {}, params = defaultParams, accept = 'application/json', contentType = 'application/json', query = {} }: ICallAPI): Promise<[any, boolean]> => {
  const _params = { ...defaultParams, ...params }
  const [url, options] = urlFor({ type, body, params: _params, accept, contentType, query })

  try {
    const res: Response = await fetch(url, options)

    if (res.status == 204) return [null, false]

    const json = await res.json()
    return [json, !res.ok]

  } catch (error) {
    return [{status: error.status, message: error.message, error: 'server_error'}, true]
  }
}
