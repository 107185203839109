const DY_CMS_URL = process.env.DY_CMS_URL
const DY_API_URL = `${DY_CMS_URL}${process.env.DY_API_VERSION}`

const LNG = {
  'en': 'en',
  'es': 'es'
}

const URLS = (url) => ({
  'layout': (locale) => `${url}${LNG[locale]}/layout`,
  'home': (locale) => `${url}${LNG[locale]}/pages/home`,
  'pages': (locale) => `${url}${LNG[locale]}/pages`,
  'page': (locale, slug) => `${url}${LNG[locale]}/pages/${slug}`,
  'products': (locale) => `${url}${LNG[locale]}/products`,
  'product': (locale, slug) => `${url}${LNG[locale]}/products/${slug}`,
  'comparator': (locale) => `${url}${LNG[locale]}/comparator`,
  'bag': (locale) => `${url}${LNG[locale]}/bag`,
  'checkout': (locale) => `${url}${LNG[locale]}/checkout`,
  'account': (locale) => `${url}${LNG[locale]}/account`,
  'account/orders': (locale) => `${url}${LNG[locale]}/account/orders`,
  'articles': (locale) => `${url}${LNG[locale]}/articles`,
  'article': (locale, slug) => `${url}${LNG[locale]}/articles/${slug}`,
  'categories': (locale, category_type) => `${url}${LNG[locale]}/categories/${category_type}`,
  'category': (locale, slug, category_type) => `${url}${LNG[locale]}/categories/${category_type}/${slug}`,
  'faqs': (locale) => `${url}${LNG[locale]}/faqs`,
  'search': (locale, params) => `${url}${LNG[locale]}/search?${params}`,
});

export const urlFor = (type: string, params: any = {}): [string, object] => {
  let defaultOptions = {
    method: 'GET'
  }
  const { slug, locale, collection } = params

  if (!URLS(DY_API_URL)[type]) throw new Error(`URL for type "${type}" does not exist`)

  if (!locale) throw new Error(`Missing language set for page "${type}"`)
  if (!LNG[locale]) throw new Error(`Missing language for language "${locale}"`)

  return [URLS(DY_API_URL)[type](locale, slug, collection), defaultOptions]
}
