"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMediaType = void 0;
var react_1 = require("react");
var BREAKPOINTS = {
    mobile: 375,
    tablet: 768,
    desktopXs: 992,
    desktop: 1440,
    getMediaType: function (width) {
        return width < this.tablet ? 'mobile' :
            width < this.desktopXs ? 'tablet' :
                'desktop';
    }
};
var getInfo = function (isClient) {
    return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
        mediaType: isClient ? BREAKPOINTS.getMediaType(window.innerWidth + 1) : 'desktop',
    };
};
var useMediaType = function () {
    var isClient = typeof window === 'object';
    var _a = __read((0, react_1.useState)(getInfo(isClient).mediaType), 2), mediaType = _a[0], setMediaType = _a[1];
    var handleResize = function () {
        var info = getInfo(isClient);
        if (info.mediaType !== mediaType)
            setMediaType(info.mediaType);
    };
    (0, react_1.useEffect)(function () {
        if (!isClient)
            return function () { };
        window.addEventListener('resize', handleResize);
        handleResize();
        return function () { return window.removeEventListener('resize', handleResize); };
    });
    return mediaType;
};
exports.useMediaType = useMediaType;
