import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { mq, getRelative, getP40, createGrid } from '@/styles'
import { SliderFlickity, ProductCard, Button } from '@dy/commons/components'
import { usePreOrderCart } from '../../hooks'
import Link from 'next/link'
import { usei18n } from '@dy/commons/i18n'

interface ISlidersHome {
  data: any,
  dataKey?: string,
  readonly setProductDetailed: any,
  displayProductDetail: () => void,
}

const Section = styled.section`
  ${createGrid()}

  margin: ${getRelative(44, 'mobile')} 0;

  ${mq.greaterThan('tablet')`
    margin: ${getRelative(44, 'desktop')} 0;
  `}

  h2 {
    ${getP40()}
  }

  .flickity-slider {
    transform: translateX(0) !important;

    ${mq.greaterThan('tablet')`
      transform: translateX(3.17%) !important;
    `}
  }

  .slider--flickity {
    grid-area: 2/ 1 / span 2 / span 6;
    padding: 0 ${getRelative(10, 'mobile')} ${getRelative(13, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding:unset;
      grid-area: 2 / 1 / span 2 / span 12;

    `}
    article {
      margin-right: ${getRelative(8.75, 'mobile')};
      width: ${getRelative(190, 'mobile')};
      ${mq.greaterThan('tablet')`
        margin-right: ${getRelative(8.75, 'desktop')};
        width: ${getRelative(261, 'desktop')};
      `}
    }
  }
  .cta {
    grid-area: 1 / 1 / span 1 / span 6;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 ${getRelative(10, 'mobile')} ${getRelative(13, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding: 0 ${getRelative(50, 'desktop')} ${getRelative(20, 'desktop')};
      grid-area: 1 / 1 / span 1 / span 12;

    `}

    button {
      height: ${getRelative(46, 'mobile')};
      margin: 0;

      ${mq.greaterThan('tablet')`
        height: unset;
      `}
    }

  }
`

const getSlides = (products, onClick, preOrderCart) => products.map((product, idx) => {
  return (
    <div className='cart-wrapper' key={idx}>
      <ProductCard data={{...product, variant: {...product.variant, idx }}} onClick={e => {
        e.preventDefault()
        onClick(product, idx)
      }} validForCartType={(preOrderCart && product.is_preorder_only) || (!preOrderCart && !product.is_preorder_only)} preOrderCart={preOrderCart}/>
    </div>
  )
})


const NEXT_LOCALE = process.env.NEXT_PUBLIC_LOCALE

export const SlidersHome = memo<ISlidersHome>(({ data, dataKey, setProductDetailed, displayProductDetail  }) => {
  const { layout } = usei18n()
  const { preOrderCart } = usePreOrderCart()

  const onClick = (product, idx) => {
    setProductDetailed({
      variant_id: product.variant.bigcommerce_id,
      slug: product.slug,
      idx
    })
    displayProductDetail()
  }

  const Slides = useMemo(() => getSlides(data.products, onClick, preOrderCart), [data.products, preOrderCart])

  return (
    <Section className='list-wrapper'>
      <div className='cta'>
        <h2>{data?.category?.name}</h2>
        <Button outline={true}>
          <Link href={(dataKey === 'usual-purchases' || dataKey === 'packs') ? data?.category?.slug : `/shop/${data?.category?.slug[NEXT_LOCALE]}`}>
           {layout?.product_page?.product_main.see_more}
          </Link>
        </Button>
      </div>
      <SliderFlickity className={'slider--product-card'} loop={false} pageDots={false} groupCells={'90%'}>
        {Slides}
      </SliderFlickity>
    </Section>
  )
})
