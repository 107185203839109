import { getProductQuery } from './queries'

const NEXT_API_BASE = '/api/bigcommerce'

const BG_URLS = (params) => ({
  'update-customer': {
    url: NEXT_API_BASE + `/customers/update`,
    method: 'POST'
  },
  'check-customer': {
    url: NEXT_API_BASE + `/customers/check/${params.email}`,
    method: 'GET'
  },
  'product': {
    url: NEXT_API_BASE + `/product/${params.slug}`,
    method: 'GET'
  },
  'checkout-redirect': {
    url: NEXT_API_BASE + `/checkout/${params.checkout_id}`,
    method: 'GET'
  },
  'login-url': {
    url: NEXT_API_BASE + `/customers/url/${params.customer_id}`,
    method: 'POST'
  },
  'check-cart': {
    url: NEXT_API_BASE + `/customers/cart/${params.cart_id}`,
    method: 'PUT'
  },
  'change-channels': {
    url: NEXT_API_BASE + `/customers/channel/${params.customer_id}`,
    method: 'GET'
  },
})


const urlFor = ({ type,  body, params }): [string, any] => {
  if (!BG_URLS(params)[type]) {
    throw new Error(`api/bigcommerce.ts -> No URL of type ${type}`)
  }

  const { url, method } = BG_URLS(params)[type]

  const options = {
    method,
    body: method === 'GET' ? null : JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return [url, options]
}


interface ICallAPI {
  type: string,
  body?: any,
  params?: any,
}

export const callBigcommerce = async ({ type, body = {}, params = {}}: ICallAPI): Promise<[any, boolean]> => {
  const [url, options] = urlFor({ type, body, params })

  try {
    const res: Response = await fetch(url, options)

    const json = await res.json()

    return [json, !res.ok]
  } catch (error) {
    return [{status: error.status, message: error.message, error: 'server_error'}, true]
  }
}

const token = process.env.BIGCOMMERCE_STOREFRONT_API_TOKEN_GRAPHQL
const NEXT_PUBLIC_HOSTNAME_BC_SHOP = process.env.NEXT_PUBLIC_HOSTNAME_BC_SHOP

export const fetchProduct = async (path, includeTax = false) => {
  const res = await fetch(`https://${NEXT_PUBLIC_HOSTNAME_BC_SHOP}/graphql`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: getProductQuery,
      variables: { path: '/' + path, includeTax }
    }),
  })

  const data = await res.json()

  return [{ product: data.data?.site?.route?.node ?? null }]
}
