import { memo, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import Link from 'next/link'
import { mq, createGrid, getRelative, getTransition, getP20_1 } from '@/styles'
import { useMediaType } from '@dy/commons/hooks'
import { usei18n } from '@dy/commons/i18n'

interface IBrandsSection {
  brands: any
}
const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_LOCALE

const Section = styled.section`
  ${createGrid()}

  margin-bottom: ${getRelative(73, 'mobile')};
  margin-top: ${getRelative(60, 'mobile')};

  + .pack-highlighted-section {
    margin-top: -${getRelative(73, 'mobile')};
  }

  h2 {
    ${getP20_1()}
    grid-area: 1 / 1 / span 1 / span 6;
    text-align: center;
    margin-bottom: ${getRelative(28, 'mobile')};
  }

  ${mq.greaterThan('tablet')`
    ${createGrid()}
    margin-bottom: ${getRelative(84, 'desktop')};
    margin-top: ${getRelative(80, 'desktop')};

    + .pack-highlighted-section {
      margin-top: -${getRelative(84, 'desktop')};
    }

    h2 {
      grid-area: 1 / 1 / span 1 / span 12;
      margin-bottom: ${getRelative(40, 'desktop')};
    }
  `}
`

const BrandWrapper = styled(motion.div)`
  grid-area: 2 / 1 / span 2 / span 6;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: ${getRelative(57, 'mobile')};
  margin: 0 ${getRelative(20, 'mobile')};

  article, a, figure { height: inherit; }

  article {
    padding: ${getRelative(5, 'mobile')} ${getRelative(15, 'mobile')};
  }

  img {
    height: 100%;
    object-fit: contain;
    filter: invert(49%) sepia(37%) saturate(331%) hue-rotate(69deg) brightness(92%) contrast(97%);
  }

  ${mq.greaterThan('tablet')`
    grid-area: 2 / 2 / span 1 / span 10;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 50px;
    height: ${getRelative(108, 'desktop')};

    article {
      padding: 0;
      margin: 0 ${getRelative(30, 'desktop')};
    }
  `}
`

const logoVariants = {
  initial: {
    opacity: 0,
    transition: {
      ease: 'easeIn',
      duration: .8,
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
      duration: .8,
    }
  },
  exit: {
    opacity: 0,
    transition: {
      ease: 'easeIn',
      duration: .8,
    }
  },
}

const getLogos = (images) => images.map((img, idx) => {
  const { logo, slug } = img
  return (
    <AnimatePresence initial={false} key={idx} exitBeforeEnter>
      <motion.article className='brand' key={logo} variants={logoVariants} initial='initial' exit='exit' animate='animate'>
      <Link href={`/brand/${slug?.[DEFAULT_LOCALE]}`} passHref>
        <motion.figure key={logo?.url} variants={logoVariants} initial='initial' exit='exit' animate='animate'>
          <img src={logo?.url} alt='Brand logo'/>
        </motion.figure>
       </Link>
      </motion.article>
    </AnimatePresence>
  )
})

const getNewLogoArray = (prevLogoArray, images) => {
  const missingLogoArray = images.filter(img => {
    return !prevLogoArray.includes(img)
  })

  const newArrayLogo = Math.floor(Math.random() * missingLogoArray.length)
  const oldArrayLogo = Math.floor(Math.random() * prevLogoArray.length)

  const updatedArray = [...prevLogoArray]
  updatedArray[oldArrayLogo] = missingLogoArray[newArrayLogo]

  return updatedArray
}

const transition = { ...getTransition(1) }
const wrapperVariants = {
  inactive: {
    opacity: 0,
    transition
  },
  active: {
    opacity: 1,
    transition: {
      ...getTransition(1),
      delay: 1.5
    }
  }
}

export const BrandsSection = memo<IBrandsSection>(({ brands }) => {
  // const images = brands.map(brand => brand?.logo?.url)
  // const slug = brands.map(brand => brand?.slug?.[DEFAULT_LOCALE])
  const mediaType = useMediaType()
  const initialArray = useMemo(() => brands.slice(0, mediaType === 'mobile' ? 3 : 5), [brands, mediaType])
  // const initialArraySlug = slug.slice(0, mediaType === 'mobile' ? 3 : 5)
  const [logoArray, changeLogoArray] = useState(initialArray)
  // const [logoArray, changeLogoArray] = useState(initialArray)
  const { page: { brands: brand_locale } } = usei18n()

  const [ref, inView] = useInView({
    rootMargin: '0px', // TOP  RIGHT
    threshold: 1,
    triggerOnce: true
  })

  const Logos = useMemo(() => getLogos(logoArray), [logoArray])

  useEffect(() => {
    if (brands.length > initialArray.length) {
      const interval = setInterval(() => {
        changeLogoArray(prevLogoArray => getNewLogoArray(prevLogoArray, brands));
      }, 3200);

      return () => clearInterval(interval);
    }
  }, [Logos, initialArray])

  return (
    <Section ref={ref}>
      <h2>{brand_locale.title}</h2>
      <BrandWrapper variants={wrapperVariants} animate={inView ? 'active' : 'inactive'} initial='inactive'>
        {Logos}
      </BrandWrapper>
    </Section>
  )
})
