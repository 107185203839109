import { memo, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { mq, createGrid, getRelative, getTransition, getP141, getP113_Header, getP93_2, getP53, getP26, getP30_1, getP20_3, getP15_1 } from '@/styles'
import { Button, Figure } from '@dy/commons/components'
import { useMediaType } from '@/hooks'
import { parserMedia } from '@dy/commons/utils'

interface IHomeHeader {
  data: any
}

const Header = styled.header<any>`
  ${createGrid()}
  place-items: center;
  grid: auto-flow / repeat(8, ${getRelative(64, 'mobile')});
  overflow-x: hidden;
  margin-top: 60px;

  div[class*='homeheaderimage'] > figure {
    user-select: none;
    pointer-events: none;
    filter: drop-shadow(8px 13px 1px rgb(78 57 14 / 7%));
    height: 100%;
  }

  &[data-length='1'] {
    margin-bottom: ${getRelative(20, 'mobile')};
    margin-top: 60px;
    color: #FFF;

    div[class*='homeheaderimage'] {
      grid-area: 1 / 2 / span 1 / span 4;
      transform: none;
      width: 100%;
      z-index: -1;

      &.figure--video {
        grid-area: 1 / 2 / span 1 / span 6;
        filter: none;
        z-index: -1;
        height: ${getRelative(277, 'mobile')};
        overflow: hidden;

        iframe {
          transform: ${({ $videoAspectRatio }) => 'scale(' + $videoAspectRatio + ')'} !important;
          width: 100% !important;

          @media (min-aspect-ratio: 16/9) {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
          }
        }

        div[data-vimeo-initialized='true'] {
          @media (min-aspect-ratio: 16/9) {
            height: 0;
            overflow: hidden;
            padding-bottom: 56.25%;
            position: relative;
            transform: ${({ $videoAspectRatio }) => 'scale(' + $videoAspectRatio + ')'} !important;
          }
        }

        & + [class*='home-header-pos'] {
          grid-area: 1 / 2 / span 2 / span 6;
          margin-top: 0;
        }
      }

      &.homeheaderimage-center {
        grid-area: 1 / 2 / span 1 / span 6;
        z-index: -1;
        height: ${getRelative(277, 'mobile')};

        > figure {
          filter: none;
        }

        img {
          object-fit: cover;
          height: 100%;
        }

        & + [class*='home-header-pos'] {
          grid-area: 1 / 2 / span 2 / span 6;
          margin-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }

  &[data-length='2'] {
    div[class*='homeheaderimage']:nth-of-type(1) { grid-area: 1 / 1 / span 1 / span 4; }

    div[class*='homeheaderimage']:nth-of-type(2) { grid-area: 1 / 5 / span 1 / span 4; }
  }

  &[data-length='3'] {
    div[class*='homeheaderimage']:nth-of-type(1) { grid-area: 1 / 1 / span 1 / span 4; }

    div[class*='homeheaderimage']:nth-of-type(2) { grid-area: 1 / 3 / span 1 / span 4; }

    div[class*='homeheaderimage']:nth-of-type(3) { grid-area: 1 / 5 / span 1 / span 4; }
  }

  ${mq.greaterThan('tablet')`
    height: ${getRelative(750, 'desktop')};
    margin-bottom: ${getRelative(50, 'desktop')};
    margin-top: 0;
    overflow: hidden;

    div[class*='homeheaderimage'] {
      width: ${getRelative(720, 'desktop')};
      height: ${getRelative(750, 'desktop')};
    }

    &[data-length] div[class*='homeheaderimage'] {
      > figure {
        filter: drop-shadow(25px 40px 4px rgb(78 57 14 / 7%));
      }

      &.homeheaderimage-left {
        grid-area: 1 / 1 / span 1 / span 6;
        margin: 0;
      }
      &.homeheaderimage-center { grid-area: 1 / 4 / span 1 / span 6; }
      &.homeheaderimage-right { grid-area: 1 / 7 / span 1 / span 6; }
    }

    &[data-length='1'] {
      margin-top:0;
      margin-bottom: ${getRelative(50, 'desktop')};

      div[class*='homeheaderimage'] {
        grid-area: 1 / 1 / span 3 / span 12;

        &.homeheaderimage-center {
          grid-area: 1 / 1 / span 1 / span 12;
          padding: 0 ${getRelative(50, 'desktop')};
          transform: none;
          width: 100%;
          height: inherit;

          & + [class*='home-header-pos'] {
            &.home-header-pos-left { grid-area: 1 / 2 / span 1 / span 5; }
            &.home-header-pos-center { grid-area: 1 / 3 / span 1 / span 8; }
            &.home-header-pos-right { grid-area: 1 / 7 / span 1 / span 5; }
          }
        }
      }

      figure {
        &.figure--video {
          grid-area: 1 / 1 / span 3 / span 12;
          height: ${getRelative(715, 'desktop')};

          & + [class*='home-header-pos'] {
            &.home-header-pos-left { grid-area: 1 / 2 / span 3 / span 5; }
            &.home-header-pos-center { grid-area: 1 / 3 / span 3 / span 8; }
            &.home-header-pos-right { grid-area: 1 / 7 / span 3 / span 5; }
          }
        }
      }
    }
  `}
`

const TextWrapper = styled.div<any>`
  grid-area: 2 / 3 / span 1 / span 4;
  margin-top: 0px;
  padding: 0 ${getRelative(30, 'mobile')}; // fix for animations
  width: 100%;
  ${({ text_valign }) => text_valign === 'top' ? css`padding-top: ${getRelative(20, 'mobile')}; align-self: start;` : text_valign === 'bottom' ? css`padding-bottom: ${getRelative(30, 'mobile')}; align-self: end;` : css`align-self: center;` };

  h2 {
    ${({ title_size }) => title_size === 'xl' ? getP141() : title_size === 'l' ? getP113_Header() : title_size === 'm' ? getP93_2() : getP53() };
  }

  p {
    font-family: ${({ theme }) => theme.fonts.basier};
    ${({ subtitle_size }) => subtitle_size === 'l' ? css`${getP30_1()} font-weight: 100;` : subtitle_size === 'm' ? css`${getP30_1()} font-weight: 100;` : getP15_1() };
    margin: 0 0 ${getRelative(10, 'mobile')};
  }

  button {
    height: ${getRelative(30, 'mobile')};
    & > div:last-of-type {
      font-size: ${getRelative(12, 'mobile')};
    }
  }

  .home-header-align-left { text-align: center; }
  .home-header-align-center { text-align: center; }
  .home-header-align-right { text-align: center; }

  ${mq.greaterThan<any>('tablet')`
    grid-area: 1 / 5 / span 1 / span 4;
    margin: ${getRelative(60, 'desktop')} 0;
    padding: 0;
    ${({ text_valign }) => text_valign === 'top' ? css`padding-top: ${getRelative(60, 'desktop')}; align-self: start;` : text_valign === 'bottom' ? css`padding-bottom: ${getRelative(70, 'desktop')}; align-self: end;` : css`align-self: center;` };

    .home-header-align-left { text-align: left; }
    .home-header-align-center { text-align: center; }
    .home-header-align-right { text-align: right; }

    p {
      ${({ subtitle_size }) => subtitle_size === 'l' ? css`${getP30_1()} font-weight: 100;` : subtitle_size === 'm' ? css`${getP26()} font-weight: 100;` : getP20_3() };

      &.home-header-text-subtitle {
        ${({ subtitle_size }) => subtitle_size === 'm' ? css`letter-spacing: -1.5px;` : ''};
      }
    }

    &.home-header-pos-left {
      grid-area: 1 / 2 / span 1 / span 5;

      p {
        &.home-header-align-left {
          margin: ${getRelative(15, 'desktop')} calc(100vw / 12) ${getRelative(30, 'desktop')} 0;
        }
        &.home-header-align-center {
          margin: ${getRelative(15, 'desktop')} calc(100vw / 24) ${getRelative(30, 'desktop')};
        }
        &.home-header-align-right {
          margin: ${getRelative(15, 'desktop')} 0 ${getRelative(30, 'desktop')} calc(100vw / 12);
        }
      }
    }
    &.home-header-pos-center {
      grid-area: 1 / 3 / span 1 / span 8;

      p {
        &.home-header-align-left {
          margin: ${getRelative(15, 'desktop')} calc(100vw / 12 * 4) ${getRelative(30, 'desktop')} 0;
        }
        &.home-header-align-center {
          margin: ${getRelative(15, 'desktop')} calc(100vw / 6) ${getRelative(30, 'desktop')};
        }
        &.home-header-align-right {
          margin: ${getRelative(15, 'desktop')} 0 ${getRelative(30, 'desktop')} calc(100vw / 12 * 4);
        }
      }
    }
    &.home-header-pos-right {
      grid-area: 1 / 7 / span 1 / span 5;

      p {
        &.home-header-align-left {
          margin: ${getRelative(15, 'desktop')} calc(100vw / 12) ${getRelative(30, 'desktop')} 0;
        }
        &.home-header-align-center {
          margin: ${getRelative(15, 'desktop')} calc(100vw / 24) ${getRelative(30, 'desktop')};
        }
        &.home-header-align-right {
          margin: ${getRelative(15, 'desktop')} 0 ${getRelative(30, 'desktop')} calc(100vw / 12);
        }
      }
    }

    button.home-header-align-left { margin: 0 auto 0 0; }
    button.home-header-align-right { margin: 0 0 0 auto; }
    button {
      height: unset;
      & > div:last-of-type {
        font-size: unset;
      }
    }
  `}
`

const getImages = (images, main_type, mediaType) => images.map((img, idx) => {
  const mobile = mediaType === 'mobile'
  const _variants = main_type === 'media' ? img_variants_single : img?.position == 'left' ? img_variants_left : img?.position == 'right' ? img_variants_right : img_variants_center

  return (
    <motion.div key={idx} variants={_variants} animate={'active'} initial='inactive' className={'homeheaderimage-' + (main_type === 'media' ? 'center' : img?.position)} custom={mobile}>
      <Figure lazy={false} media={parserMedia(img)} oldVersion/>
    </motion.div>
  )
})

const text_variants_title = {
  inactive: {
    opacity: 0,
    y: '30px'
  },
  active: (singleImage) => ({
    opacity: 1,
    y: '0px',
    transition: { 
      ...getTransition(.5),
      delay: singleImage ? 2 : .3
    }
  })
}

const text_variants_subtitle = {
  inactive: {
    opacity: 0,
    y: '30px'
  },
  active: (singleImage) => ({
    opacity: 1,
    y: '0px',
    transition: { 
      ...getTransition(.5),
      delay: singleImage ? 2.1 : .5
    }
  })
}

const text_variants_button = {
  inactive: {
    opacity: 0,
    y: '30px'
  },
  active: (singleImage) => ({
    opacity: 1,
    y: '0px',
    transition: { 
      ...getTransition(.5),
      delay: singleImage ? 2.2 : .3
    }
  })
}

const img_variants_left = {
  inactive: {
    opacity: 0,
    rotate: '-10deg',
    y: getRelative(260, 'desktop')
  },
  active: (mobile) => ({
    opacity: 1,
    rotate: '0deg',
    y: mobile ? 0 : getRelative(65, 'desktop'),
    transition: { 
      ...getTransition(1)
    }
  })
}

const img_variants_right = {
  inactive: (mobile) => ({
    opacity: 0,
    rotate: '-15deg',
    y: mobile ? getRelative(350, 'mobile') : getRelative(170, 'desktop')
  }),
  active: (mobile) => ({
    opacity: 1,
    rotate: '0deg',
    y: mobile ? 0 : getRelative(-100, 'desktop'),
    transition: { 
      ...getTransition(1),
      delay: .2
    }
  })
}

const img_variants_center = {
  inactive: (mobile) => ({
    opacity: 0,
    rotate: '17deg',
    y: mobile ? getRelative(350, 'mobile') : getRelative(300, 'desktop')
  }),
  active: (mobile) => ({
    opacity: 1,
    rotate: '0deg',
    y: mobile ? 0 : getRelative(130, 'desktop'),
    transition: { 
      ...getTransition(1),
      delay: .4
    }
  })
}

const img_variants_single = {
  inactive: {
    opacity: 0
  },
  active: {
    opacity: 1,
    transition: { 
      ...getTransition(.5),
      delay: 1.5
    }
  }
}

export const HomeHeader = memo<IHomeHeader>(({ data }) => {
  const { title, subtitle, link, left_image, center_image, right_image, text_position: position, text_align: alignment, media, main_type, text_valign, title_size, subtitle_size } = data[0]
  const videooRef = useRef(null)
  const [videoAspectRatio, setVideoAspectRatio] = useState(null)

  const images = useMemo(() => {
    if (main_type === 'product') {
      return [left_image, center_image, right_image]
    } else {
      return [media]
    }
  }, [left_image, center_image, right_image, media, main_type])

  const mediaType = useMediaType()
  const ImageList = useMemo(() => getImages(images, main_type, mediaType), [images, mediaType])
  const filteredImages = images.filter(el => el != null)
  const singleImage = filteredImages?.length === 1

  useEffect(() => {
    if (!videooRef.current) return

    const aspectRatio = videooRef.current.clientWidth / videooRef.current.scrollHeight
    
    if(aspectRatio > (16 / 9)) {
      setVideoAspectRatio(aspectRatio / (16 / 9))
    } else {
      setVideoAspectRatio((16 / 9) / aspectRatio)
    }
  }, [])

  return (
    <Header data-length={filteredImages.length} ref={videooRef} $videoAspectRatio={videoAspectRatio}>
      { ImageList }
      <TextWrapper className={'home-header-pos-' + position} title_size={title_size} subtitle_size={subtitle_size} text_valign={text_valign}>
        <motion.h2 custom={singleImage} variants={text_variants_title} animate={'active'} initial='inactive' className={'home-header-align-' + alignment + ' home-header-text-title'}>{title}</motion.h2>
        <motion.p custom={singleImage} variants={text_variants_subtitle} animate={'active'} initial='inactive' className={'home-header-align-' + alignment + ' home-header-text-subtitle'}>{subtitle}</motion.p>
        <a href={link.path}>
          <Button custom={singleImage} variants={text_variants_button} animate={'active'} initial='inactive' className={'home-header-align-' + alignment + ' home-header-text-button'}>
            {link.title}
          </Button>
        </a>
      </TextWrapper>
    </Header>
  )
})
