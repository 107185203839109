"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useViewportScroll = void 0;
var framer_motion_1 = require("framer-motion");
var react_1 = require("react");
var useViewportScroll = function (SCROLL_CENTINELLA, callback) {
    var scrollYProgress = (0, framer_motion_1.useViewportScroll)().scrollYProgress;
    (0, react_1.useEffect)(function () {
        var stop = scrollYProgress.onChange(function () {
            if (scrollYProgress.get() > SCROLL_CENTINELLA) {
                callback(false);
            }
            else {
                callback(true);
            }
        });
        return function () {
            stop();
        };
    }, [SCROLL_CENTINELLA, callback]);
};
exports.useViewportScroll = useViewportScroll;
