import { memo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { use100vh } from 'react-div-100vh'
import { mq, getTransition, getP20_1 } from '@/styles'

import { useRouter } from 'next/router'

const Aside = styled(motion.aside)`
    opacity: 0;
    pointer-events: none;
    user-select: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transition: opacity 300ms ease;
    z-index: 10000000;
    overflow-x: hidden;

    background-color: #FFFFFF;

    #is-cart {
        display: none;
    }

    .content-klk {
        display: grid;
        grid: auto / repeat(6, 16.66666667%);
        padding: 0 20px;
        height: 60px;
        border-bottom: 1px solid rgba(0,0,0,.1);

        span {
            grid-area: 1 / 3 / span 1 / span 2;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            ${getP20_1()}
        }

        button {
            grid-area: 1 / 6 / span 1 / span 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            svg g {
              stroke: ${({ theme }) => theme.colors.greenlight};
            }
        }
    }


    .navBar-section {
        padding: 40px 20px 0;

        .navBar-item {
            margin: 0 0 20px 0;

            span, a {
                text-align: left;
                width: 100%;
                text-decoration: none;
                ${getP20_1()}
            }
        }
    }

    &.active {
        opacity: 1;
        pointer-events: auto;
        user-select: auto;
    }

  ${mq.greaterThan('tablet')`
    display: none;
  `}
`

const variants: any = {
  active: {
    opacity: 1,
    userSelect: 'initial',
    pointerEvents: 'initial',
    transition: {
      ...getTransition(.1)
    }
  },
  inactive: {
    opacity: 0,
    userSelect: 'none',
    pointerEvents: 'none',
    transition: {
      ...getTransition(.1)
    }
  },
}

const Traducciones = {
  'it': {
    "title": 'Il tuo account',
    "orderhistory": "Ordini",
    "personalinfo": "Dati personali",
    "addressbook": "Indirizzi",
    "logout": "Chiudi sessione"
  },
  'es': {
    "title": 'Mi cuenta',
    "orderhistory": "Pedidos",
    "personalinfo": "Información personal",
    "addressbook": "Direcciones",
    "logout": "Cerrar sesión",
  },
  'en': {
    "title": 'Il tuo account',
    "orderhistory": "Ordini",
    "personalinfo": "Dati personali",
    "addressbook": "Indirizzi",
    "logout": "Chiudi sessione"
  },
  'fr': {
    "title": 'Mon compte',
    "orderhistory": "Commandes",
    "personalinfo": "Informations personnelles",
    "addressbook": "Adresses",
    "logout": "Se déconnecter",
  },
}

const NEXT_PUBLIC_HOSTNAME_BC_SHOP = process.env.NEXT_PUBLIC_HOSTNAME_BC_SHOP
const getUrl = (url) => {
  return 'https://' + NEXT_PUBLIC_HOSTNAME_BC_SHOP + url
}

export const MenuAccount = memo<any>(({ isActive, onClose }) => {
  const height = use100vh()
  const { locale } = useRouter() || { locale: '' }
  const { title, personalinfo, orderhistory, addressbook, logout } = Traducciones[locale]

  return <Aside variants={variants} animate={isActive ? 'active' : 'inactive'}>
    <nav id="nav-klk" className="navBar navBar--sub navBar--account active" style={{overflowX: 'hidden', height }}>
        <div className="content-klk">
            <span>{title}</span>
            <button onClick={onClose} className="btn-klk-close">
                <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                    <title>i--Close</title>
                    <g id="Screens-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Account---Menu-(mobile)" transform="translate(-382.000000, -24.000000)" stroke="#000000" strokeWidth="1.5">
                            <g id="Group" transform="translate(0.000000, 18.000000)">
                                <g id="i--Close" transform="translate(383.000000, 7.000000)">
                                    <line x1="0.25" y1="0.25" x2="9.75" y2="9.5" id="Line"></line>
                                    <line x1="0.25" y1="0.25" x2="9.75" y2="9.5" id="Line" transform="translate(5.000000, 4.875000) scale(-1, 1) translate(-5.000000, -4.875000) "></line>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
        </div>
        <ul className="navBar-section">
            <li className="navBar-item">
                <a className="navBar-action" href={getUrl(`/account.php?action=account_details`)}>{personalinfo}</a>
            </li>
            <li className="navBar-item">
                <a className="navBar-action" href={getUrl(`/account.php?action=order_status`)}>{orderhistory}</a>
            </li>
            <li className="navBar-item">
                <a className="navBar-action" href={getUrl(`/account.php?action=address_book`)}>{addressbook}</a>
            </li>
          <li className="navBar-item">
              <a className="navBar-action" href={getUrl(`/login.php?action=logout`)} aria-label="Cerrar sesión">
              {logout}
              </a>
          </li>
        </ul>
    </nav>
  </Aside>
})
