"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDateString = void 0;
var isValidDate = function (dateString) { return !isNaN(Date.parse(dateString)); };
function parseDateString(_a) {
    var dateString = _a.dateString, _b = _a.locale, locale = _b === void 0 ? 'es-ES' : _b, _c = _a.includeHour, includeHour = _c === void 0 ? false : _c, _d = _a.includeSeconds, includeSeconds = _d === void 0 ? false : _d, _e = _a.includeTimezone, includeTimezone = _e === void 0 ? false : _e;
    if (!isValidDate(dateString)) {
        console.warn('🗓️ dateString passed is not a valid date pattern and cannot be parsed');
        return '';
    }
    var date = new Date(dateString);
    var options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    if (includeHour) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        if (includeSeconds)
            options.second = '2-digit';
    }
    if (includeTimezone)
        options.timeZoneName = 'short';
    return date.toLocaleString(locale, options);
}
exports.parseDateString = parseDateString;
