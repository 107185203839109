import fetch from 'isomorphic-fetch'

export const call = async (url: string, options: any) => {
  try {
    const res: Response = await fetch(url, options)
    const json = await res.json()
    return res.ok ? [json, false] : [{status: res.status, message: res.statusText}, true]
  } catch (error) {
    return [{status: 500, message: error.message}, true]
  }
}

export const reducerCreator = (initState, reducers) => (state = initState, action) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
}