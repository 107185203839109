import { memo, ReactNode, forwardRef } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { mq, getRelative, getP113, getP20, getTransition, hideScrollbar } from '@/styles'

export const Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0;
  background-color: rgba(0,0,0,.4);
  user-select: none;
  pointer-events: none;
  transition: opacity 300ms ${({ theme }) => theme.ease};

  &.active {
    opacity: 1;
    pointer-events: initial;
    user-select: initial;
  }
`

export const Aside = styled.aside<any>`
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 150;

  ${({ isActive  }) => !isActive && `pointer-events: none; user-select: none`}
`

const _Main = styled.main`
  background-color: ${({ theme }) => theme.colors.greenlight};
  flex-grow: 2;
  overflow: auto;
  pointer-events: auto;
  width: 100%;

  h2 {
    ${getP20()}
    margin-bottom: 30px;

    ${mq.lessThan('tablet')`
      width: 400px;
    `}
  }

  ${mq.greaterThan('tablet')`
    h2 {
      br {
        display: none;
      }
    }
  `}

  .nav-list {
    display: flex;
    flex-direction: column;
    padding: 0 ${getRelative(20, 'mobile')};

    > li {
      margin-bottom: ${getRelative(26, 'mobile')};

      > button,
      > a {
        ${getP113()}
        color: ${({ theme }) => theme.colors.white};

        &.active {
          border-bottom: 0;
          color: ${({ theme }) => theme.colors.orange};
          text-decoration: none;
        }

        > span {
          display: none;
        }
      }
    }
  }
`

interface IMain {
  className?: string,
  children: ReactNode
}

export const Main = memo<IMain>(({ children, className = '' }) => {
  return (
    <_Main className={className}>
      {children}
    </_Main>)
})

const _Body = styled<any>(motion.div)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.greenlight};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  margin-bottom: 40px;
  margin-top: 0;
  overflow: auto;
  pointer-events: none;
  padding-top: ${getRelative(100, 'mobile')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 400;


  main {
    ${hideScrollbar()}
    transition: opacity 300ms 50ms ${({ theme }) => theme.ease};

    opacity: ${({ isActive }) => isActive ? 1 : 0};
  }


  ${mq.greaterThan('tablet')`
    max-width: ${getRelative(600, 'desktop')};
    min-height: 170px;
    width: 100%;
  `}
`

const variants_div = {
  animate: {
    x: '0%',
    transition: {
      ...getTransition(),
    }
  },
  exit: {
    x: '-100%',
    transition: {
      ...getTransition(),
    }
  },
}

interface IBody {
  className?: string,
  children: ReactNode,
  isActive: boolean,
  ref?: any,
}

export const Body = memo<IBody>(forwardRef(({ children, className = '', isActive }, ref) => {
  return (
    <_Body ref={ref} isActive={isActive} initial='exit' animate={isActive ? 'animate' : 'exit'} variants={variants_div} className={className}>
      {children}
    </_Body>)
}))
