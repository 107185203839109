import { memo } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { mq, getRelative } from '@/styles'

const LogoStyled = styled.a`
  margin: 0 auto;
  order: 2;
  padding: 0 ${getRelative(30, 'mobile')};

  ${mq.greaterThan('tablet')`
    grid-column: 1 / span 3;
    margin: 0;
    order: 0;
    padding: 0 ${getRelative(50, 'desktop')};
  `}

  img,
  svg {
    fill: ${({ theme }) => theme.colors.greenlight};
    max-width: 170px;
    width: 100%;

    ${mq.greaterThan('tablet')`
      max-width: 240px;
    `}
  }

  &.white {
    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`

type TypeLogo = {
  fillColor: any
}

export const Logo = memo(({ fillColor }:TypeLogo) => {
  return (
    <Link href='/' passHref>
      <LogoStyled className={`logo ${fillColor}`} >
        <img src={`/images/svg/logo-hellomamma${fillColor === 'white' ? '--white' : ''}.svg`} alt='Logo hellomamma' width='240' height='31'/>
        <span className='srt-only'>Go to Homepage</span>
      </LogoStyled>
    </Link>
  )
})


