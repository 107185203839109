const NEXT_PUBLIC_HOSTNAME_CLIENT = process.env.NEXT_PUBLIC_HOSTNAME_CLIENT

export const search = () => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": `https://${NEXT_PUBLIC_HOSTNAME_CLIENT}`,
    "potentialAction": {
      "@type": "SearchAction",
      "target": `https://${NEXT_PUBLIC_HOSTNAME_CLIENT}/search?q={search_term_string}`,
      "query-input": "required name=search_term_string"
    }
  })
}
