import { combineReducers } from 'redux'

import { reducerCreator } from './helpers'

import { reducers as modalReducers, InitialState as MODAL_INITIAL_STATE } from './modals/reducers'
import { reducers as layoutReducers, InitialState as LAYOUT_INITIAL_STATE } from './layout/layout.reducers'
import { reducers as productReducers, InitialState as PRODUCT_INITIAL_STATE } from './product/product.reducers'
import { reducers as articleReducers, InitialState as ARTICLE_INITIAL_STATE } from './article/article.reducers'
import { reducers as categoryReducers, InitialState as CATEGORY_INITIAL_STATE } from './category/category.reducers'
import { reducers as pageReducers, InitialState as PAGE_INITIAL_STATE } from './page/page.reducers'
import { reducers as selectedProductReducers, InitialState as SELECTED_PRODUCT_INITIAL_STATE } from './selectedProduct/reducers'

export const INITIAL_STATE = {
  modals: MODAL_INITIAL_STATE,
  layout: LAYOUT_INITIAL_STATE,
  product: PRODUCT_INITIAL_STATE,
  article: ARTICLE_INITIAL_STATE,
  category: CATEGORY_INITIAL_STATE,
  page: PAGE_INITIAL_STATE,
  selectedProduct: SELECTED_PRODUCT_INITIAL_STATE
}

export default combineReducers({
  modals: reducerCreator(MODAL_INITIAL_STATE, modalReducers),
  layout: reducerCreator(LAYOUT_INITIAL_STATE, layoutReducers),
  product: reducerCreator(PRODUCT_INITIAL_STATE, productReducers),
  article: reducerCreator(ARTICLE_INITIAL_STATE, articleReducers),
  category: reducerCreator(CATEGORY_INITIAL_STATE, categoryReducers),
  page: reducerCreator(PAGE_INITIAL_STATE, pageReducers),
  selectedProduct: reducerCreator(SELECTED_PRODUCT_INITIAL_STATE, selectedProductReducers)
})
