export const SELECT_PRODUCT = 'SELECT_PRODUCT'

export type Size = {
  bigcommerce_id: string | number | null,
  name: string | number | null,
}

export type Color = {
  bigcommerce_id: string | number,
  name: string,
  product_media: any[]
}

export type Variant = {
  bigcommerce_id: string | number,
  option_values: number[],
  units?: number | any
}

export interface ProductInterface {
  bigcommerce_id: string | number
  is_new_release: boolean,
  name: string,
  sizes: Size[]
  colors: Color[],
  variants: Variant[],
  [key: string]: any
}

export interface SelectProductAction {
  type: typeof SELECT_PRODUCT,
  payload: { product: ProductInterface, selectedColor?: Color }
}