
import { useState, useEffect, useMemo, useContext } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import useCart from '@custom-bigcommerce/storefront-data-hooks/cart/use-cart'
import useCustomer from '@custom-bigcommerce/storefront-data-hooks/use-customer'
import { callBigcommerce } from '@/api'
import { useModal, useMediaType } from '@/hooks'
import { modalAuthId } from '@/modals'
import { mq, getRelative, getTransition, getP12_2, getP9_1 } from '@/styles'
import { NewCartItemsContext } from '@dy/commons/components'
import {  AnimatePresence, motion } from 'framer-motion'
import { currencyFormatter } from '@/utils'
import { CartUrlContext } from '@dy/commons/hooks'

const ItemsInCartCircle = styled.div<any>`
  background-color: ${({ theme }) => theme.colors.orange};
  border: 1px solid ${({ theme, isMenuActive }) => theme.colors[isMenuActive ? 'greenlight' : 'background']};
  border-radius: 50%;
  bottom: -1px;
  opacity: ${({ visible }) => visible ? 1 : 0};
  position: absolute;
  pointer-events: none;
  right: 0;
  transition: 250ms opacity ${({ ease }) => ease }, 250ms border-color ${({ ease }) => ease };
  user-select: none;
  width: ${getRelative(15, 'mobile')};
  height: ${getRelative(15, 'mobile')};

  ${mq.greaterThan('tablet')`
    width: ${getRelative(10, 'desktop')};
    height: ${getRelative(10, 'desktop')};
  `}
`

const CartLink = styled.a<any>`
  &.loading {
    pointer-events: none;
    user-select: none;
  }

  border: none;
  border-radius: unset;
  padding: 0;
  span {
    ${getP9_1()}
    margin-left: 0;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

  }

  &.empty {
    border: none;
    ${mq.greaterThan('tablet')`
      padding: 0px;
  `}
  }

  &.not_empty {
    ${mq.greaterThan('tablet')`
      border: 1px solid ${({ theme }) => theme.colors.orange};
      border-radius: ${getRelative(90, 'desktop')};
      padding: ${getRelative(10, 'desktop')};

      span {
        ${getP12_2()}
        margin-left:  ${getRelative(8, 'desktop')};
        color: ${({ theme }) => theme.colors.orange};
      }
    `}

 }
`

const NEXT_PUBLIC_HOSTNAME_BC_SHOP = process.env.NEXT_PUBLIC_HOSTNAME_BC_SHOP

const userLoggedInVariants = {
  enter: {
    x: '100%',
    transition: { ...getTransition(.5) }
  },
  animate: {
    x: '0%',
    transition: { ...getTransition(.5) }
  },
  exit: {
    x: '100%',
    transition: { ...getTransition(.5) }
  }
}

const TRANSLATIONS = {
  'es': {
    'account': 'Cuenta'
  },
  'en': {
    'account': 'Account'
  },
  'fr': {
    'account': 'Mon compte'
  }
}

export const NavLinkAccount = ({ onClick, fillColor, isUserLoggedIn }) => {
  const { open } = useModal(modalAuthId)
  const { data: userData }  = useCustomer()
  const [urlHref, setUrl] = useState('')
  const mediaType = useMediaType()
  const color = useMemo(() => userData?.entityId ? 'orange' : fillColor, [fillColor, userData?.entityId])
  const { locale } = useRouter()

  useEffect(() => {
    if (userData?.entityId?.toString()) {
      setUrl('https://' + NEXT_PUBLIC_HOSTNAME_BC_SHOP + '/account.php')
    }
  }, [userData])

  const clickAction = useMemo(() => mediaType === 'mobile' && userData?.entityId ? onClick : open, [mediaType, onClick, userData])

  return (
    <AnimatePresence exitBeforeEnter>
      {urlHref && mediaType !== 'mobile' && isUserLoggedIn ?
        <motion.a href={urlHref} title={'Account'} className={`account ${isUserLoggedIn ? 'log' : ' '}`} variants={userLoggedInVariants} initial='initial' exit='exit' animate={'animate'}>
          <img src={`/images/svg/i--account--${color}.svg`} alt='Icon account' width='18' height='18'/>
          <span className='user_name'>{userData?.firstName?.toString()}</span>
        </motion.a>
      :
        <motion.button onClick={clickAction} title={'Account'} className='account' variants={userLoggedInVariants} initial='initial' exit='exit' animate={'animate'}>
          <img src={`/images/svg/i--account--${color}.svg`} alt='Icon account' width='18' height='18'/>
          <span className='account_s'>{TRANSLATIONS[locale].account}</span>
        </motion.button>
      }
    </AnimatePresence>
  )
}

const getCartUrl = async (id, setUrl, setAwaitingResponse, setCartUrl) => {
  setAwaitingResponse(true)
  const BC_URL = 'https://' + NEXT_PUBLIC_HOSTNAME_BC_SHOP
  if (id) {
    const [res] = await callBigcommerce({ type: 'checkout-redirect', params: { checkout_id: id } })
    setUrl(res.data?.cart_url ?? BC_URL + '/cart.php')
    setCartUrl({cart_url: res.data?.cart_url ?? BC_URL + '/cart.php', checkout_url: res.data?.checkout_url ?? ''})

    if (!res.data?.cart_url) console.warn('Error getCartUrl', res)
  } else {
    setUrl(BC_URL + '/cart.php')
    setCartUrl({cart_url: BC_URL + '/cart.php', checkout_url: '/checkout.php'})
  }
  setAwaitingResponse(false)
}

const DEFAULT_REGION = process.env.NEXT_PUBLIC_REGION

export const NavLinkCart = ({ fillColor, isMenuActive = false, showCart }) => {
  const { locale } = useRouter()
  const { data: dataCart } = useCart()
  const [urlHref, setUrl] = useState('')
  const [awaitingResponse, setAwaitingResponse] = useState(false)
  const [className, setClassName] = useState('empty')
  const [quantity, setQuantity] = useState(0)
  const [cartAmount, setCartAmmount] = useState(0)
  const { cartItems } = useContext(NewCartItemsContext)
  const mediaType = useMediaType()
  const { setCartUrl } = useContext(CartUrlContext)

  useEffect(() => {
    getCartUrl(dataCart?.id, setUrl, setAwaitingResponse, setCartUrl)
  }, [dataCart, showCart])

  useEffect(()=> {
    if(dataCart?.line_items?.physical_items) {
      let counter = 0
      for (let index = 0; index < dataCart?.line_items?.physical_items.length; index++) {
        const element = dataCart?.line_items?.physical_items[index].quantity;
        counter += element
      }
      setQuantity(counter)
    }
  },[dataCart, quantity])

  useEffect(()=> {
    if(dataCart?.cart_amount){
      setCartAmmount(dataCart?.cart_amount)
      setClassName('not_empty')
    } else if(dataCart?.cart_amount === undefined) {
      setCartAmmount(0)
      setClassName('empty')
    }
  },[dataCart?.cart_amount, cartAmount, className])

  return (
    <CartLink href={urlHref} title={'Cart icon'}  fillColor={fillColor} className={`cart ${className} ${cartItems?.length > 0 || awaitingResponse ? 'loading' : ''}`}>
      <img src={`/images/svg/i--cart--${fillColor}.svg`} alt='Icon cart' width='21' height='18'/>
      { mediaType === 'mobile' ?
      <ItemsInCartCircle visible={dataCart} fav={false} isMenuActive={isMenuActive}>
       <span className='total mobile'>
          {quantity > 0 && quantity }
        </span>
      </ItemsInCartCircle>
        :
        cartAmount !== 0 &&
        <span className='total desktop'>
        <>
          {currencyFormatter({ price: cartAmount, localeRegion: `${locale}-${DEFAULT_REGION}`, round: false, trailingZeros: true })}
        </>
      </span>
      }
    </CartLink>
  )
}
