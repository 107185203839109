"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usei18n = exports.Provideri18n = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var hooks_1 = require("../hooks");
var init = {
    layout: {},
    page: {},
    setPageLocales: function () { }
};
var i18nContext = (0, react_1.createContext)(init);
var Provideri18n = function (_a) {
    var _b = _a.value, layout = _b.layout, page = _b.page, children = _a.children;
    var _c = __read((0, hooks_1.useSmartState)({ layout: layout, page: page }), 2), value = _c[0], setLocales = _c[1];
    (0, react_1.useEffect)(function () {
        if (layout && page) {
            setLocales({ layout: layout, page: page });
        }
    }, [layout, page]);
    var setPageLocales = function (newLocales) {
        var newState = __assign(__assign({}, value), { page: newLocales });
        setLocales(newState);
    };
    return (0, jsx_runtime_1.jsx)(i18nContext.Provider, __assign({ value: __assign(__assign({}, value), { setPageLocales: setPageLocales }) }, { children: children }));
};
exports.Provideri18n = Provideri18n;
var usei18n = function () {
    var _a = (0, react_1.useContext)(i18nContext), layout = _a.layout, page = _a.page, setPageLocales = _a.setPageLocales;
    return { layout: layout, page: page, setPageLocales: setPageLocales };
};
exports.usei18n = usei18n;
