"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAnimationFrame = void 0;
// src: https://codepen.io/HunorMarton/pen/zYONexq
var react_1 = require("react");
var useAnimationFrame = function (callback, centinella, time) {
    var requestRef = (0, react_1.useRef)(null);
    var previousTimeRef = (0, react_1.useRef)(null);
    var timeRef = (0, react_1.useRef)(time);
    var initTimeRef = (0, react_1.useRef)(Date.now());
    var animate = (0, react_1.useCallback)(function (time) {
        if (previousTimeRef.current !== undefined) {
            var deltaTime = time - previousTimeRef.current;
            if (Date.now() - initTimeRef.current >= timeRef.current) {
                initTimeRef.current = Date.now();
                callback(deltaTime);
            }
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    }, [callback]);
    (0, react_1.useEffect)(function () {
        if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
            initTimeRef.current = Date.now();
            requestRef.current = requestAnimationFrame(animate);
        }
    }, [centinella]);
    (0, react_1.useEffect)(function () {
        requestRef.current = requestAnimationFrame(animate);
        return function () { return cancelAnimationFrame(requestRef.current); };
    }, []);
};
exports.useAnimationFrame = useAnimationFrame;
