const instagram = {
  'GB': 'https://www.instagram.com/hellomamma_uk/',
  'ES': 'https://www.instagram.com/hellomamma_es/',
  'FR': 'https://www.instagram.com/hellomamma_fr/',
}

const NEXT_PUBLIC_HOSTNAME_CLIENT = process.env.NEXT_PUBLIC_HOSTNAME_CLIENT

const email = {
  'GB': 'hello@hellomamma.co.uk',
  'ES': 'hola@hellomamma.eu',
  'FR': 'hola@hellomamma.eu',
}

export const home = (data, region) => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "",
    "name": "Hellomamma",
    "url": `https://${NEXT_PUBLIC_HOSTNAME_CLIENT}`,
    "isPartOf": {
      "@type": "WebSite"
    },
    "publisher": {
      "@type": "Store",
      ...(data.seo && { "name": `${data.seo.title}` }),
      ...(data.seo && { "description": `${data.seo.description}` }),
      "brand": "Hellomamma",
      "email": email[region],
      "url": ``,
      "sameAs": [
        instagram[region],
      ],
      "image": `https://${NEXT_PUBLIC_HOSTNAME_CLIENT}/images/products/contents/sunpower.jpg`,  // required CHECK
      "priceRange": "1 - 60",
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "email": email[region],
          "contactType": "customer service",
          "availableLanguage": ["Italian", "Spanish", "English", "French"]
        }
      ],
      "logo": "https://s3-eu-west-1.amazonaws.com/production.storage.hellomamma.com/assets/logo-hellomamma-2x.png",
      "openingHours": "Mo-Fr 09:00-18:30",
      "currenciesAccepted": "EUR",
      "paymentAccepted": "Credit Card, Visa, Paypal"
    }
  })
}
