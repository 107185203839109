import { memo, useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { mq, getRelative, getMargin60, getMargin10, getP26_3, getP18_2 } from '@/styles'

type TypeFaqQuestion = {
  question: string,
  answer: string,
  hash: string
}

const QuestionTitle = styled.dt`
  ${getMargin60(true)}
  margin-bottom: ${getRelative(10, 'mobile')};
  position: relative;

  ${mq.greaterThan('tablet')`
    margin-bottom: ${getRelative(10, 'desktop')};
  `}

  &:first-child {
    margin-top: 0;
  }

  h3 {
    color: ${({ theme }) => theme.colors.greenlight};
    ${getP26_3()}
    font-family: ${({ theme }) => theme.fonts.basier};
    ${getMargin10()}
    text-align: left;
  }

  .hash {
    height: 1px;
    opacity: 0;
    position: absolute;
    top: -110px;
    user-select: none;
    width: 100%;

    ${mq.greaterThan('tablet')`
      top: -140px;
    `}
  }
`

const Answer = styled.dd<any>`
  ${getP18_2()}
  color: ${({ theme }) => theme.colors.greendark};
  margin-bottom: ${getRelative(48, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin-bottom: ${getRelative(60, 'desktop')};
  `}

  p, ul, dl, ol {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    ${getMargin10()}
    padding-right: ${getRelative(70, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-right: 0;
    `}
  }

  a {
    color: ${({ theme }) => theme.colors.orange};
    text-decoration: underline;
  }
`

interface IQuestion {
  data: TypeFaqQuestion,
  setActiveQuestion: any
}

export const Question = memo(({ data, setActiveQuestion}:IQuestion) => {
  const [ref, inView] = useInView({
    rootMargin: '100px 0px -50% 0px',
    threshold: 1,
    triggerOnce: false
  })

  useEffect(() => {
    if (inView) {
      setActiveQuestion(data.hash)
    }
  }, [inView])

  return (
    <>
      <QuestionTitle ref={ref}>
        <a href=''></a>
        <div id={`${data.hash}`} className='hash'></div>
        <h3>{data.question}</h3>
      </QuestionTitle>
      <Answer dangerouslySetInnerHTML={{ __html: data.answer}} />
    </>
  )
})
