import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import { mq, createGrid, getP67, getRelative, getTransition } from '@/styles'

const TRANSLATIONS = {
  'en': {
    'introduction_1': 'We are committed to an',
    'products': ' Italian gastronomy ',
    'introduction_2': 'far from clichés and preconceived ideas. Our intention is that we go well beyond pasta, pesto and limoncello. Andiamo?'
  },
  'es': {
    'introduction_1': 'Apostamos por una',
    'products': ' gastronomía italiana ',
    'introduction_2': 'alejada de tópicos e ideas preconcebidas. Nuestra intención es que vayamos mucho más allá de la pasta, el pesto y el limoncello. Andiamo?'
  },
  'fr': {
    'introduction_1': 'Nous nous engageons à proposer une',
    'products': ' gastronomie italienne ',
    'introduction_2': 'loin des clichés et des idées reçues. Ici nous souhaitons aller bien au-delà des pâtes, du pesto et du limoncello. Andiamo?'
  },
  'it': {
    'introduction_1': 'Scommettiamo su una',
    'products': ' gastronomia italiana ',
    'introduction_2': 'fuori di preconcetti. La nostra intenzione è quella di andare ben oltre la pasta, il pesto e il limoncello. Andiamo?'
  }
}

const Section = styled.section`
  ${createGrid()}
  align-items: center;
  padding: 0 ${getRelative(20, 'mobile')} ${getRelative(80, 'mobile')};

  ${mq.greaterThan('tablet')`
    // height: ${getRelative(715, 'desktop')};
    padding: ${getRelative(50, 'desktop')} 0;
  `}
`

const Text = styled(motion.h1)`
  ${getP67()}
  grid-area: 1 / 1 / span 1 / span 6;
  text-align: center;

  ${mq.greaterThan('tablet')`
    grid-area: 1 / 3 / span 1 / span 8;
    text-align: left;
  `}

  a {
    transition: color .3s ${({ theme }) => theme.ease}, text-shadow .3s ${({ theme }) => theme.ease};
    color: ${({ theme }) => theme.colors.orange};

    ${mq.greaterThan('tablet')`
      -webkit-text-stroke-width: 1.2px;
      -webkit-text-stroke-color: rgb(232 168 54);
    `}

    &:hover {
      color: ${({ theme }) => theme.colors.background};
    }
  }
`

let timeout = null
let ntimes = 5
const tryThething = (callback) => {
  timeout && clearTimeout(timeout)
  //@ts-ignore
  if (!window.NO_URL_KLK && ntimes > 0) {
    ntimes--
    timeout = setTimeout(() => tryThething(callback), 1000)
    //@ts-ignore
  } else if (window.NO_URL_KLK) {
    //@ts-ignore
    callback(window.NO_URL_KLK)
  }
}

const transition = { ...getTransition(1) }
const variants = {
  inactive: {
    y: '30px',
    opacity: 0,
    transition
  },
  active: {
    y:'0',
    opacity: 1,
    transition
  }
}

export const IntroductionSection = (() => {
  const { locale } = useRouter() || { locale: 'es' }
  const [url, setUrl] = useState('/shop/despensa')

  const [ref, inView] = useInView({
    rootMargin: '0px', // TOP  RIGHT
    threshold: .8,
    triggerOnce: true
  })

  useEffect(() => {
    //@ts-ignore
    tryThething(setUrl)
  }, [])

  return (
    <Section ref={ref}>
      <Text variants={variants} animate={inView ? 'active' : 'inactive'} initial='inactive'>
        {TRANSLATIONS[locale].introduction_1}<Link href={url}><a>{TRANSLATIONS[locale].products}</a></Link>{TRANSLATIONS[locale].introduction_2}
      </Text>
    </Section>
  )
})
