import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { mq, getRelative,  getMargin60 } from '@/styles'
import { Content } from './Content'

const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  grid-column: 1 / span 6;
  ${getMargin60()}

  ${mq.greaterThan('tablet')`
    grid-column: 3 / span 8;
  `}

  > article {
    &.media {
      width: 100%;
    }

    &.text {
      ${mq.greaterThan('tablet')`
        padding: 0 ${({ theme: { col }}) => getRelative(col.desktop, 'desktop')};
      `}
    }
  }

  .spacer {
    height: ${getRelative(40, 'mobile')};

    ${mq.greaterThan('tablet')`
      height: ${getRelative(50, 'desktop')};
    `}
  }
`

interface IContent {
  type: 'text' | 'empty' | 'media' | 'spacer',
  col: { from: number, expand: number },
  row: { from: number, expand: number },
  text?: string,
  media?: any,
  color?: string,
  align?: { v: string, h: string },
  [key: string]: any
}

interface ISectionContents {
  section: {
    rows: number,
    columns: IContent[]
  }
}

const getContentsByColumns = (columns) => columns.map((content, idx) => {
  return (
    <Content key={idx+'column'} content={content} />
  )
})

export const SectionContents = memo(({ section }:ISectionContents) => {
  const { columns } = section
  const ContentsByColumns = useMemo(() => getContentsByColumns(columns), [columns])

  return (
    <Section>
      {ContentsByColumns}
    </Section>
  )
})
