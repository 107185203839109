"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaxHeightContainer = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var framer_motion_1 = require("framer-motion");
var clamp_1 = require("./clamp");
var variants = {
    active: {
        height: 'auto',
        transition: {
            type: 'tween',
            ease: [.25, .1, .25, 1],
            duration: .3,
        }
    },
    init: {
        height: '0px',
        transition: {
            type: 'tween',
            ease: [.25, .1, .25, 1],
            duration: .3,
        }
    }
};
exports.MaxHeightContainer = (0, react_1.memo)(function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, data = _a.data, elipsis = _a.elipsis, _c = _a.force_link, force_link = _c === void 0 ? false : _c;
    var elementRef = (0, react_1.useRef)(null);
    var buttonRef = (0, react_1.useRef)(null);
    var _d = __read((0, react_1.useState)(false), 2), isRendered = _d[0], setRender = _d[1];
    var _e = __read((0, react_1.useState)(data), 2), currentData = _e[0], setData = _e[1];
    var _f = __read((0, react_1.useState)(false), 2), isExpanded = _f[0], setExpanded = _f[1];
    // const mediaType = useMediaType()
    var handleHover = function (isHover) { return function () {
        if (buttonRef.current && !force_link) {
            isHover ? buttonRef.current.classList.add('active') : buttonRef.current.classList.remove('active');
        }
    }; };
    (0, react_1.useEffect)(function () {
        if (!elementRef.current)
            return;
        var isClamped = (0, clamp_1.clamp)(elementRef.current, elementRef.current.offsetHeight, { ellipsis: elipsis });
        buttonRef.current = elementRef.current.getElementsByClassName('dy-max-button')[0];
        if (force_link && buttonRef.current) {
            buttonRef.current.addEventListener('click', handleExpanded);
        }
        setExpanded(!isClamped);
    }, [currentData]);
    (0, react_1.useEffect)(function () {
        buttonRef.current = elementRef.current.getElementsByClassName('dy-max-button')[0];
    }, []);
    var handleExpanded = function () {
        if (!buttonRef.current)
            return;
        buttonRef.current.remove();
        setExpanded(true);
    };
    var handleClick = (0, react_1.useRef)(handleExpanded);
    (0, react_1.useEffect)(function () {
        if (!isRendered)
            return;
        setData(data);
    }, [data]);
    (0, react_1.useEffect)(function () {
        setRender(true);
    }, []);
    return isExpanded ? (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { animate: 'active', variants: variants, onClick: null, className: "dy-max expanded ".concat(className), dangerouslySetInnerHTML: { __html: currentData } }) :
        (0, jsx_runtime_1.jsx)("div", { onClick: !force_link ? handleClick.current : null, onMouseEnter: handleHover(true), onMouseLeave: handleHover(false), ref: elementRef, className: "dy-max ".concat(className), dangerouslySetInnerHTML: { __html: currentData } });
});
