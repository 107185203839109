import {memo, useEffect, useMemo, useCallback } from 'react'
import { useRouter } from 'next/router'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, getTransition, getP53, getRelative } from '@/styles'
import { Question } from './Question'

type Seo = {
  title: string,
  description: string
}

type Slug = {
  [locale: string]: string
}

type TypeFaqQuestion = {
  question: string,
  answer: string,
  hash: string
}

export type TypeFaqCategory = {
  name: string,
  seo: Seo,
  slug: Slug,
  faqs: TypeFaqQuestion[]
}

const Section = styled(motion.section)`
  grid-column: 1 / span 6;
  position: relative;

  ${mq.greaterThan('tablet')`
    grid-column: 5 / span 6;
    margin-bottom: ${getRelative(120, 'desktop')};
  `}

  &:last-child {
    margin-bottom: ${getRelative(70, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${getRelative(120, 'desktop')};
    `}
  }

  .idslug {
    height: 1px;
    opacity: 0;
    position: absolute;
    top: -80px;
    user-select: none;
    width: 100%;

    ${mq.greaterThan('tablet')`
      top: -140px;
    `}
  }

  h2 {
    height: ${getRelative(35, 'mobile')};
    margin-bottom: ${getRelative(30, 'mobile')};
    justify-content: flex-start;

    ${mq.greaterThan('tablet')`
      height: auto;
      margin-bottom: ${getRelative(30, 'desktop')};
    `}

    &,
    button {
      align-items: center;
      color: ${({ theme }) => theme.colors.greenlight};
      display: flex;
      ${getP53()}
      width: 100%;
    }

    button {
      justify-content: space-between;
      pointer-events: auto;
      user-select: auto;

      ${mq.greaterThan('tablet')`
        pointer-events: none;
      `}
    }
  }
`

const List = styled<any>(motion.dl)`
  height: 100%;

  &[aria-expanded='false'] {
    pointer-events: none;
    user-select: none;
  }

  &[aria-expanded='true'] {
    pointer-events: auto;
    user-select: auto;
  }
`

const Chevron = styled(motion.span)`
  display: inline-block;
  margin-left: 10px;
  user-select: none;

  img {
    height: ${getRelative(13, 'mobile')};
    width: ${getRelative(13, 'mobile')};
  }

  ${mq.greaterThan('tablet')`
    display: none;
  `}
`

const variants = {
  initial: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: .3,
      delay: 0,
    }
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  }
}

const transition = {
  ease: [.25, .1, .25, 1],
  duration: .3,
  type: 'tween'
}


const getFaqs = (faqs, setActiveQuestion) => faqs.map((faq, idx) => <Question key={idx} data={faq} setActiveQuestion={setActiveQuestion} />)

type TypeQuestionList = {
  data: TypeFaqCategory,
  idx: number,
  setActiveIndex: any,
  setActiveCategory: any,
  setActiveQuestion: any,
}

let isExpanded = true
export const QuestionsList = memo(({ data, idx, setActiveIndex, setActiveCategory, setActiveQuestion }:TypeQuestionList) => {
  const { locale, query } = useRouter() || { locale: '', query: {}, push: () => {} }

  const Faqs = useMemo(() => getFaqs(data.faqs, setActiveQuestion), [data])
  // const [isExpanded, setIsExpanded] = useState(true)

  const [ref, inView] = useInView({
    rootMargin: '100px 0px -50% 0px',
    threshold: 1,
    triggerOnce: false
  })

  useEffect(() => {
    if (inView) {
      setActiveIndex(idx)
      setActiveCategory(data.slug[locale])
    }
  }, [inView])

  const sectionRef = useCallback(node => {
    if (typeof window !== 'object') return

    // @ts-ignore
    if (node !== null && query?.slug && query.slug[0] === data.slug[locale]) {
      setTimeout(() => { node.scrollIntoView({ behavior: 'smooth' }) }, 1200)
    }
  }, []);

  return (
    <Section initial={{ opacity: 0, y: -15 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 0 }} transition={{ duration: 1.2 }}>
      <div ref={sectionRef} className='idslug'></div>
      <h2 ref={ref}>
        {data.name}
        <Chevron aria-hidden={true} animate={{ rotate: isExpanded ? 90 : 0}} transition={transition}>
          <img src='/images/svg/i--chevron--greenlight.svg' alt='Icon chevron' width='6' height='8'/>
        </Chevron>
      </h2>
      <List {...(idx && { id: idx })} aria-expanded={isExpanded} tabIndex={-1} variants={variants} initial='initial' exit='exit' animate={isExpanded ? 'animate' : 'exit'}>
        {Faqs}
      </List>
    </Section>
  )
})
