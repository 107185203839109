import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { mq, createGrid, getRelative, getP93_1 } from '@/styles'
import { Button } from '@dy/commons/components'
import { usei18n } from '@dy/commons/i18n'
import { ValuesComponent } from './'


const Section = styled.section`
  ${createGrid()}
  row-gap: ${getRelative(50, 'mobile')};
  margin-bottom: ${getRelative(100, 'mobile')};
  overflow: hidden;

  ${mq.greaterThan('tablet')`
    row-gap: ${getRelative(146, 'desktop')};
    margin-bottom: ${getRelative(160, 'desktop')};
  `}
`

const TextWrapper = styled.div`
  grid-area: 2 / 2 / span 1 / span 4;

  ${mq.greaterThan('tablet')`
    grid-area: 1 / 4 / span 2 / span 6;
    margin: 0 ${getRelative(59, 'desktop')};
    align-self: center;
  `}

  h2 {
    ${getP93_1()}
    margin-bottom: ${getRelative(25, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${getRelative(30, 'desktop')};
    `}
  }
`

const URLS = {
  'es': '/page/manifiesto',
  'en': '/page/manifesto',
  'it': '/page/manifiesto',
  'fr': '/page/manifesto'
}

const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_LOCALE

export const ValuesSection = memo(() => {
  const { page: { valores } } = usei18n()
  const { locale } = useRouter() || { locale: DEFAULT_LOCALE }
  const [currentLocale, setCurrentLocale] = useState(locale)

  useEffect(() => {
    setCurrentLocale(locale)
  }, [locale])

  return (
    <Section>
      <ValuesComponent className='top-left' image='/images/valores-amore-umore.png' circle='/images/Amore-text.svg' />
      <ValuesComponent className='bottom-left' image='/images/valores-comer-es-cultura.png' circle={`/images/Comer-text_${currentLocale}.svg`} />
      <ValuesComponent className='top-right' image='/images/valores-redescubre-la-gastronomia-italiana.png' circle={`/images/Redescubre-text_${currentLocale}.svg`} />
      <ValuesComponent className='bottom-right' image='/images/valores-tutti-a-tavola.png' circle='/images/Tutti-text.svg' />
      <TextWrapper>
        <h2>{valores.title}</h2>
        <Button>
          <Link href={URLS[currentLocale] || '/'}>
            <a>
              {valores.more}
            </a>
          </Link>
        </Button>
      </TextWrapper>
    </Section>
  )
})
