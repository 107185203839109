import { memo } from 'react'
import styled from 'styled-components'
import { mq, getRelative } from '@/styles'
import { PackSlider } from '@dy/commons/components'

interface IPacksHeader {
  data: any
}

const Header = styled.header`
  margin-bottom: ${getRelative(10, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin-bottom: ${getRelative(124, 'desktop')};
    margin-top: 120px;
  `}

  > div {
    padding-top: calc(40px + ${getRelative(20, 'mobile')});

    ${mq.greaterThan('tablet')`
      padding-top: ${getRelative(100, 'desktop')};
    `}
  }
`

export const PacksHeader = memo<IPacksHeader>(({ data }) => {
  return (
    <Header>
      <PackSlider data={data} inverted packPage={true} />
    </Header>
  )
})
