import { LayoutInterface, LayoutFetchSuccessAction, LayoutFetchPendingAction, LayoutFetchErrorAction, FETCH_LAYOUT_SUCCESS, FETCH_LAYOUT_PENDING, FETCH_LAYOUT_ERROR } from './layout.types'

export interface LayoutState {
  pending: boolean,
  data: LayoutInterface,
  error: TypeError
}

export const InitialState: LayoutState = {
  pending: false,
  data: null,
  error: null
}

const layoutFetchPending = (state: LayoutState, _: LayoutFetchPendingAction) => {
  return {
    ...state,
    pending: true,
    error: null
  }
}

const layoutFetchSuccess = (state: LayoutState, action: LayoutFetchSuccessAction) => {
  return {
    ...state,
    pending: false,
    error: null,
    data: action.payload
  }
}

const layoutFetchError = (state: LayoutState, action: LayoutFetchErrorAction) => {
  return {
    ...state,
    pending: false,
    error: action.payload
  }
}

export const reducers = {
  [FETCH_LAYOUT_PENDING]: layoutFetchPending,
  [FETCH_LAYOUT_SUCCESS]: layoutFetchSuccess,
  [FETCH_LAYOUT_ERROR]: layoutFetchError
}
