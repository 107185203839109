"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validate = void 0;
var nifValidator = function (id) {
    var DNI_REGEX = /^(\d{8})([A-Z])$/;
    var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
    var validate = function (id) {
        // Ensure upcase and remove whitespace
        id = id.toUpperCase().replace(/\s/, '');
        var type = getIdType(id);
        var valid = type === 'dni' ? validDNI(id) : validNIE(id);
        // return { type, valid }
        return valid;
    };
    var getIdType = function (id) {
        if (id.match(DNI_REGEX))
            return 'dni';
        if (id.match(NIE_REGEX))
            return 'nie';
    };
    var validDNI = function (dni) {
        var dni_letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
        var letter = dni_letters.charAt(parseInt(dni, 10) % 23);
        return letter == dni.charAt(8);
    };
    var validNIE = function (nie) {
        // Change the initial letter for the corresponding number and validate as DNI
        var prefixDigit = { 'X': 0, 'Y': 1, 'Z': 2 };
        var prefix = nie.charAt(0);
        prefix = prefixDigit[prefix];
        return validDNI(prefix + nie.substr(1));
    };
    return validate(id);
};
var ibanValidator = function (rawIban) {
    var iban = trim(rawIban.toUpperCase().replace(/\s/g, ''));
    if (iban.length !== 24 || iban.substring(0, 2) !== 'ES')
        return false;
    var regex = new RegExp('\\d{22}');
    if (regex.test(iban.substring(2, 24))) {
        var char1 = iban.substring(0, 1);
        var char2 = iban.substring(1, 2);
        var char1Code = getCharCode(char1);
        var char2Code = getCharCode(char2);
        var isbanaux = String(char1Code) + String(char2Code) + iban.substring(2);
        isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);
        var remainer = module97(isbanaux);
        return remainer === 1;
    }
    function trim(str) {
        return str.replace(/^\s+/g, '').replace(/\s+$/g, '');
    }
    function getCharCode(char) {
        var abc = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return abc.search(char) + 10;
    }
    function module97(banaux) {
        var parts = Math.ceil(banaux.length / 7);
        var remainer = '';
        for (var i = 1; i <= parts; i++) {
            remainer = String(parseFloat(remainer + banaux.substr((i - 1) * 7, 7)) % 97);
        }
        return parseInt(remainer);
    }
};
var cifValidator = function (cif) {
    if (!cif || cif.length !== 9)
        return false;
    var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    var digits = cif.substr(1, cif.length - 2);
    var letter = cif.substr(0, 1);
    var control = cif.substr(cif.length - 1);
    var sum = 0;
    var digit;
    if (!letter.match(/[A-Z]/))
        return false;
    var i;
    for (i = 0; i < digits.length; ++i) {
        digit = parseInt(digits[i]);
        if (isNaN(digit))
            return false;
        if (i % 2 === 0) {
            digit *= 2;
            if (digit > 9)
                digit = parseInt("".concat(digit / 10)) + (digit % 10);
        }
        sum += digit;
    }
    sum %= 10;
    digit = sum !== 0 ? 10 - sum : sum;
    if (letter.match(/[ABEH]/))
        return String(digit) === control;
    if (letter.match(/[NPQRSW]/))
        return letters[digit] === control;
    return String(digit) === control || letters[digit] === control;
};
var cifAndNifValidator = function (id) {
    return cifValidator(id) || nifValidator(id);
};
exports.validate = {
    cif: cifValidator,
    cifandnif: cifAndNifValidator,
    iban: ibanValidator,
    nif: nifValidator
};
