import { memo } from 'react'
import Script from 'next/script'

type TypeGTM = {
  readonly id: number | string,
  readonly noscript?: boolean,
}

export const GoogleTagManager = memo(({ id, noscript = false }: TypeGTM) => {
  return (
    <>
      {!noscript ?
        <Script strategy='afterInteractive'>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${id}');
          `}
        </Script>
        :
        <noscript>
          <iframe src={`https://www.googletagmanager.com/ns.html?id=${id}`} height='0' width='0' style={{ display: 'none', visibility: 'hidden' }}></iframe>
        </noscript>
      }
    </>
  )
})
