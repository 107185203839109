import { memo } from 'react'
import styled from 'styled-components'
import { Header, Main, Body, Aside } from './ProductAddedLayout'
import { useMount } from '@/hooks'


const _Header = styled(Header)<any>`
  height: ${({ navbarHeight }) => navbarHeight == 'mobile' ? 70 : navbarHeight === 'expanded' ? 120 : 80}px;
  transition: 250ms height ease-in-out;
`
interface IModalProductAdded {
  products: any | any[]
  isActive: boolean,
  onClickCross: any,
  navbarHeight: string
}

export const modalProductAddedId = 'modal-product-added'
export const ModalProductAdded = memo<IModalProductAdded>(({ isActive, navbarHeight }) => {
  const isMounted = useMount()

  return (isMounted ?
    <Aside isActive={isActive}>
    <Body isActive={isActive}>
      <_Header navbarHeight={navbarHeight}/>
      <Main>
        Product added
      </Main>
    </Body></Aside> : <></>
  )
})
