import { applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createWrapper } from 'next-redux-wrapper'
import reducers, { INITIAL_STATE } from './_index'

const bindMiddlewares = (middlewares = []) => {
  if(process.env.SITE_ENVIRONMENT !== 'production') {

    const logger = createLogger({ timestamp: true })

    if(process.env.LOGGER) middlewares.push(logger as any)
    return composeWithDevTools(applyMiddleware(...middlewares))
  }

  return applyMiddleware(...middlewares)
}

export const makeStore = () => {
  const store = createStore(reducers, INITIAL_STATE, bindMiddlewares([thunkMiddleware]))

  // TODO:- give proper type to module
  if ((module as any).hot) {
    (module as any).hot.accept('./_index', () => {
      console.log('Replacing reducer')
      store.replaceReducer(require('./_index').default)
    })
  }

  return store;
}

export const wrapper = createWrapper(makeStore, {debug: false})
