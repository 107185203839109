"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageSlider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var styled_components_1 = __importStar(require("styled-components"));
var framer_motion_1 = require("framer-motion");
var __1 = require("../");
var styles_1 = require("../../styles");
var hooks_1 = require("../../hooks");
var utils_1 = require("../../utils");
var SliderStyled = (0, styled_components_1.default)(framer_motion_1.motion.div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  min-height: ", ";\n  position: relative;\n\n  ", "\n\n  figure {\n    height: ", ";\n    margin: ", " auto;\n    max-width: 100%;\n    width: ", ";\n\n    ", "\n\n    ", "\n  }\n\n  img {\n    object-fit: ", ";\n    height: 100%;\n  }\n\n  .control-btn {\n    display: block;\n    position: absolute;\n    top: 50%;\n    left: ", ";\n\n    &.next {\n      right: ", ";\n      left: auto;\n    }\n\n    ", "\n  }\n"], ["\n  min-height: ", ";\n  position: relative;\n\n  ", "\n\n  figure {\n    height: ", ";\n    margin: ", " auto;\n    max-width: 100%;\n    width: ", ";\n\n    ", "\n\n    ", "\n  }\n\n  img {\n    object-fit: ", ";\n    height: 100%;\n  }\n\n  .control-btn {\n    display: block;\n    position: absolute;\n    top: 50%;\n    left: ", ";\n\n    &.next {\n      right: ", ";\n      left: auto;\n    }\n\n    ", "\n  }\n"])), (0, styles_1.getRelative)(325, 'mobile'), styles_1.mq.greaterThan('tablet')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    min-height: ", ";\n\n    &:hover .control-btn {\n      display: block;\n    }\n  "], ["\n    min-height: ", ";\n\n    &:hover .control-btn {\n      display: block;\n    }\n  "])), (0, styles_1.getRelative)(430, 'desktop')), function (_a) {
    var isPack = _a.isPack;
    return isPack ? "".concat((0, styles_1.getRelative)(245, 'mobile')) : "".concat((0, styles_1.getRelative)(324, 'mobile'));
}, (0, styles_1.getRelative)(15, 'mobile'), (0, styles_1.getRelative)(374, 'mobile'), function (_a) {
    var isPack = _a.isPack;
    if (isPack) {
        return (0, styled_components_1.css)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["width: 100%;"], ["width: 100%;"])));
    }
}, styles_1.mq.greaterThan('tablet')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      width: ", ";\n      height: ", ";\n      margin: ", " auto;\n    "], ["\n      width: ", ";\n      height: ", ";\n      margin: ", " auto;\n    "])), (0, styles_1.getRelative)(520, 'desktop'), function (_a) {
    var isPack = _a.isPack;
    return isPack ? "".concat((0, styles_1.getRelative)(407, 'desktop')) : "".concat((0, styles_1.getRelative)(428, 'desktop'));
}, (0, styles_1.getRelative)(10, 'desktop')), function (_a) {
    var isPack = _a.isPack;
    return isPack ? 'cover' : 'contain';
}, (0, styles_1.getRelative)(20, 'mobile'), (0, styles_1.getRelative)(20, 'mobile'), styles_1.mq.greaterThan('tablet')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      display: none;\n      left: ", ";\n      top: calc(50% - ", ");\n\n      &.next {\n        right: ", ";\n      }\n    "], ["\n      display: none;\n      left: ", ";\n      top: calc(50% - ", ");\n\n      &.next {\n        right: ", ";\n      }\n    "])), (0, styles_1.getRelative)(20, 'desktop'), (0, styles_1.getRelative)(10, 'desktop'), (0, styles_1.getRelative)(20, 'desktop')));
var TimelineContainer = styled_components_1.default.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  .span-container {\n    background-color: ", ";\n    position: relative;\n    display: block;\n    height: ", ";\n    border-radius: 3px;\n\n    span {\n      background-color: ", ";\n      position: absolute;\n      display: block;\n      height: ", ";\n      border-radius: 3px;\n    }\n  }\n\n  ", "\n"], ["\n  .span-container {\n    background-color: ", ";\n    position: relative;\n    display: block;\n    height: ", ";\n    border-radius: 3px;\n\n    span {\n      background-color: ", ";\n      position: absolute;\n      display: block;\n      height: ", ";\n      border-radius: 3px;\n    }\n  }\n\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return (0, styles_1.hex2Rgba)(theme.colors.greenlight, 0.3);
}, (0, styles_1.getRelative)(2, 'mobile'), function (_a) {
    var theme = _a.theme;
    return theme.colors.greenlight;
}, (0, styles_1.getRelative)(2, 'mobile'), styles_1.mq.greaterThan('tablet')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    margin-top: ", ";\n    margin-bottom: ", ";\n\n    .span-container {\n      height: ", ";\n\n      span { height: ", "; }\n    }\n\n  "], ["\n    margin-top: ", ";\n    margin-bottom: ", ";\n\n    .span-container {\n      height: ", ";\n\n      span { height: ", "; }\n    }\n\n  "])), (0, styles_1.getRelative)(10, 'desktop'), (0, styles_1.getRelative)(10, 'desktop'), (0, styles_1.getRelative)(3, 'desktop'), (0, styles_1.getRelative)(3, 'desktop')));
var Timeline = (0, react_1.memo)(function (_a) {
    var currentIdx = _a.currentIdx, nSlides = _a.nSlides;
    var controls = (0, framer_motion_1.useAnimation)();
    var _b = __read((0, react_1.useMemo)(function () {
        var variants = {};
        var percentage = 100 / nSlides;
        for (var idx = 0; idx < nSlides; idx++) {
            variants[idx] = {
                width: (idx + 1) * percentage + '%',
                transition: {
                    ease: 'linear',
                    duration: hooks_1.SLIDE_TIME / 1000,
                },
            };
            variants[idx + 'prev'] = {
                width: (idx) * percentage + '%',
                transition: {
                    ease: 'linear',
                    duration: 0.1,
                },
            };
        }
        return [variants];
    }, [nSlides]), 1), spanVariants = _b[0];
    var changeSpan = (0, react_1.useCallback)(function (idx) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, controls.start(idx + 'prev')];
                case 1:
                    _a.sent();
                    controls.start(idx + '');
                    return [2 /*return*/];
            }
        });
    }); }, []);
    (0, react_1.useEffect)(function () {
        changeSpan(currentIdx);
        return function () {
            controls.stop();
        };
    }, [currentIdx]);
    return ((0, jsx_runtime_1.jsx)(TimelineContainer, __assign({ className: 'second' }, { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: 'span-container' }, { children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.span, { variants: spanVariants, animate: controls }) })) })));
});
var imageVariants = {
    initial: {
        opacity: 0,
        transition: __assign(__assign({}, (0, styles_1.getTransition)()), { delay: 0 })
    },
    animate: {
        opacity: 1,
        transition: __assign(__assign({}, (0, styles_1.getTransition)()), { duration: 1, delay: .3 })
    },
    exit: {
        opacity: 0,
        transition: __assign(__assign({}, (0, styles_1.getTransition)()), { duration: .2, delay: 0 })
    }
};
var slideSizes = "(min-width: 768px) ".concat((0, styles_1.getRelative)(520, 'desktop'), ", ").concat((0, styles_1.getRelative)(374, 'mobile'));
var getSlide = function (image, idx) {
    var _a;
    if (!image[idx])
        return null;
    return ((0, jsx_runtime_1.jsx)(__1.Figure, { media: (0, utils_1.parserMedia)((_a = image[idx]) === null || _a === void 0 ? void 0 : _a.pdp), lazy: idx > 5, sizes: slideSizes, quality: 65 }));
};
exports.ImageSlider = (0, react_1.memo)(function (_a) {
    var data = _a.data, _b = _a.isPack, isPack = _b === void 0 ? false : _b;
    // if(!data?.[0]?.pdp) return <SliderStyled></SliderStyled>
    var _c = (0, hooks_1.useSlider)(data.length), currentSlide = _c.currentSlide, triggerRef = _c.triggerRef, nextSlide = _c.nextSlide, prevSlide = _c.prevSlide, setSlide = _c.setSlide, startAnimation = _c.startAnimation;
    var Image = (0, react_1.useMemo)(function () { return getSlide(data, currentSlide); }, [data, currentSlide]);
    var nSlides = (0, react_1.useMemo)(function () { return data.length; }, [data.length]);
    (0, react_1.useEffect)(function () {
        startAnimation();
        setSlide(0);
    }, [data]);
    return ((0, jsx_runtime_1.jsxs)(SliderStyled, __assign({ layout: true, ref: triggerRef, isPack: isPack }, { children: [(0, jsx_runtime_1.jsx)(framer_motion_1.AnimatePresence, __assign({ exitBeforeEnter: true }, { children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, __assign({ variants: imageVariants, initial: 'initial', exit: 'exit', animate: 'animate' }, { children: Image }), currentSlide) })), data.length > 1 &&
                (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("button", __assign({ onClick: function () { return prevSlide(); }, className: 'control-btn prev' }, { children: (0, jsx_runtime_1.jsx)("img", { src: '/images/svg/i--prevslide.svg', width: '40', height: '40' }) })), (0, jsx_runtime_1.jsx)("button", __assign({ onClick: function () { return nextSlide(); }, className: 'control-btn next' }, { children: (0, jsx_runtime_1.jsx)("img", { src: '/images/svg/i--nextslide.svg', width: '40', height: '40' }) })), (0, jsx_runtime_1.jsx)(Timeline, { nSlides: nSlides, currentIdx: currentSlide })] })] })));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
