import { ArticleInterface, ArticleFetchSuccessAction, ArticleFetchPendingAction, ArticleFetchErrorAction, FETCH_ARTICLE_SUCCESS, FETCH_ARTICLE_PENDING, FETCH_ARTICLE_ERROR } from './article.types'

interface ArticleState {
  pending: boolean,
  data: ArticleInterface,
  error: TypeError
} 

export const InitialState: ArticleState = {
  pending: false,
  data: null,
  error: null
}

const articleFetchPending = (state: ArticleState, _: ArticleFetchPendingAction) => {
  return {
    ...state,
    pending: true,
    error: null
  }
}

const articleFetchSuccess = (state: ArticleState, action: ArticleFetchSuccessAction) => {
  return {
    ...state,
    pending: false,
    error: null,
    data: action.payload
  }
}

const articleFetchError = (state: ArticleState, action: ArticleFetchErrorAction) => {
  return {
    ...state,
    pending: false,
    error: action.payload
  }
}

export const reducers = {
  [FETCH_ARTICLE_PENDING]: articleFetchPending,
  [FETCH_ARTICLE_SUCCESS]: articleFetchSuccess,
  [FETCH_ARTICLE_ERROR]: articleFetchError
}