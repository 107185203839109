import { FC, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { AccordionItem } from './AccordionItem'
import { useAccordion, AccordionContext } from './useAccordion'

const AccordionWrapper = styled.dl`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const getAccordionItems = (items, activeIndex, activeCategory, activeQuestion) => {
  const parsedItems = []
  for(let item of items) {
    if(Array.isArray(item.content)) {
      const parsedCategory = {
        header: item.header,
        content: <Accordion items={item.content} activeIndex={activeIndex} activeCategory={activeCategory} activeQuestion={activeQuestion} />
      }

      parsedItems.push(parsedCategory)
    } else {
      parsedItems.push(item)
    }
  }

  return parsedItems.map((parsedItem, idx) => {
    return(<AccordionItem key={idx} data={parsedItem} index={idx} activeCategory={activeCategory} activeQuestion={activeQuestion} />)
  })
}

interface IAccordion {
  items: any[]
  activeIndex: number | null,
  activeCategory: any,
  activeQuestion: any,
}

export const Accordion:FC<IAccordion> = ({ items = [], activeIndex, activeCategory, activeQuestion }):JSX.Element => {
  const [activeItem, setActiveItem, collapseAll] = useAccordion()
  const AccordionItems = useMemo(() => getAccordionItems(items, activeIndex, activeCategory, activeQuestion ), [items, activeCategory, activeQuestion])

  const context = useMemo(() => ({ activeItem, setActiveItem, collapseAll }), [activeItem])

  useEffect(() => {
    setActiveItem({
      index: activeIndex,
      slug: activeCategory,
      hash: activeQuestion,
    })
  }, [activeIndex, activeCategory, activeQuestion])

  return (
    <AccordionContext.Provider value={context}>
      <AccordionWrapper>
        {AccordionItems}
      </AccordionWrapper>
    </AccordionContext.Provider>
  )
}
