import { memo } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { Header, Main, Body } from './Layout'
import { useModal, useMount } from '@/hooks'
import { mq, getRelative, hideScrollbar, hex2Rgba, getP15_3 } from '@/styles'
import { Button } from '@dy/commons/components'

const TRANSLATIONS = {
  'en': {
    'filters': 'Filters',
    'see': 'See',
    'delete_all': 'Delete all'
  },
  'es': {
    'filters': 'Filtros',
    'see': 'Ver',
    'delete_all': 'Borrar todo'
  },
  'fr': {
    'filters': 'Filtres',
    'see': 'Voir',
    'delete_all': 'Effacer tout' // Tout supprimer?
  },
  'it': {
    'filters': 'Filtri',
    'see': 'Vedere',
    'delete_all': 'Cancella tutto'
  },
}

const Section = styled.section`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  max-height: inherit;
`

const _Body = styled(Body)`
  background-color: ${({ theme }) => theme.colors.background_green};
  height: 100%;
  margin-bottom: 0;
  width: 100vw;

  ${mq.greaterThan('tablet')`
    width: ${getRelative(414, 'desktop')};
  `}

  > header {
    ${mq.greaterThan('tablet')`
      height: ${getRelative(60, 'desktop')};
      padding-right: 0;
    `}

    h2 {
      font-family: ${({ theme }) => theme.fonts.platform};
      font-weight: bold;
    }

    .close {
      align-items: center;
      display: flex;
      justify-content: center;

      ${mq.greaterThan('tablet')`
        padding: ${getRelative(20, 'desktop')};
      `}
    }
  }

  > main {
    max-height: none;
  }
`

const Ul = styled(motion.ul)`
  height: auto;
  overflow: auto;
  padding: ${getRelative(40, 'mobile')} 0 ${getRelative(140, 'mobile')};
  width: 100%;
  ${hideScrollbar()}

  ${mq.greaterThan('tablet')`
    padding: ${getRelative(40, 'desktop')} 0 ${getRelative(140, 'desktop')};
  `}

  li {
    margin-bottom: ${getRelative(40, 'mobile')};
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    ${mq.greaterThan('tablet')`
      margin-bottom: ${getRelative(40, 'desktop')};

      &:last-child {
        margin-bottom: 0;
      }
    `}
  }
`

const Actions = styled.div<any>`
  background: ${({ theme }) => theme.colors.background_green};
  background: linear-gradient(0deg, ${({ theme }) => hex2Rgba(theme.colors.background_green, .85)} 60%, ${({ theme }) => hex2Rgba(theme.colors.background_green, 0)} 100%);
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: ${getRelative(80, 'mobile')} ${getRelative(20, 'mobile')} 0;
  pointer-events: none;
  position: absolute;
  width: 100%;

  ${mq.greaterThan('tablet')`
    padding: ${getRelative(80, 'desktop')} ${getRelative(20, 'desktop')} 0;
  `}

  button {
    ${getP15_3()}
    font-family: ${({ theme }) => theme.fonts.basier};
    font-weight: bold;
    height: ${getRelative(60, 'mobile')};
    margin-bottom: ${getRelative(15, 'mobile')};
    pointer-events: ${({ isActive }) => isActive ? 'auto' : 'none'};
    white-space: nowrap;
    width: 100%;

    ${mq.greaterThan('tablet')`
      height: ${getRelative(60, 'desktop')};
      margin-bottom: ${getRelative(15, 'desktop')};
    `}

    &:first-child {
      margin-right: ${getRelative(20, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-right: ${getRelative(20, 'desktop')};
      `}
    }

    &:last-child {
      background-color: ${({ theme }) => theme.colors.background_green};
    }
  }
`

interface IModalFilters {
  filters: JSX.Element[],
  clearFilters: () => void,
  activeFiltersLength: number | null,
  resultsLength: number | null
}

export const modalFiltersId = 'modal-filters'
export const ModalFilters = memo(({ filters, clearFilters, activeFiltersLength, resultsLength }:IModalFilters) => {
  const { render, close, isActive } = useModal(modalFiltersId)
  const isMounted = useMount()
  const { locale } = useRouter() || { locale: '' }

  return isMounted ? render(
    <AnimatePresence exitBeforeEnter>
      <_Body key={`modal-filters-active-${isActive}`} isActive={isActive} lateral={true} className='lateral'>
        <Header text={TRANSLATIONS[locale].filters} onClick={close} />
        <Main>
          <Section>
            <Ul initial='inactive' >
              {filters}
            </Ul>
          </Section>
          <Actions isActive={isActive}>
            <Button onClick={close} bgType={'greenlight'}>
              {TRANSLATIONS[locale].see}
              {activeFiltersLength > 0 && ` (${resultsLength})`}
            </Button>
            <Button onClick={clearFilters} bgType={'greenlight'} outline={true}>{TRANSLATIONS[locale].delete_all}</Button>
          </Actions>
        </Main>
      </_Body>
    </AnimatePresence>
  ) : <></>
})
