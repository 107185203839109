import { useState } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import useCustomer from '@custom-bigcommerce/storefront-data-hooks/use-customer'

import { Header, Main, Body } from './Layout'
import { callBigcommerce } from '@/api'
// import { usei18n } from '@dy/commons/i18n'
import { cookie } from '@dy/commons/utils'
import { useModal, useMount } from '@dy/commons/hooks'
import { mq, getRelative, getP20_1, getP15_5 } from '@/styles'
import { InputSelect, Button } from '@dy/commons/components'

const PRO = process.env.SITE_ENVIRONMENT !== 'development' && process.env.SITE_ENVIRONMENT !== 'local' && process.env.SITE_ENVIRONMENT !== 'staging'

const _Header = styled(Header)`
  h2 {
    ${getP20_1()}
  }
`

const _Main = styled(Main)`
  overflow: auto;

  form {
    padding: ${getRelative(30, 'mobile')} ${getRelative(40, 'mobile')} ${getRelative(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding: ${getRelative(40, 'desktop')} ${getRelative(50, 'desktop')} ${getRelative(30, 'desktop')};
    `}

    button {
      ${getP15_5()}
      margin-top: ${getRelative(100, 'mobile')};
      width: 100%;

      ${mq.greaterThan('tablet')`
        margin-top: ${getRelative(100, 'desktop')};
      `}
    }

    p {
      ${getP20_1()}
      margin-bottom: ${getRelative(30, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-bottom: ${getRelative(30, 'desktop')};
      `}
    }
  }
`

// const languageOptions = {
//   'ES': [
//     { name: 'Español', value: 'es'},
//     { name: 'Italiano', value: 'it'},
//   ],
//   'GB': [
//     { name: 'English', value: 'en'},
//     { name: 'Italian', value: 'it'},
//   ],
//   'FR': [
//     { name: 'French', value: 'fr'},
//     { name: 'Italian', value: 'it'},
//   ],
// }

const RegionOptions = [
  { name: 'España (Península)', value: 'ES'},
  { name: 'Islas Canarias', value: 'IC'},
  { name: 'France', value: 'FR'},
  { name: 'United Kingdom', value: 'GB'}
]

const RegionDefaultLocales = {
  'ES': 'es',
  'IC': 'es',
  'FR': 'fr',
  'GB': 'en'
}

const RegionOptions_init = {
  'ES': 'España (Península)',
  'IC': 'Islas Canarias',
  'FR': 'France',
  'GB': 'United Kingdom',
}

const ROUTES = {
  'ES': {
    'es': `https://hellomamma.es?zone=es`,
    'it': `https://hellomamma.es/it?zone=es`,
  },
  'IC': {
    'es': `https://canarias.hellomamma.es?zone=ic`
  },
  'FR': {
    'fr': `https://hellomamma.fr?zone=fr`,
    'it': `https://fr.hellomamma.fr/it?zone=fr`,
  },
  'GB': {
    'en': `https://hellomamma.co.uk?zone=en`,
    'it': `https://hellomamma.co.uk/it?zone=en`,
  },
}

const STAGING_ROUTES = {
  'ES': {
    'es': `https://staging.hellomamma.es?zone=es`,
    'it': `https://staging.hellomamma.es/it?zone=es`,
  },
  'IC': {
    'es': `https://staging.canarias.hellomamma.es?zone=ic`
  },
  'FR': {
    'fr': `https://staging.hellomamma.fr?zone=fr`,
    'it': `https://staging.hellomamma.fr/it?zone=fr`,
  },
  'GB': {
    'en': `https://staging.hellomamma.co.uk?zone=en`,
    'it': `https://staging.hellomamma.co.uk/it?zone=en`,
  },
}

// const HEADER_TEXT = {
//   'es': 'País e idioma',
//   'en': 'Country and language',
//   'fr': 'Pays et langue',
//   'it': 'Paese e lingua',
// }

const NEXT_REGION = process.env.NEXT_PUBLIC_REGION

export const modalLanguagesId = 'modal-languages'
export const ModalLanguages = ({ customModals, openCustom }) => {
  // payload
  const { render, close, isActive } = useModal(modalLanguagesId)

  const { data: userData }  = useCustomer()
  const [isThinking, setThink] = useState(false)

  const { locale } = useRouter() || { locale: '' }
  const [selectedLocale, setLocale] = useState(locale)
  const [selectedRegion, setRegion] = useState(NEXT_REGION)

  const isMounted = useMount()
  const [currentLevel] = useState('initial')
  // const { layout } = usei18n()

  const handleRegionChange = (value) => {
    setRegion(value)
    setLocale(null)
  }

  const handleClose = () => {
    cookie.set('COUNTRY_SELECTED', NEXT_REGION)
    close()

    if(customModals) {
      setTimeout(() => {
        openCustom()
      }, 1500)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setThink(true)
    cookie.setObj({ name: 'NEXT_LOCALE', value: selectedLocale })
    cookie.setObj({ name: 'NEXT_REGION', value: selectedRegion })

    cookie.set('COUNTRY_SELECTED', selectedRegion)
    if(NEXT_REGION !== selectedRegion) {
      cookie.set('COUNTRY_REDIRECT', selectedRegion)
    }

    if (selectedRegion === NEXT_REGION && userData?.entityId?.toString()) {
      await callBigcommerce({ type: 'change-channels', params: { customer_id: userData?.entityId?.toString() }})
    }

    if(NEXT_REGION !== selectedRegion) {
      window.location.href = PRO ? ROUTES[selectedRegion][selectedLocale ? selectedLocale : RegionDefaultLocales[selectedRegion]] : STAGING_ROUTES[selectedRegion][selectedLocale ? selectedLocale : RegionDefaultLocales[selectedRegion]]
    } else {
      close()
    }
    setThink(false)
  }

  return isMounted ? render(
    <AnimatePresence exitBeforeEnter>
      <Body isActive={isActive} framerKey={currentLevel + isActive}>
        {/* <_Header text={payload?.isNew ? 'Benvenuto Hellomamma' : HEADER_TEXT[locale] } onClick={handleClose} /> */}
        <_Header text={'Benvenuto a HelloMamma!'} onClick={handleClose} />
        <_Main>
          <form className='form-language' onSubmit={onSubmit}>
            <p>Select country</p>
            <InputSelect onChange={handleRegionChange} initialFanacyValue={RegionOptions_init[NEXT_REGION]} initialValue={NEXT_REGION} name='region' options={RegionOptions} />
            <Button disabled={isThinking} type='submit' className='klklk' bgType='greenlight'>Go!</Button>
          </form>
        </_Main>
      </Body>
    </AnimatePresence>
  ) : <></>
}
