
import { memo, useEffect, useState, useCallback } from 'react'
import { useRouter } from 'next/router'
import {motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { mq, getRelative, getP20, hideScrollbar, getP20_1, getP15, getTransition } from '@/styles'
import { Input, INPUT_PATTERNS, Button } from '@dy/commons/components'
import { useModal, useMount, useToast } from '@/hooks'
import { usei18n } from '@dy/commons/i18n'
import useCustomer from '@custom-bigcommerce/storefront-data-hooks/use-customer'
import { Header, Main, Body } from './Layout'
const REGION = process.env.NEXT_PUBLIC_REGION
const isStaging = () => window.location.href.includes('staging') ||  window.location.href.includes('localhost')


const TRANSLATIONS = {
  'en': {
    'success': "You’ll get an email when this item comes back in stock!",
    'error':'You already have a stock notification for this product.'
  },
  'es': {
    'success': "¡Vas a recibir un email cuando haya stock!",
    'error':'Ya tienes una notificación de stock para este producto.'
  },
  'fr': {
    'success': "Vous recevrez un email dès qu'il y aura du stock!",
    'error':"Vous disposez déjà d'une notification de stock pour ce produit."

  },
  'it': {
    'success': "Riceverai un'e-mail non appena sarà disponibile in stock!",
    'error':'Hai già una notifica di disponibilità per questo prodotto.'

  },
}

const textVariants = {
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...getTransition(),
    }
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
  initial: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
}

const BodyStyled = styled(Body)`
  h2 {
    ${getP20_1()}
  }

  header {
    .prev {
      align-self: center;

      img {
        width: ${getRelative(6, 'mobile')};
        height: ${getRelative(10, 'mobile')};

        ${mq.greaterThan('tablet')`
          width: ${getRelative(10, 'desktop')};
          height: ${getRelative(11, 'desktop')};
        `}
      }

    }
  }

  main {
    ${hideScrollbar()}
    padding: ${getRelative(40, 'mobile')} ${getRelative(40, 'mobile')} ${getRelative(30, 'mobile')};

    h2 {
      margin-bottom: ${getRelative(30, 'mobile')};
      text-align: left;
    }

    form {
      display: flex;
      flex-wrap: wrap;

      .signup-cb {
        label {
          align-items: center;
        }

        input, span {
          ${getP15()}
          font-family: 'Basier Circle';
          font-weight: 500;

        }
      }

      button {
        picture {
          transition: opacity 300ms ${({ theme }) => theme.ease};
        }
      }

      p {
        font-family: 'Basier Circle';
        width: 100%;

        &.p--new {
          ${getP15()}
          opacity: .5;
          margin: ${getRelative(30, 'mobile')} 0 ${getRelative(15, 'mobile')};
          text-align: center;

          ${mq.greaterThan('tablet')`
            margin: ${getRelative(40, 'desktop')} 0 ${getRelative(15, 'desktop')};
          `}

          button {
            border: 0;
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            text-decoration: underline;
            text-transform: uppercase;
          }
        }
      }

        div {
          &.col-6 {
            &.first {
              padding-top: ${getRelative(20, 'mobile')};
            }

            ${mq.greaterThan('tablet')`
              padding-top: ${getRelative(20, 'desktop')};
              display: inline-block;

              &.first,
              &.first + div {
                width: calc(50% - ${getRelative(20, 'desktop')});
              }

              &.first {
                margin-right: auto;
                padding-top: ${getRelative(20, 'desktop')};

              }
            `}
          }
        }

      input, label {
        font-family: 'Basier Circle';
        font-weight: 500;
        color: #324931;

        &:-internal-autofill-selected {
          appearance: none;
          background-color: none !important;
          background-image: none !important;
          color: #324931!important;
        }

        &[type='password'] ~ button {
          picture {
            opacity: .4;
          }
        }

        &[type='text'] ~ button {
          picture {
            opacity: 1;
          }
        }
      }

      button[type='submit'] {
        ${getP15()}
        width: 100%;
        margin-top: ${getRelative(30, 'mobile')};
      }

      input[name='password'] ~ p {
        opacity: 1 !important;
      }

      .hidden {
        display: none;
      }

      .error {
        ${getP20()}
        color: ${({ theme }) => theme.colors.red};
      }

      .forgot-password {
        font-size: ${getRelative(12, 'mobile')};
        margin-top: ${getRelative(-10, 'mobile')};
        color: #324931;
        font-family: 'Basier Circle';
        font-weight: 500;
        display: block;
        width: 100%;
        letter-spacing: -.3;
        display: flex;
        justify-content: flex-end;

        ${mq.greaterThan('tablet')`
          font-size: ${getRelative(12, 'desktop')};
          margin-top: ${getRelative(-10, 'desktop')};
        `}
      }
    }

  }

  ${mq.greaterThan('tablet')`
    width: ${getRelative(550, 'desktop')};
    max-width: unset;

    main {
      padding: ${getRelative(50, 'desktop')} ${getRelative(40, 'desktop')} ${getRelative(30, 'desktop')};

      h2 {
        margin-bottom: ${getRelative(30, 'desktop')};
      }

      form {
        button[type='submit'] {
          margin-top: ${getRelative(70, 'desktop')};
        }
      }
    }
  `}
`

type Inputs = {
  email: string,
}

// async function postEmailNotification(bcId, email, locale) {
//   const staging = isStaging()
//   const url = `https://${staging ? 'staging.' : ''}cms.hellomamma.eu/api/v1/${REGION}/${locale}/stock_notifications`
//   try {
//     // ⛔️ TypeError: Failed to fetch
//     // 👇️ incorrect or incomplete URL
//     const response = await fetch(url, {
//       method: 'POST',
//       body: JSON.stringify({bigcommerce_variant_id: bcId, email: email})
//       // headers: {
//       //   'accept': 'application/json',
//       //   'Content-Type': "application/json",
//       // },
//     });

//     console.log('response', response, url)
//     if (!response.ok) {
//       throw new Error(`Error! status: ${response.status}`);
//     }

//     const result = await response.json();
//     console.log('result', result)
//     return result;
//   } catch (err) {
//     console.log(err, url);
//   }
// }

export const modalNotifyMe = 'modal-notify'
export const ModalNotifyMe = memo(() => {
  const { render, close, isActive, payload } = useModal(modalNotifyMe)
  const { data: userData }  = useCustomer()
  const isMounted = useMount()
  const [userEmail, setUserEmail] = useState('')
  const [formError, setFormError] = useState(null)
  const { register, errors, handleSubmit } = useForm<Inputs>()
  const { locale } = useRouter() || { locale: 'es' }
  const { Container, addToast, toasts } = useToast()
  const { layout } = usei18n()
  useEffect(()=>{
    if(userData?.email) {
      setUserEmail(userData.email)
    }
  }, [userData])

  const onSubmit = useCallback(async (data) => {
    setFormError(null)
    const email = data.email
    const variant_id = payload.variant_id
    const staging = isStaging()
    const url = `https://${staging ? 'staging.' : ''}cms.hellomamma.eu/api/v1/${REGION}/${locale}/stock_notifications`

    const dataForm = { bigcommerce_variant_id: variant_id, email: email}
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(dataForm)
    })
    const parsed = await response.json() 
    const {status} = response
    if (status === 200 && !parsed.error) {
      addToast({ children: TRANSLATIONS[locale].success, type: 'success' })
    } else if(parsed.error) {
      const text = parsed.message.split('Variant ')[1]
      addToast({ children: text, type: 'warning' })
    }
    
    close()
  }, [payload])

  return isMounted ? render(
    <AnimatePresence exitBeforeEnter>
      <BodyStyled framerKey={isActive} isActive={isActive}>
      <>
        <Header text={layout.modales.back_to_stock.title} onClick={close} />
        <Main>
          <h2>{layout.modales.back_to_stock.email}</h2>
          <form onSubmit={handleSubmit(onSubmit, payload)}>
            <Input name='email' initialValue={userEmail} errorMessage={layout.auth.error.email_message} label={'Email'} autoComplete={'off'} className='dy-input' error={Object.keys(errors).length > 0 && errors.email ? true : false} ref={register({
              pattern: INPUT_PATTERNS.email,
              required: true,
            })} />
            <AnimatePresence>
              {formError && <motion.p initial='initial' animate='animate' exit='exit' variants={textVariants} className='error'>{formError}</motion.p>}
            </AnimatePresence>
            <Button type='submit' bgType={'greenlight'}>{layout.modales.back_to_stock.continue}</Button>
          </form>
        </Main>
        <Container toasts={toasts} />
      </>
      </BodyStyled>
    </AnimatePresence>
  ) : <></>
})
