"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDropdown = void 0;
var react_1 = require("react");
var useDropdown = function (_a) {
    var _b = _a === void 0 ? { onChange: null } : _a, onChange = _b.onChange;
    var triggerRef = (0, react_1.useRef)(null);
    var dropdownRef = (0, react_1.useRef)(null);
    var _c = __read((0, react_1.useState)(false), 2), expanded = _c[0], setExpanded = _c[1];
    var onTriggerClick = (0, react_1.useCallback)(function () {
        setExpanded(function (state) { return !state; });
    }, []);
    var onMouseClick = (0, react_1.useCallback)(function (_a) {
        var target = _a.target;
        var triggerClicked = triggerRef.current.contains(target);
        var dropdownClicked = dropdownRef.current.contains(target);
        if (!triggerClicked && !dropdownClicked) { // outside click
            setExpanded(false);
        }
        else if (dropdownClicked) { // dropdown list click
            if (target instanceof HTMLAnchorElement || (target instanceof HTMLButtonElement && target.dataset.dismiss) || target.dataset.value) {
                var value = target.dataset.value;
                var text = target.textContent;
                onChange && onChange(value, text);
                setExpanded(false);
            }
        }
    }, []);
    var onEsc = (0, react_1.useCallback)(function (e) {
        if (e.keyCode === 27 && expanded === true) {
            setExpanded(false);
        }
    }, [expanded]);
    (0, react_1.useEffect)(function () {
        if (expanded) {
            document.addEventListener('mousedown', onMouseClick);
            window.addEventListener('keyup', onEsc);
        }
        else {
            document.removeEventListener('mousedown', onMouseClick);
            window.removeEventListener('keyup', onEsc);
        }
    }, [expanded]);
    return [triggerRef, dropdownRef, expanded, onTriggerClick];
};
exports.useDropdown = useDropdown;
