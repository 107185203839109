import { FC } from 'react';
import Head   from 'next/head'

import { article, faqs, home, product, products, search } from './jsonld';
// NOTE: <title> and <meta> elements need to be contained as direct children of the <Head> element,
// or wrapped into maximum one level of <React.Fragment>, otherwise
// the metatags won't be correctly picked up on clientside navigation.
// NOTE:- Segun parece tampoco acepta `Fragments` que son hermanos. Solo pilla el primero.
const REGION = process.env.NEXT_PUBLIC_REGION
const generateJson = {
  'contents': (data) => article(data),
  'faqs': (data) => faqs(data),
  'home': (data, region) => home(data, region),
  'product': (data, region, locale) => product(data, region, locale),
  'products': (data, region, locale) => products(data, region, locale),
  'search': () => search()
}

interface IHead {
  readonly data?: {
    title: string,
    description: string,
    video?: {
      content_type: string,
      default: string,
      size: {
        height: number,
        width: number
      }
    },
    [key: string]: any
  },
  readonly jsonld?: {
    pageType: string,
    data: any,
    [key: string]: any
  },
  defaultLocale?: string,
  slug?: any,
  pathname?: string
}

const DY_CLIENT_URL= process.env.DY_CLIENT_URL
const NEXT_PUBLIC_HOSTNAME_CLIENT = process.env.NEXT_PUBLIC_HOSTNAME_CLIENT
const NEXT_PUBLIC_LOCALE= process.env.NEXT_PUBLIC_LOCALE
const defaultOgImage = {
  default: `${DY_CLIENT_URL}/images/og_cover.jpg`,
  content_type: 'image/jpg',
  alt: 'HelloMamma',
  size: {
    height: 517,
    width: 750
  }
  // size: {
  //   height: 1080,
  //   width: 1652
  // }
}

export const HeadTag:FC<IHead> = ({ data = {}, jsonld = null, slug = null, pathname = '', defaultLocale = NEXT_PUBLIC_LOCALE }) => {
  const image = defaultOgImage
  const slugDefault = slug ? (typeof slug === 'string') ? slug : slug[defaultLocale] : null
  // const slugItalian = slug ? (typeof slug === 'string') ? slug : slug['it'] : null
  // const image = pageType === 'product' ? data.header.image_data : defaultOgImage

  const Images = [
    <meta property='og:image' key='og:image' content={image.default} />,
    <meta property='og:image:alt' key='og:image:alt' content={image.alt} />,
    <meta property='og:image:type' key='og:image:type' content={image.content_type} />,
    <meta property='og:image:secure_url' key='og:image:secure_url' content={image.default} />,
    <meta property='og:image:height' key='og:image:height' content={`${image.size.height}`} />,
    <meta property='og:image:width' key='og:image:width' content={`${image.size.width}`} />,
  ]

  const OgBase = [
    <meta property='og:description' key='og:description' content={data.description} />,
    <meta property='og:locale' key='og:locale' content={NEXT_PUBLIC_LOCALE} />,
    <meta property='og:site_name' key='og:site_name' content='HelloMamma' />,
    <meta property='og:title' key='og:title' content={data.title} />,
    <meta property='og:type' key='og:type' content='website' />,
    <meta property='og:url' key='og:url' content={DY_CLIENT_URL} />,
  ]

  const OgVideo = data.video ? [
    <meta key='og:video:type' property='og:video:type' content={data.video.content_type} />,
    <meta key='og:video:secure_url' property='og:video:secure_url' content={data.video.default} />,
    <meta key='og:video:height' property='og:video:height' content={`${data.video.size.height}`} />,
    <meta key='og:video:width' property='og:video:width' content={`${data.video.size.width}`} />,
  ] : []

  const CanonicalAndAlternateUrls = slug && defaultLocale ? [
    <link key='canonical' rel='canonical' href={`https://${NEXT_PUBLIC_HOSTNAME_CLIENT}/${pathname}${slugDefault}`} hrefLang={`${defaultLocale === 'en' ? 'gb' : defaultLocale}-${defaultLocale.toUpperCase()}`} />
  ] : []

  return (
    <Head>
      <title>{data.title}</title>
      <meta name='description' content={data.description} />
      {OgBase}
      {Images}
      {OgVideo}
      {jsonld && <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: generateJson[jsonld.pageType](jsonld.data, REGION, defaultLocale) }} />}
      {CanonicalAndAlternateUrls}
    </Head>
  )
}
