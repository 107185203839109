import { memo, ReactNode } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, getRelative, hex2Rgba, getP20, getTransition } from '@/styles'

const _Header = styled.header`
  height: ${getRelative(60, 'mobile')};
  display: grid;
  grid: auto / 1fr 4fr 1fr;
  height: 70px;
  position: relative;
  padding: 0 ${getRelative(20, 'mobile')};
  pointer-events: none;
  border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.black, .1)};
  width: 100%;

  ${mq.greaterThan('tablet')`
    height: 80px;
    padding: 0 ${getRelative(20, 'desktop')};
  `}

  h2 {
    ${getP20()}
    grid-area: 1 / 2 / span 1 / span 1;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .prev {
    grid-area: 1 / 1 / span 1 / span 1;
    justify-self: flex-start;
    padding-right: 50px;
  }

  .close {
    grid-area: 1 / 3 / span 1 / span 1;
    justify-self: flex-end;
    padding-left: 50px;

    figure {
      align-items: center;
      display: flex;
      height: 100%;
      min-width: 10px;
      width: auto;
    }

    img {
      min-width: 10px;
      width: ${getRelative(10, 'mobile')};

      ${mq.greaterThan('tablet')`
        width: ${getRelative(10, 'desktop')};
      `}
    }
  }
`
export const Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0;
  background-color: rgba(0,0,0,.6);
  user-select: none;
  pointer-events: none;
  transition: opacity 300ms ${({ theme }) => theme.ease};

  &.active {
    opacity: 0;
    pointer-events: initial;
    user-select: initial;
  }
`

export const Aside = styled.aside<any>`
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: fixed;
  z-index: 150;

  ${({ isActive  }) => !isActive && `pointer-events: none; user-select: none`}
`

interface IHeader {
  text?: string,
  className?: string,
  onClick: any,
  onClickPrev?: any,
}

export const Header = memo<IHeader>(({ text, onClick, className = '', onClickPrev = null }) => {
  return (
    <_Header className={className}>
      {onClickPrev ? <button className='prev' onClick={onClickPrev}><img src='/images/svg/i--go-back.svg' alt='crbackoss icon'/></button> : <></>}
      {text && <h2>{text}</h2>}
      <button className='close' onClick={onClick}><img src='/images/svg/i--close.svg' alt='cross icon' width='16' height='16'/></button>
    </_Header>)
})

const _Main = styled.main`
  background-color: ${({ theme }) => theme.colors.black};
  flex-grow: 2;
  overflow: auto;
  pointer-events: auto;
  width: 100%;

  h2 {
    ${getP20()}
    margin-bottom: 30px;

    ${mq.lessThan('tablet')`
      width: 400px;
    `}
  }

  ${mq.greaterThan('tablet')`
    h2 {
      br {
        display: none;
      }
    }
  `}
`

interface IMain {
  className?: string,
  children: ReactNode
}

export const Main = memo<IMain>(({ children, className = '' }) => {
  return (
    <_Main className={className}>
      {children}
    </_Main>)
})

const _Body = styled(motion.div)<any>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  margin-bottom: 40px;
  margin-top: 0;
  overflow: auto;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: 10;

  main {
    transition: opacity 300ms 50ms ${({ theme }) => theme.ease};

    opacity: ${({ isActive }) => isActive ? 1 : 0};
  }


  ${mq.greaterThan('tablet')`
    max-width: ${getRelative(600, 'desktop')};
    min-height: 170px;
    width: 100%;
  `}
`

const variants_div = {
  animate: {
    x: '0%',
    transition: {
      ...getTransition(),
    }
  },
  exit: {
    x: '-100%',
    transition: {
      ...getTransition(),
    }
  },
}

interface IBody {
  className?: string,
  children: ReactNode,
  isActive: boolean
}

export const Body = memo<IBody>(({ children, className = '', isActive }) => {
  return (
    <_Body isActive={isActive} initial='exit' animate={isActive ? 'animate' : 'exit'} variants={variants_div} className={className}>
      {children}
    </_Body>)
})
