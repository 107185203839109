export const faqs = (data) => {
  if(!data) return null

  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": data.faqs.map((faq) => ({
      "@type": "Question",
      "name": `${faq.question}`,
      "acceptedAnswer": {
      "@type": "Answer",
        "text": `${faq.answer}`
      }
    }))
  })
}
