import { useMemo } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useSlider } from '@/hooks'
import { getTransition, mq, getRelative } from '@/styles'

const _SlimBanners = styled.aside`
  height: 58px;
  background-color: ${({ theme }) => theme.colors.orange};
  position: relative;
  top: 0;
  width: 100%;
  z-index: 9998;

  ${mq.greaterThan('tablet')`
    height: ${getRelative(40, 'desktop')};
  `}

  div {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;
  }

  /* Don't use flex in the paragraph, it removes the spaces between links when comes from CMS */
  p {
    width: 100%;
  }

  a {
    text-decoration: underline;
  }

  p, a {
    font-size: 16px;
    line-height: 19px;
    font-family: ${({ theme }) => theme.fonts.basier};
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 400;

  }

  ~ div.main {
    > [class*='ProductWrapper'], [class*='PacksHeader'], [class*='HeaderMarquee'], [class*='HomeHeader__Header'] {
      margin-top: 60px;
    }

    .packs-page-grid, [class*='SectionFilters__Section'], [class*='SectionCategories__Section'], [class*='SectionProductDetail__Section'] {
      top: ${getRelative(60, 'mobile')};
    }

    [class*='SectionProductDetail__Section'] {
      height: calc(100vh - 100px);
    }

    ${mq.greaterThan('tablet')`
      > [class*='ProductWrapper'], [class*='PacksHeader'], [class*='HeaderMarquee'], [class*='HomeHeader__Header'] {
        margin-top: ${getRelative(120, 'desktop')};
      }

      [class*='SectionFilters__Section'], [class*='SectionCategories__Section'], [class*='FaqsCategories'] {
        top: 0;
      }

      [class*='SectionProductDetail__Section'] {
        top: ${getRelative(80, 'desktop')};
        height: calc(100vh - ${getRelative(160, 'desktop')});

        &.plp-klk {
          top: ${getRelative(10, 'desktop')};
          height: calc(100vh - ${getRelative(20, 'desktop')});
        }

        &.packs-page-grid,
        &.not-in-grid {
          top: ${getRelative(80, 'desktop')};
          height: calc(100vh - ${getRelative(100, 'desktop')});
        }
      }

    `}
  }
`

const transition = { ...getTransition() }
const variants = {
  inactive: {
    y: '-10px',
    opacity: 0,
    transition
  },
  active: {
    y:'0',
    opacity: 1,
    transition
  },
  exit: {
    y:'10px',
    opacity: 0,
    transition
  }
}

export const SlimBanners = ({ banners }) => {
  const { currentSlide } = useSlider(banners.length, true)
  const Slide = useMemo(() => {
    return banners[currentSlide]?.title
  }, [currentSlide, banners])

  return <_SlimBanners key={'slims'}>
    <AnimatePresence exitBeforeEnter>
      <motion.div key={currentSlide} variants={variants} initial='inactive' exit='exit' animate='active' dangerouslySetInnerHTML={{ __html: Slide }} />
    </AnimatePresence>
  </_SlimBanners>
}
