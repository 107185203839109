const NEXT_PUBLIC_HOSTNAME_CLIENT = process.env.NEXT_PUBLIC_HOSTNAME_CLIENT

export const article = (data) => {
  if(!data) return null
  const image = data.contents.map((content) => {
    if(content?.columns?.[0]?.type === 'media') {
      const image = content.columns[0].media?.default?.['2x']?.regular
      if(image) return `${image}`
    }
  }).filter(Boolean)

  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://${NEXT_PUBLIC_HOSTNAME_CLIENT}/article`
    },
    "headline": `${data.title}`,
    ...(data.subtitle && { "description": `${data.subtitle}` }),
    "image": image ?? `https://${NEXT_PUBLIC_HOSTNAME_CLIENT}/images/og_cover.jpg`,
    "datePublished": "2021-07-05T08:00:00+08:00",
    "dateModified": "2021-07-05T09:20:00+08:00",
    "author": {
      "@type": "Person",
      "name": "HelloMamma Team"
    },
    "publisher": {
      "@type": "Organization",
      "name": "HelloMamma",
      "logo": {
        "@type": "ImageObject",
        "url": "https://s3-eu-west-1.amazonaws.com/production.storage.hellomamma.com/assets/logo-hellomamma-2x.png"
      }
    }
  })
}
