import { memo, ReactNode } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, getRelative,  getTransition, hex2Rgba, getP20 } from '@/styles'

const _Header = styled.header`
  height: ${getRelative(60, 'mobile')};
  display: grid;
  grid: auto / 1fr 4fr 1fr;
  position: relative;
  padding: 0 ${getRelative(20, 'mobile')};
  border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.text, .1)};
  width: 100%;

  ${mq.greaterThan('tablet')`
    height: ${getRelative(50, 'desktop')};
    padding: 0 ${getRelative(20, 'desktop')};
  `}

  h2 {
    ${getP20()}
    font-family: ${({ theme }) => theme.fonts.basier};
    font-weight: 400;
    grid-area: 1 / 2 / span 1 / span 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .prev {
    grid-area: 1 / 1 / span 1 / span 1;
    justify-self: flex-start;
    padding-right: 50px;
  }

  .close {
    grid-area: 1 / 3 / span 1 / span 1;
    justify-self: flex-end;
    padding-left: 50px;

    figure {
      align-items: center;
      display: flex;
      height: 100%;
      min-width: 10px;
      width: auto;
    }

    img {
      min-width: 10px;
      width: ${getRelative(10, 'mobile')};

      ${mq.greaterThan('tablet')`
        width: ${getRelative(10, 'desktop')};
      `}
    }
  }
`

interface IHeader {
  text: string,
  className?: string,
  onClick: any,
  onClickPrev?: any,
  closeIconColor?: string
}

export const Header = memo<IHeader>(({ text, onClick, className = '', onClickPrev = null, closeIconColor = 'black' }) => {
  return (
    <_Header className={className}>
      {onClickPrev ? <button className='prev' onClick={onClickPrev}><img src='/images/svg/i--go-back.svg' alt='crbackoss icon'/></button> : <></>}
      <h2>{text}</h2>
      <button className='close' onClick={onClick}><img src={`/images/svg/i--close${closeIconColor === 'white' ? '--white' : ''}.svg`} alt='cross icon' width='16' height='16'/></button>
    </_Header>)
})

const _Main = styled.main`
  flex-grow: 2;
  height: auto;
  overflow: hidden scroll;
  position: relative;
  width: 100%;
  max-height: calc(90vh - ${getRelative(60, 'mobile')});

  ${mq.greaterThan('tablet')`
    max-height: calc(90vh - ${getRelative(50, 'desktop')});
  `}

  h2 {
    ${getP20()}
    font-family: ${({ theme }) => theme.fonts.basier};
    font-weight: 400;
    margin: 0 auto;
    margin-bottom: 30px;
    width: 100%;

    ${mq.lessThan('tablet')`
      max-width: 400px;
    `}

    ${mq.greaterThan('tablet')`
      br {
        display: none;
      }
    `}
  }

`

interface IMain {
  className?: string,
  children: ReactNode
}

export const Main = memo<IMain>(({ children, className = '' }) => {
  return (
    <_Main className={className}>
      {children}
    </_Main>)
})

const _Body = styled(motion.div)`
  align-items: center;
  pointer-events: auto;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
  min-height: 170px;
  max-height: 95vh;
  max-width: 100vw;
  width: calc(100% - ${getRelative(30, 'mobile')});

  ${mq.greaterThan('tablet')`
    max-width: ${getRelative(500, 'desktop')};
    min-width: 400px;
    width: 100%;
  `}

  &.lateral {
    justify-content: flex-start;
    margin-left: auto;
    margin-top: 0;
    max-height: 100vh;
  }
`

const variants_div = {
  enter: {
    opacity: 0,
    y: '10px',
    transition: { ...getTransition(), delay: .2 }
  },
  animate: {
    opacity: 1,
    y: '0px',
    transition: { ...getTransition(),
    delay: .2 }
  },
  exit: {
    opacity: 0,
    y: '10px',
    transition: { ...getTransition() }
  }
}

const variants_lateral = {
  enter: {
    opacity: 0,
    x: '100%',
    transition: { ...getTransition() }
  },
  animate: {
    opacity: 1,
    x: '0%',
    transition: { ...getTransition() }
  },
  exit: {
    opacity: 0,
    x: '100%',
    transition: { ...getTransition() }
  },
}

interface IBody {
  className?: string,
  children: ReactNode,
  initial?: string,
  exit?: string,
  framerKey?: string,
  animate?: string,
  readonly isActive?: boolean,
  readonly lateral?: boolean
}

export const Body = memo<IBody>(({ children, className = '', framerKey = '', lateral = false, isActive }) => {
  return (
    <_Body key={framerKey} initial='enter' exit='exit' animate={isActive ? 'animate' : 'exit'} variants={lateral ? variants_lateral : variants_div} className={className}>
      {children}
    </_Body>)
})
