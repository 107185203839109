import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { mq, getRelative, getP53, getP12_1 } from '@/styles'
import { usei18n } from '@dy/commons/i18n'
// import { Tabs } from '@/commons'
import { Table } from '../'

type ProductSpecification = {
  name: string,
  value: string | number,
  type: 'weight' | 'energy'
}

type TypeSectionNutrition = {
  data: ProductSpecification[]
}

const TRANSLATIONS = {
  'en': {
    'average_value_per': 'Average value per',
    'energy': 'Energy'
  },
  'es': {
    'average_value_per': 'Valor medio por',
    'energy': 'Energía'
  },
  'fr': {
    'average_value_per': 'Valeur moyenne pour',
    'energy': 'Énergie'
  },
  'it': {
    'average_value_per': 'Valore medio per',
    'energy': 'Energia'
  },
}

const Section = styled.section`
  table td {
    &:nth-child(even) {
     text-align: right;
     padding-right: ${getRelative(25, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-right: ${getRelative(25, 'desktop')};
    `}
    
    }
  }
`

const Title = styled.h2`
  ${getP53()}
  color: ${({ theme }) => theme.colors.greendark};
  margin-bottom: ${getRelative(30, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin-bottom: ${getRelative(20, 'desktop')};
  `}
`

const P = styled.p`
  align-items: center;
  display: flex;
  height: ${getRelative(25, 'mobile')};
  justify-content: center;

  ${mq.greaterThan('tablet')`
    height: ${getRelative(25, 'desktop')};
  `}

  span {
    align-items: center;
    color: ${({ theme }) => theme.colors.greenlight};
    display: flex;
    ${getP12_1()}
    font-family: ${({ theme }) => theme.fonts.basier};
    height: 100%;
    justify-content: center;
    padding: 0 ${getRelative(25, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding: 0 ${getRelative(25, 'desktop')};
    `}

    &:nth-child(2) {
      border-left: 1px solid ${({ theme }) => theme.colors.greenlight};
    }
  }
`

const localeString = {
  'en': 'en-GB',
  'es': 'es-ES',
  'fr': 'fr-FR',
  'it': 'it-IT',
}


const getNutritional = (details, locale) => details.map((detail, idx) => {
  const value = detail.value < 1 ? '< 1' : detail.value.toLocaleString(localeString[locale])
  return (
    <tr key={idx}>
      <td>{detail.name}</td><td>{value}g</td>
    </tr>
  )
})

export const SectionNutritionChart = memo<TypeSectionNutrition>(({ data }) => {
  // const [currentGraph, setGraph] = useState(0)
  const { locale } = useRouter() || { locale: 'es' }
  const { layout } = usei18n()

  const energy = data.filter((item) => item.type === 'energy')[0]
  const chartData = data.filter((item) => item.type === 'weight')
  const dataWithPercentages = useMemo(() => getNutritional(chartData,locale), [chartData])

  return (
    <Section>
      <Title>{layout.product_page.product_description.nutritional_value.title}</Title>
      <P>
        <span>{TRANSLATIONS[locale].average_value_per} 100gr.</span>
        {energy && <span>{TRANSLATIONS[locale].energy}: {energy.value}Kcal / {Math.round(typeof energy.value === 'string' ? parseInt(energy.value) : Number(energy.value) * 4.184)}kJ</span>}
      </P>
      {/* <Tabs currentActive={currentGraph} data={[{ title: 'Por 100gr.'}]} onClickTab={setGraph} /> */}
      <Table>
        <tbody>
          {dataWithPercentages}
        </tbody>
      </Table>
    </Section>
  )
})
