import { SELECT_PRODUCT, ProductInterface, Color, SelectProductAction } from './type'

interface SelectedProductState {
  product: ProductInterface,
  selectedColor: Color,
} 

export const InitialState: SelectedProductState = {
  product: {
    bigcommerce_id: null,
    is_new_release: false,
    name: '',
    sizes: [],
    colors: [],
    variants: []
  },
  selectedColor: null
}


const selectProductReducer = (state: SelectedProductState, action: SelectProductAction) => {
  return {
    ...state,
    product: action.payload.product,
    selectedColor: action.payload.selectedColor,
  }
}

export const reducers = {
  [SELECT_PRODUCT]: selectProductReducer
}

