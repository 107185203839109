import { memo } from 'react'
import styled from 'styled-components'
import { mq, getRelative, hex2Rgba, getMargin60, getMargin20, getP53, getP30, getP26_1, getP20, getP18_2, getP18_1, getP15, getP12_1 } from '@/styles'

const TextWrapper = styled.div<any>`
  font-family: ${({ theme }) => theme.fonts.basier};
  color: ${({ color, theme }) => color ? color : theme.colors.greendark};
  text-align: ${({ align }) => align && align.h ? align.h : 'left'};
  word-break: break-word;
  width: 100%;

  ${mq.lessThan('tablet')`
    padding: 0 ${getRelative(8, 'mobile')};
  `}

  h2, h3, h4,
  p, ul, ol, dl,
  q, a {
    color: inherit;
    text-align: inherit;
    word-break: inherit;
  }

  p, ul, ol, dl, q, a {
    color: ${({ theme }) => theme.colors.greendark};
  }

  h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.colors.greenlight};
  }

  h2, h3, h4 {
    ${getMargin20()}
    font-weight: 500;

    + p {
      margin-top: -${getRelative(10, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-top: -${getRelative(10, 'desktop')};
      `}
    }
  }

  h1, h2 {
    ${getP30()}

    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${getRelative(15, 'mobile')};

        ${mq.greaterThan('tablet')`
          margin-top: ${getRelative(20, 'desktop')};
        `}
      }
    }
  }

  h3 {
    ${getP26_1()}

    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${getRelative(15, 'mobile')};

        ${mq.greaterThan('tablet')`
          margin-top: ${getRelative(15, 'desktop')};
        `}
      }
    }
  }

  h4 {
    ${getP18_1()}
  }

  h5 {
    ${getP15()}
  }

  h4, h5 {
    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${getRelative(10, 'mobile')};

        ${mq.greaterThan('tablet')`
          margin-top: ${getRelative(10, 'desktop')};
        `}
      }
    }
  }

  h6 {
    ${getP12_1()}

    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${getRelative(8, 'mobile')};

        ${mq.greaterThan('tablet')`
          margin-top: ${getRelative(8, 'desktop')};
        `}
      }
    }
  }

  p {
    ${getP18_2()}

    br {
      content: '';
      display: block;
      margin-bottom:  ${getRelative(10, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-bottom: ${getRelative(15, 'desktop')};
      `}
    }
  }

  ul, ol, dl {
    ${getP20()}
    ${getMargin60()}
    font-weight: normal;

    &:last-child {
      margin-bottom: 0;
    }

    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${getRelative(10, 'mobile')};

        ${mq.greaterThan('tablet')`
          margin-top: ${getRelative(15, 'desktop')};
        `}
      }
    }
  }




  br {
    + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
      ${getP20()}
    }
  }

  ul, ol {
    padding-left: 18px;
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  q, blockquote {
    border-left: 0;
    color: ${({ theme }) => theme.colors.greenlight};
    ${getP53()}
    display: block;
    font-weight: bold;
    font-style: normal;
    margin: auto;
    padding: ${getRelative(12, 'mobile')} ${getRelative(20, 'mobile')} 0;
    position: relative;
    text-align: center;
    width: 100%;

    ${mq.greaterThan('tablet')`
      padding: ${getRelative(20, 'desktop')} 0 ${getRelative(20, 'desktop')};
      transform: translateX(-${({ theme: { col }}) => getRelative(col.desktop, 'desktop')});
      width: calc(100% + ${({ theme: { col }}) => getRelative(col.desktop * 2, 'desktop')});
    `}

    &:before,
    &:after {
      content: '';
      background-image: url('/images/svg/i--double-quotes.svg');
      background-repeat: no-repeat;
      display: inline-block;
      height: ${getRelative(33, 'mobile')};
      opacity: .2;
      position: absolute;
      user-select: none;
      width: ${getRelative(47, 'mobile')};

      ${mq.greaterThan('tablet')`
        height: ${getRelative(66, 'desktop')};
        width: ${getRelative(94, 'desktop')};
      `}
    }

    &:before {
      left: ${getRelative(20, 'mobile')};
      top: -${getRelative(10, 'mobile')};
      transform: rotate(180deg);

      ${mq.greaterThan('tablet')`
        left: ${getRelative(30, 'desktop')};
        top: -${getRelative(10, 'desktop')};
      `}
    }

    &:after {
      right: ${getRelative(20, 'mobile')};
      bottom: -${getRelative(10, 'mobile')};

      ${mq.greaterThan('tablet')`
        bottom: 0;
        right: ${getRelative(30, 'desktop')};
      `}
    }

    > p {
      color: ${({ theme }) => theme.colors.greenlight};
      display: inline;
      ${getP53()}
      font-family: ${({ theme }) => theme.fonts.platform};
      font-style: normal;
      text-align: center;
    }
  }

  #cookiefirst-cookies-table {
    table {
      min-width: unset !important;

      tbody {
        th,
        td {
          &:nth-child(2) {
            min-width: unset;
          }
        }

        th {
          &:first-child {
            width: ${getRelative(70, 'mobile')};

            ${mq.greaterThan('tablet')`
              width: ${getRelative(80, 'desktop')};
            `}
          }

          &[scope='col'] {
            padding-bottom: ${getRelative(5, 'mobile')};

            ${mq.greaterThan('tablet')`
              padding-bottom: ${getRelative(5, 'desktop')};
            `}
          }
        }

        td {
          &[colspan='5'] {
            padding-bottom: 5px;

            h4 {
              margin-bottom: ${getRelative(5, 'mobile')};

              ${mq.greaterThan('tablet')`
                margin-bottom: ${getRelative(5, 'desktop')};
              `}
            }
          }
        }

        td {
          + th[scope='col'] {
            border-bottom: 0px;
          }
        }
      }
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;

    th {
      ${getP18_2()}
    }

    td {
      ${getP15()}
      font-weight: 400;
    }

    th,
    td {
      padding: ${getRelative(20, 'mobile')} ${getRelative(5, 'mobile')};

      ${mq.greaterThan('tablet')`
        padding: ${getRelative(30.5, 'desktop')} ${getRelative(10, 'desktop')};
      `}

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    th {
      color: ${({ theme }) => theme.colors.text};
      border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.text, .6)};
    }

    tbody {
      th {
        &[scope='row'] {
          border-right: 1px solid ${({ theme }) => hex2Rgba(theme.colors.text, .6)};
          white-space: normal;
        }
      }
    }

    tbody, tfoot {
      td {
        border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.text, .1)};
        color: ${({ color, theme }) => color ? color : theme.colors.greendark};

        ${mq.greaterThan('tablet')`
          max-width: ${getRelative(300, 'desktop')};
          vertical-align: initial;
        `}
      }

      tr {
        &:last-child {
          td {
            border-color: ${({ theme }) => hex2Rgba(theme.colors.text, 0)};
          }
        }
      }
    }
  }
`

type ContentTypeText = {
  col: { from: number, expand: number},
  row: { from: number, expand: number},
  align?: {
    v: 'top' | 'center' | 'bottom',
    h: 'left' | 'center' | 'right'
  },
  color?: string,
  text: string,
  type: 'text'
}

interface IContentText {
  data: ContentTypeText
}

export const ContentText = memo(({ data }:IContentText) => {
  const { text, color, align } = data

  return (
    <TextWrapper className={'text-wrapper'} color={color} align={align} dangerouslySetInnerHTML={{ __html: text }} />
  )
})
