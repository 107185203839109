import { useContext, useRef, useState, useEffect, useCallback } from 'react'
import { AccordionContext } from './useAccordion'

export const useAccordionItem = (index, slug, locale) => {
  const triggerRef   = useRef(null)
  const accordionRef = useRef(null)
  const [expanded, setExpanded] = useState(false)
  const {activeItem, setActiveItem } = useContext(AccordionContext)

  const onToggle = useCallback(() => {
    setActiveItem({ index: index === activeItem?.index ? null : index, slug: index === activeItem?.index ? null : slug[locale], hash: null })
    setExpanded(!expanded)
  }, [activeItem, expanded])

  useEffect(() => {
    setExpanded(index === activeItem?.index)
  }, [activeItem])

  const onKeyDown = e => {
    switch (e.key) {
      case ' ':
      case 'Enter':
        e.preventDefault()
        onToggle()
        break
      case 'ArrowDown':
        e.preventDefault()
        if(triggerRef.current && triggerRef.current.nextElementSibling && triggerRef.current.nextElementSibling.nextElementSibling ) {
          triggerRef.current.nextElementSibling.nextElementSibling.focus()
        }
        break
      case 'ArrowUp':
        e.preventDefault()
        if(triggerRef.current && triggerRef.current.previousElementSibling && triggerRef.current.previousElementSibling.previousElementSibling ) {
          triggerRef.current.previousElementSibling.previousElementSibling.focus()
        }
        break
      case 'Home':
      case 'End':
        e.preventDefault()
        break
      default:
        break
    }
  }

  return [triggerRef, accordionRef, expanded, onToggle, onKeyDown, activeItem] as const
}
