import { memo } from 'react'
import Script from 'next/script'

type TypeCookie = {
  readonly region: string
}
const PRO = process.env.SITE_ENVIRONMENT !== 'development' && process.env.SITE_ENVIRONMENT !== 'local' && process.env.SITE_ENVIRONMENT !== 'staging' && process.env.SITE_ENVIRONMENT !== 'preview'

const id = {
  'GB': {
    staging: 'staging.hellomamma.co.uk-060eb9d3-1866-4717-8c0a-be0201c7baa8',
    master: 'hellomamma.co.uk-060eb9d3-1866-4717-8c0a-be0201c7baa8'
  },
  'FR': {
    staging: 'staging.hellomamma.fr-f2d679db-60b7-43a1-85c8-40499a3ddbee',
    master: 'hellomamma.fr-f2d679db-60b7-43a1-85c8-40499a3ddbee'
  },
  'ES': {
    staging: 'staging.hellomamma.es-e92a7999-b0e8-4b71-ba54-5bdc560af85f',
    master: 'hellomamma.es-e92a7999-b0e8-4b71-ba54-5bdc560af85f'
  }
}

export const CookieFirst = memo(({ region }: TypeCookie) => {
  return (
    <Script strategy='afterInteractive' src={`https://consent.cookiefirst.com/sites/${id[region][PRO ? 'master' : 'staging']}/consent.js`}/>
  )
})
