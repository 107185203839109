
import { memo, useState, useMemo, useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'

import useSignup from '@custom-bigcommerce/storefront-data-hooks/use-signup'
import useLogin from '@custom-bigcommerce/storefront-data-hooks/use-login'
// import useCart from '@custom-bigcommerce/storefront-data-hooks/cart/use-cart'
import { mq, getRelative, getP20, getTransition, hideScrollbar, getP20_1, getP15 } from '@/styles'
import { Input, INPUT_PATTERNS, Button, InputCheckbox } from '@dy/commons/components'
import { useModal, useMount, useToast } from '@/hooks'
import { callBigcommerce } from '@/api'
import { usei18n } from '@dy/commons/i18n'

import { Header, Main, Body } from './Layout'

const Figure = styled.figure`
  position: absolute;
  right: ${getRelative(17, 'mobile')};
  top: 35%;
  height: ${getRelative(15, 'mobile')};
  width: ${getRelative(23, 'mobile')};

  img {
    width: 100%;
    height: 100%;
  }

  ${mq.greaterThan('tablet')`
    right: ${getRelative(17, 'desktop')};
    height: ${getRelative(15, 'desktop')};
    width: ${getRelative(23, 'desktop')};
  `}
`

const TRANSLATIONS = {
  'en': {
    'access': 'Access',
    'continue': 'Continue',
    'email': 'Please, write your email',
    'forgot_password': 'Have you forgotten your password?',
    'name': 'Name',
    'lastname': 'Lastname',
    'password': 'Password',
    'signup': "Looks like it's your first time with us, welcome to Hello Mamma!",
    'subscribe': 'Subscribe to newsletter',
    'welcome': "Looks like it's your first time with us, welcome to Hello Mamma!",
    'welcome_back': "Welcome back to Hello Mamma. Please confirm your password.",
    'something_went_wrong': "Something went wrong. Please try again.",
    'something_went_wrong_create': "Ooops! It seems you are not registered yet. Create an account to log in",
    'something_went_wrong_already_account': "It seems you're already registered. Log in to access your account.",
    'successfully_registered': "You've successfully registered!",
    'successfully_login': "You've successfully logged in!"
  },
  'es': {
    'access': 'Acceder',
    'continue': 'Continuar',
    'email': 'Por favor, escribe tu email',
    'forgot_password': '¿Has olvidado tu contraseña?',
    'name': 'Nombre',
    'lastname': 'Apellidos',
    'password': 'Contraseña',
    'signup': 'Parece que es tu primera vez con nosotros, bienvenido a Hello Mamma!',
    'subscribe': 'Subscribirme al newsletter',
    'welcome': 'Parece que es tu primera vez con nosotros, bienvenido a Hello Mamma!',
    'welcome_back': 'Bienvenido de nuevo a Hello Mamma. Por favor, confirma tu contraseña.',
    'something_went_wrong': "Algo ha ido mal. Por favor, inténtalo de nuevo.",
    'something_went_wrong_create': "Ooops! Parece que aún no estás registrado. Crea una cuenta para iniciar sessión.",
    'something_went_wrong_already_account': "Parece que ya estás registrado. Inicia sesión para acceder a tu cuenta.",
    'successfully_registered': "¡Te has registrado correctamente!",
    'successfully_login': "¡Has iniciado sesión correctamente!"

  },
  'fr': {
    'access': 'Accéder',
    'continue': 'Continuer',
    'email': "S'il vous plaît, écrivez votre email",
    'forgot_password': 'Avez-vous oublié votre mot de passe?',
    'name': 'Nome',
    'lastname': 'Noms de famille',
    'password': 'Mot de passe',
    'signup': 'Parece que es tu primera vez con nosotros, bienvenido a Hello Mamma!',
    'subscribe': "S'inscrire à la Newsletter",
    'welcome': 'Parece que es tu primera vez con nosotros, bienvenido a Hello Mamma!',
    'welcome_ back': 'Bienvenue à nouveau à Hello Mamma. Veuillez confirmer votre mot de passe.',
    'something_went_wrong': "Un problème est survenu. Veuillez réessayer.",
    'something_went_wrong_create': "Oops! Il semble que vous ne vous soyez pas encore inscrit. Créez un compte pour vous connecter.",
    'something_went_wrong_already_account': "Il semble que vous soyez déjà inscrit. Connectez-vous pour accéder à votre compte.",
    'successfully_registered': "Vous êtes inscrit avec succès !",
    'successfully_login': "Vous êtes connecté avec succès !"
  },
  'it': {
    'access': 'Accedere',
    'continue': 'Continua',
    'email': 'Per favore, scrivi la tua email',
    'forgot_password': 'Hai dimenticato la tua password?',
    'name': 'Nome',
    'lastname': 'Cognomi',
    'password': "Password",
    'signup': 'Parece que es tu primera vez con nosotros, bienvenido a Hello Mamma!',
    'subscribe': 'Iscriviti alla Newsletter',
    'welcome': 'Parece que es tu primera vez con nosotros, bienvenido a Hello Mamma!',
    'welcome_back': "Bentornata a Hello Mamma. Per favore conferma la tua password.",
    'something_went_wrong': "Qualcosa è andato storto. Per favore riprova.",
    'something_went_wrong_create': "Ops! Sembra che tu non sia ancora registrato. Crea un account per accedere.",
    'something_went_wrong_already_account': "Sembra che tu sia già registrato. Accedi per accedere al tuo account.",
    'successfully_registered': "Ti sei registrato con successo!",
    'successfully_login': "Hai effettuato l'accesso con successo!"
  },
}

export const PasswordEye = () => {
  return <Figure><img src={'/images/svg/password-eye.svg'} alt='icon password' /></Figure>
}

const textVariants = {
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...getTransition(),
    }
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
  initial: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
    }
  },
}

const isStaging = () => window.location.href.includes('staging')
const REGION = process.env.NEXT_PUBLIC_REGION
const ROUTES = {
  'ES': (staging) => `https://${staging ? 'staging.' : ''}shop.hellomamma.es`,
  'FR': (staging) => `https://${staging ? 'staging.' : ''}shop.hellomamma.fr`,
  'GB': (staging) => `https://${staging ? 'staging.' : ''}shop.hellomamma.co.uk`,
}

const LEVELS = {
  'inactive': () => <></>,
  'login': ({ callback, loading, register, close, errors, getValues, formError, isActive, locales, setLevel }) => {
    const { email } = getValues()
    const _isStaging = isStaging()
    const BC_URL = ROUTES[REGION](_isStaging)

    return (
      <>
        <Header text={locales?.login?.title} onClick={close} />
        <Main>
          <h2>{locales?.login?.description}</h2>
          <form onSubmit={callback}>
            <Input name='email' initialValue={email} errorMessage={locales?.error.email_message} label={locales?.login?.email} autoComplete={'off'} className='dy-input' error={Object.keys(errors).length > 0 && errors.email ? true : false} ref={register({
              pattern: INPUT_PATTERNS.email,
              required: true,
            })} />
            <Input autofocus={isActive} name='password' ref={register({
              required: true,
            })} icon={<PasswordEye />} className='dy-input' label={locales?.login?.password} type='password' autoComplete={'current-password'} />
            <a className='forgot-password' href={BC_URL + '/login.php?action=reset_password'}>{locales?.login?.forgot}</a>
            <AnimatePresence>
              {formError && <motion.p initial='initial' animate='animate' exit='exit' variants={textVariants} className='error'>{formError}</motion.p>}
            </AnimatePresence>
            <Button disabled={loading} type='submit' bgType={'greenlight'}>{locales?.login?.button}</Button>
            <p className='p--new'>{locales?.login?.no_account}</p>
            <Button disabled={loading} bgType={'greenlight'} outline={true} onClick={() => { setLevel('signup') }}>{locales?.login?.button_signup}</Button>
          </form>
        </Main>
      </>
    )
  },
  'signup': ({ callback, register, close, errors, getValues, isActive, loading, formError, setLevelWelcome, locales }) => {
    const { email,  } = getValues()

    return (
      <>
        <Header text={locales?.new_account?.title} onClickPrev={setLevelWelcome} onClick={close}/>
        <Main>
          <h2>{locales?.new_account?.description}</h2>
          <form onSubmit={callback}>
            <Input name='email' initialValue={email} errorMessage={locales?.error.email_message} label={locales?.new_account?.email} className='dy-input' error={Object.keys(errors).length > 0 && errors.email ? true : false} ref={register({
              required: true,
              pattern: INPUT_PATTERNS.email
            })} />
            <Input name="password" ref={register({
              required: true,
              pattern: INPUT_PATTERNS.password
            })} icon={<PasswordEye/>} autofocus={isActive} className='dy-input' label={locales?.new_account?.password} type='password' errorMessage={locales?.error.password_message} error={Object.keys(errors).length && errors.password}/>
            <Input error={Object.keys(errors).length && errors.firstName} className='dy-input col-6 first' ref={register({ required: true })} name={'firstName'} label={locales?.new_account?.name}/>
            <Input error={Object.keys(errors).length && errors.lastName} className='dy-input col-6' ref={register({ required: true })} name={'lastName'} label={locales?.new_account?.lastname}/>
            <InputCheckbox className='signup-cb' ref={register} name='newsletter' initialChecked={true} label={locales?.new_account?.newsletter} />
            <AnimatePresence>
              {formError && <motion.p initial='initial' animate='animate' exit='exit' variants={textVariants} className='error'>{formError}</motion.p>}
            </AnimatePresence>
            <Button disabled={loading} type='submit' bgType={'greenlight'}>{locales?.new_account?.button}</Button>
            <p className='p--new'>{locales?.new_account?.has_account} <button onClick={setLevelWelcome}>{locales?.new_account?.login}</button></p>
          </form>
        </Main>
      </>
    )
  },
  'signup-complete': ({ modalClose, locales }) => {
    return (
      <>
        <Header text={locales?.welcome?.title} onClick={modalClose}/>
        <Main>
          <h2>{locales?.welcome?.description}</h2>
          <form onSubmit={null}>
            <Button onClick={modalClose} type='submit' bgType={'greenlight'}>{locales?.welcome?.button}</Button>
          </form>
        </Main>
      </>
    )
  }
}

const getLevel = (level, locale, { loading, isActive, callback, close, errors, register, getValues, formError, setLevelWelcome, watchIsCompany, modalClose, locales, setLevel }) => {
  return LEVELS[level]({ callback, locale, close, errors, register, isDone: level === 'done', getValues, formError, isActive, loading, setLevelWelcome, setLevel, watchIsCompany, modalClose, locales })
}

type Inputs = {
  email: string,
}

const handleCreator = (fn, data) => async () => {
  try {
    await fn({ ...data })
    return [true, null]
  } catch (e) {
    return [false, e]
  }
}

const BodyStyled = styled(Body)`
  h2 {
    ${getP20_1()}
  }

  header {
    .prev {
      align-self: center;

      img {
        width: ${getRelative(6, 'mobile')};
        height: ${getRelative(10, 'mobile')};

        ${mq.greaterThan('tablet')`
          width: ${getRelative(10, 'desktop')};
          height: ${getRelative(11, 'desktop')};
        `}
      }

    }
  }

  main {
    ${hideScrollbar()}
    padding: ${getRelative(40, 'mobile')} ${getRelative(40, 'mobile')} ${getRelative(30, 'mobile')};

    h2 {
      margin-bottom: ${getRelative(30, 'mobile')};
      text-align: left;
    }

    form {
      display: flex;
      flex-wrap: wrap;

      .signup-cb {
        label {
          align-items: center;
        }

        input, span {
          ${getP15()}
          font-family: 'Basier Circle';
          font-weight: 500;

        }
      }

      button {
        picture {
          transition: opacity 300ms ${({ theme }) => theme.ease};
        }
      }

      p {
        font-family: 'Basier Circle';
        width: 100%;

        &.p--new {
          ${getP15()}
          opacity: .5;
          margin: ${getRelative(30, 'mobile')} 0 ${getRelative(15, 'mobile')};
          text-align: center;

          ${mq.greaterThan('tablet')`
            margin: ${getRelative(40, 'desktop')} 0 ${getRelative(15, 'desktop')};
          `}

          button {
            border: 0;
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            text-decoration: underline;
            text-transform: uppercase;
          }
        }
      }

        div {
          &.col-6 {
            &.first {
              padding-top: ${getRelative(20, 'mobile')};
            }

            ${mq.greaterThan('tablet')`
              padding-top: ${getRelative(20, 'desktop')};
              display: inline-block;

              &.first,
              &.first + div {
                width: calc(50% - ${getRelative(20, 'desktop')});
              }

              &.first {
                margin-right: auto;
                padding-top: ${getRelative(20, 'desktop')};

              }
            `}
          }
        }

      input, label {
        font-family: 'Basier Circle';
        font-weight: 500;
        color: #324931;

        &:-internal-autofill-selected {
          appearance: none;
          background-color: none !important;
          background-image: none !important;
          color: #324931!important;
        }

        &[type='password'] ~ button {
          picture {
            opacity: .4;
          }
        }

        &[type='text'] ~ button {
          picture {
            opacity: 1;
          }
        }
      }

      button[type='submit'] {
        ${getP15()}
        width: 100%;
        margin-top: ${getRelative(30, 'mobile')};
      }

      input[name='password'] ~ p {
        opacity: 1 !important;
      }

      .hidden {
        display: none;
      }

      .error {
        ${getP20()}
        color: ${({ theme }) => theme.colors.red};
      }

      .forgot-password {
        font-size: ${getRelative(12, 'mobile')};
        margin-top: ${getRelative(-10, 'mobile')};
        color: #324931;
        font-family: 'Basier Circle';
        font-weight: 500;
        display: block;
        width: 100%;
        letter-spacing: -.3;
        display: flex;
        justify-content: flex-end;

        ${mq.greaterThan('tablet')`
          font-size: ${getRelative(12, 'desktop')};
          margin-top: ${getRelative(-10, 'desktop')};
        `}
      }
    }

  }

  ${mq.greaterThan('tablet')`
    width: ${getRelative(550, 'desktop')};
    max-width: unset;

    main {
      padding: ${getRelative(50, 'desktop')} ${getRelative(40, 'desktop')} ${getRelative(30, 'desktop')};

      h2 {
        margin-bottom: ${getRelative(30, 'desktop')};
      }

      form {
        button[type='submit'] {
          margin-top: ${getRelative(70, 'desktop')};
        }
      }
    }
  `}
`

export const modalAuthId = 'modal-auth'
export const ModalAuth = memo(() => {
  const { render, close, isActive } = useModal(modalAuthId)
  const [loading, setLoading] = useState(false)
  const isMounted = useMount()
  const [currentLevel, setLevel] = useState('login')
  const [formError, setFormError] = useState(null)
  const { register, errors, handleSubmit, getValues, watch } = useForm<Inputs>()
  const { Container, addToast, toasts } = useToast()
  const watchIsCompany = watch('isCompany')
  const { locale } = useRouter() || { locale: 'es' }

  const { layout } = usei18n()

  const signup = useSignup()
  const login = useLogin()
  const handleModalClose = useCallback(() => {
    close()
    // reset()
  }, [])

  const setLevelWelcome = useCallback(() => {
    setLevel('login')
  }, [])

  const onSubmit = useCallback(async (data) => {
    setFormError(null)

    if (currentLevel === 'signup') {
      setLoading(true)

      const [res] = await callBigcommerce({ type: 'check-customer', params: { email: data.email } })

      if(res.exists) {
        addToast({ children: TRANSLATIONS[locale].something_went_wrong_already_account, type: 'error' })
      } else  {
        const [ok, error] =  await handleCreator(signup, data)()

        if (ok) {
          try {
            const res = await login({ email: data.email, password: data.password })

            if (res.result === 'success') {
              const params = { customer_id: res.customer.entityId }
              const body = { redirect: `/?redirect_to=${window.location.href}` }

              const [_res] = await callBigcommerce({ type: 'login-url', params, body })

              addToast({ children: TRANSLATIONS[locale].successfully_registered, type: 'success' })

              window.location.href = _res.url
            } else {
              addToast({ children: TRANSLATIONS[locale].something_went_wrong, type: 'error' })
            }
          } catch (e) {
            setLoading(false)
            console.warn(e)
          }
        } else {
          addToast({ children: TRANSLATIONS[locale].something_went_wrong, type: 'error' })
          console.warn(error)
        }

        setLoading(false)

      }
    } else if (currentLevel === 'login') {
      try {
        setLoading(true)

        const res = await login({ email: data.email, password: data.password })

        if (res.result === 'success') {
          const params = { customer_id: res.customer.entityId }
          const body = { redirect: `/?redirect_to=${window.location.href}` }

          const [_res] = await callBigcommerce({ type: 'login-url', params, body })
          addToast({ children: TRANSLATIONS[locale].successfully_login, type: 'success' })

          window.location.href = _res.url
        } else {
          addToast({ children: TRANSLATIONS[locale].something_went_wrong_create, type: 'error' })
        }
      } catch (e) {
        console.warn(e)
      }
      finally {
        setLoading(false)
      }
    }
  }, [currentLevel, signup])

  useEffect(() => {
    return () => {
      setLevel('login')
      setFormError(null)
    }
  }, [])

  useEffect(() => {
    if (isActive && currentLevel === 'inactive') setLevel('login')
  }, [isActive, currentLevel])

  const Level = useMemo(() => getLevel(currentLevel, locale, { watchIsCompany, loading, isActive, register, errors, modalClose: close, close: handleModalClose, callback: handleSubmit(onSubmit), getValues, formError, setLevelWelcome, setLevel, locales: layout?.auth }), [currentLevel, locale, register, Object.keys(errors).length, handleModalClose, onSubmit, formError, isActive, loading, setLevelWelcome, setLevel, watchIsCompany])

  return isMounted ? render(
    <AnimatePresence exitBeforeEnter>
      <BodyStyled framerKey={currentLevel + isActive} isActive={isActive}>
        {isActive && Level}
        <Container toasts={toasts} />
      </BodyStyled>
    </AnimatePresence>
  ) : <></>
})
