import { memo } from 'react'
import styled from 'styled-components'

import { mq, getRelative, getP53, getP20_1, getP18_1 } from '@/styles'
import { usei18n } from '@dy/commons/i18n'

interface ISectionIngredients {
  data: string
}

const H2 = styled.h2`
  ${getP53()}
  color: ${({ theme }) => theme.colors.greendark};
  margin: ${getRelative(60, 'mobile')} 0 ${getRelative(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin: ${getRelative(80, 'desktop')} 0 ${getRelative(20, 'desktop')};
  `}
`

const P = styled.p`
  ${getP18_1()}

  ${mq.greaterThan('tablet')`
    ${getP20_1()}
  `}
`

export const SectionIngredients = memo<ISectionIngredients>(({ data }) => {
  const { layout } = usei18n()

  return (
    <section>
      <H2>{layout.product_page.product_description.nutritional_value.ingredients}</H2>
      <P>{data}</P>
    </section>
  )
})
