import { ProductInterface, ProductFetchSuccessAction, ProductFetchPendingAction, ProductFetchErrorAction, FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_PENDING, FETCH_PRODUCT_ERROR } from './product.types'

interface ProductState {
  pending: boolean,
  data: ProductInterface,
  error: TypeError
} 

export const InitialState: ProductState = {
  pending: false,
  data: null,
  error: null
}

const productFetchPending = (state: ProductState, _: ProductFetchPendingAction) => {
  return {
    ...state,
    pending: true,
    error: null
  }
}

const productFetchSuccess = (state: ProductState, action: ProductFetchSuccessAction) => {
  return {
    ...state,
    pending: false,
    error: null,
    data: action.payload
  }
}

const productFetchError = (state: ProductState, action: ProductFetchErrorAction) => {
  return {
    ...state,
    pending: false,
    error: action.payload
  }
}

export const reducers = {
  [FETCH_PRODUCT_PENDING]: productFetchPending,
  [FETCH_PRODUCT_SUCCESS]: productFetchSuccess,
  [FETCH_PRODUCT_ERROR]: productFetchError
}