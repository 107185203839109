import { ModalState, ModalActionTypes, TRIGGER_MODAL } from './types'

export const InitialState: ModalState = {}

const modalsUpdate = (state = InitialState, action: ModalActionTypes) => {
  const key = Object.keys(action.payload)[0]

  return { ...state, [key]: { ...state[key], ...action.payload[key] } }
}

export const reducers = {
  [TRIGGER_MODAL]: modalsUpdate,
}
