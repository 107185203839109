"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.hideScrollbar = exports.createTooltip = exports.createUnderline = void 0;
var styled_components_1 = require("styled-components");
var _1 = require("./");
/**
 * Creates an animated underline
 *
 * @remarks
 * Used with styled-component:
 * ```tsx
 * import styled from 'styled-components'
 *
 * const Article = styled.article`
 *  a {
 *    ${createUnderline()}
 *  }
 * `
 * ```
 * @remarks
 * The component must have an <span/> element as a child
 * ```tsx
 * const Card = () => (
 *  <Article>
 *   <a href='https://klk.com'>Go 😎<span/></a>
 *  </Article>
 * )
 * ```
  * @remarks
  * Adding the className "active" triggers the active animation
  * ```tsx
  * const Card = ({ isActive }) => (
  *   <Article>
  *     <a className={isActive ? 'active' : ''} href='https://klk.com'>Go 😎<span/></a>
  *   </Article>
  * )
  * ```
*/
var createUnderline = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.height, height = _c === void 0 ? 1 : _c, _d = _b.reverse, reverse = _d === void 0 ? false : _d, _e = _b.color, color = _e === void 0 ? 'black' : _e;
    return (0, styled_components_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n\n  span {\n    position: absolute;\n    bottom: 0;\n    height: ", "px;\n    width: 0;\n    background-color: ", ";\n    left: unset;\n    right: 0;\n    transition: width 300ms ease, left 300ms ease, right 300ms ease;\n  }\n\n  ", "\n\n  &:hover,\n  &.active {\n    ", "\n  }\n"], ["\n  position: relative;\n\n  span {\n    position: absolute;\n    bottom: 0;\n    height: ", "px;\n    width: 0;\n    background-color: ", ";\n    left: unset;\n    right: 0;\n    transition: width 300ms ease, left 300ms ease, right 300ms ease;\n  }\n\n  ", "\n\n  &:hover,\n  &.active {\n    ", "\n  }\n"])), height, function (_a) {
        var theme = _a.theme;
        return theme.colors[color];
    }, reverse && "\n    span {\n      left: 0;\n      right: unset;\n      width: 100%;\n    }\n  ", reverse ? "\n      span {\n        left: unset;\n        right: 0;\n        width: 0;\n      }" : "\n      span {\n        left: 0;\n        right: unset;\n        width: 100%;\n      }");
};
exports.createUnderline = createUnderline;
var createTooltip = function (_a) {
    var _b = _a.color, color = _b === void 0 ? '#F6A300' : _b;
    return (0, styled_components_1.css)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\n&:before {\n    content: '';\n    display: block;\n    position: absolute;\n    left: 50%;\n    transform: translateX(-50%);\n    bottom: 100%;\n    width: 0;\n    height: 0;\n    border: ", " solid transparent;\n    border-bottom-color: ", ";\n\n    ", "\n}\n\n&:after {\n    content: '';\n    display: block;\n    position: absolute;\n    left: 50%;\n    transform: translateX(-50%);\n    bottom: 100%;\n    width: 0;\n    height: 0;\n    border: ", " solid transparent;\n    border-bottom-color: ", ";\n\n    ", "\n}\n"], ["\n\n&:before {\n    content: '';\n    display: block;\n    position: absolute;\n    left: 50%;\n    transform: translateX(-50%);\n    bottom: 100%;\n    width: 0;\n    height: 0;\n    border: ", " solid transparent;\n    border-bottom-color: ", ";\n\n    ", "\n}\n\n&:after {\n    content: '';\n    display: block;\n    position: absolute;\n    left: 50%;\n    transform: translateX(-50%);\n    bottom: 100%;\n    width: 0;\n    height: 0;\n    border: ", " solid transparent;\n    border-bottom-color: ", ";\n\n    ", "\n}\n"])), (0, _1.getRelative)(9, 'mobile'), color, _1.mq.greaterThan('tablet')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      border: ", " solid transparent;\n      border-bottom-color: ", ";\n    "], ["\n      border: ", " solid transparent;\n      border-bottom-color: ", ";\n    "])), (0, _1.getRelative)(9, 'desktop'), color), (0, _1.getRelative)(4, 'mobile'), color, _1.mq.greaterThan('tablet')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      border: ", " solid transparent;\n      border-bottom-color: ", ";\n    "], ["\n      border: ", " solid transparent;\n      border-bottom-color: ", ";\n    "])), (0, _1.getRelative)(4, 'desktop'), color));
};
exports.createTooltip = createTooltip;
var hideScrollbar = function () { return (0, styled_components_1.css)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    -ms-overflow-style: none;  /* Internet Explorer 10+ */\n    scrollbar-width: none;  /* Firefox */\n\n  &::-webkit-scrollbar {\n    background-color: rgba(0, 0, 0, 0);\n    display: none;\n  }\n"], ["\n    -ms-overflow-style: none;  /* Internet Explorer 10+ */\n    scrollbar-width: none;  /* Firefox */\n\n  &::-webkit-scrollbar {\n    background-color: rgba(0, 0, 0, 0);\n    display: none;\n  }\n"]))); };
exports.hideScrollbar = hideScrollbar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
