"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccordion = exports.AccordionContext = void 0;
var react_1 = require("react");
exports.AccordionContext = (0, react_1.createContext)(null);
var useAccordion = function (length) {
    var _a = __read((0, react_1.useState)(new Array(length).fill(false)), 2), activeIndex = _a[0], setActiveIndex = _a[1];
    var expandAll = function () {
        setActiveIndex(new Array(length).fill(true));
    };
    var collapseAll = function () {
        setActiveIndex(new Array(length).fill(false));
    };
    return [activeIndex, setActiveIndex, expandAll, collapseAll];
};
exports.useAccordion = useAccordion;
