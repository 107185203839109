import { memo, forwardRef, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { Main, Body, Overlay, Aside } from './MenuMobileLayout'
import { useMount, useScrollViewer } from '@/hooks'
import { CategoriesAccordion } from '@dy/commons/components'
import type { TypeCategoryList } from '@/schema'
import { getTransition } from '@/styles'
import useCustomer from '@custom-bigcommerce/storefront-data-hooks/use-customer'

const Div = styled.div<any>``

const transition = {
  ...getTransition()
}

export const listVariants = {
  inactive: {
    opacity: 0,
    transition
  },
  active: {
    opacity: 1,
    transition: {
      ...getTransition(),
      staggerChildren: .01
    }
  },
  exit: {
    opacity: 0,
    transition
  }
}

export const itemVariants = {
  inactive: {
    x: -10,
    opacity: 0,
    transition
  },
  active: {
    x: 0,
    opacity: 1,
    transition
  },
  exit: {
    opacity: 0
  }
}

interface IModalMenuMobile {
  categories: TypeCategoryList
  isActive: boolean,
  theresPacks?: boolean,
  onClickClose: any,
  ref?: any,
  hasBanners?: boolean
}

const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_LOCALE
const TRANSLATIONS = {
  'en': {
    'name': 'My Favourites'
  },
  'es': {
    'name': 'Mis habituales'
  },
  'fr': {
    'name': 'Mon historique'
  }
}

export const myUsualAsLink = {
  name: TRANSLATIONS[DEFAULT_LOCALE].name,
  slug: {
    es: '/account/usual-purchases',
    en: '/account/usual-purchases',
    fr: '/account/usual-purchases'
  },
  subcategories: []
}

export const packsAsLink = {
  name: 'Packs',
  slug: {
    es: '/packs',
    en: '/packs',
    fr: '/packs'
  },
  subcategories: []
}

export const modalMenuMobileId = 'modal-menu-mobile'
export const ModalMenuMobile = memo<IModalMenuMobile>(forwardRef(({ categories = [], isActive, onClickClose, theresPacks, hasBanners }, ref) => {
  const isMounted = useMount()
  const { data: userData }  = useCustomer()
  const scrollState = useScrollViewer(1)
  const scrollTop = useMemo(() => scrollState === 'TOP', [scrollState])

  const userLogged = useMemo(()=> !!userData?.entityId,[userData])

  const [updatedCategories, setUpdatedCategories] = useState([...categories, ...theresPacks && DEFAULT_LOCALE !== 'es' ? [packsAsLink] : []])

  useEffect(() => {
    setUpdatedCategories([
      ...userLogged ? [myUsualAsLink] : [],
      ...categories,
      ...theresPacks && DEFAULT_LOCALE !== 'es' ? [packsAsLink] : [],

    ])
  }, [userLogged, categories, theresPacks])

  const closeMenuMobile = () => {
    onClickClose()
  }


  return (isMounted ?
    <Aside isActive={isActive}>
    <Overlay onClick={closeMenuMobile} className={isActive ? 'active' : ''}/>
    <Body ref={ref} isActive={isActive}>
      <Main>
        <Div $scrollTop={scrollTop} $hasBanners={hasBanners}>
          <AnimatePresence exitBeforeEnter>
            <CategoriesAccordion key={`list-2-${isActive}`} categories={updatedCategories} menuMobile={true} closeMenuMobile={closeMenuMobile} />
          </AnimatePresence>
        </Div>
      </Main>
    </Body></Aside> : <></>
  )
}))
