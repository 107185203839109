import { ArticleInterface } from 'redux/article/article.types'
import { CategoryInterface } from 'redux/category/category.types'
import { SeoInterface, SlugInterface, SpecificationInterface, BrandInterface, ContentInterface, MediumInterface, ProductDownloadInterface, ColorInterface, SizeInterface, VariantInterface } from '../types/global/global.types'

export interface ProductInterface {
  bigcommerce_id: number,
  name: string,
  seo: SeoInterface,
  slug: SlugInterface,
  is_new_release: boolean,
  short_description: string,
  long_description: string,
  specifications: SpecificationInterface,
  brand: BrandInterface,
  contents: ContentInterface[],
  product_media: MediumInterface[],
  articles: ArticleInterface[],
  categories: CategoryInterface[],
  product_downloads: ProductDownloadInterface[],
  colors: ColorInterface[],
  sizes: SizeInterface[],
  variants: VariantInterface[]
}

export const FETCH_PRODUCT_PENDING = 'PRODUCT/FETCH_PENDING'
export const FETCH_PRODUCT_SUCCESS = 'PRODUCT/FETCH_SUCCESS'
export const FETCH_PRODUCT_ERROR   = 'PRODUCT/FETCH_ERROR'

export interface ProductFetchSuccessAction {
  type: typeof FETCH_PRODUCT_SUCCESS,
  payload: ProductInterface
}

export interface ProductFetchPendingAction {
  type: typeof FETCH_PRODUCT_PENDING,
  payload: boolean
}

export interface ProductFetchErrorAction {
  type: typeof FETCH_PRODUCT_ERROR,
  payload: TypeError
}