import { memo, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { motion } from 'framer-motion'
import { useMediaType, useMount, useScrollViewer } from '@/hooks'
import type { TypeCategoryList } from '@/schema'
import { mq, getRelative, getTransition, createGrid, hex2Rgba, getP12_1 } from '@/styles'
import useCustomer from '@custom-bigcommerce/storefront-data-hooks/use-customer'
import useCart from '@custom-bigcommerce/storefront-data-hooks/cart/use-cart'

import { Burger } from './Burger'
import { Logo } from './Logo'
import { NavLinkAccount, NavLinkCart } from './NavLink'
import { ModalMenuMobile } from './ModalMenuMobile'
// import { NavList } from './NavList'
import { MenuAccount } from './MenuAccount'

import dynamic from 'next/dynamic'
import type { ISearch } from './Search'

const DynamicSearch = dynamic<ISearch>(() => import('./Search').then((mod) => mod.Search))

const Nav = styled<any>(motion.nav)`
  ${createGrid()}
  align-items: center;
  background-color: ${({ menuActive, theme: { colors } }) => menuActive ? hex2Rgba(colors.background, 0) : colors.background};
  box-shadow: ${({ menuActive, scrollTop, boxShadow, theme: { colors } }) => (boxShadow && !scrollTop && !menuActive) ? `0 4px 40px 0 ${hex2Rgba(colors.black, .1)}` : 'none'};
  display: flex;
  height: ${getRelative(60, 'mobile')};
  justify-content: space-between;
  left: 0;
  opacity: 0;
  padding: 0 ${getRelative(10, 'mobile')};
  position: fixed;
  right: 0;
  top: 0;
  transition: 300ms background-color ${({ ease }) => ease}, 300ms opacity ${({ ease }) => ease};
  width: 100vw;
  z-index: 500;

  ${mq.greaterThan('tablet')`
    background-color: ${({ theme }) => theme.colors.background};
    display: grid;
    height: ${getRelative(120, 'desktop')};
    padding: 0;

    &.scrolling_up {
      ~ div.main {
        .filters-dy-klk {
          top: ${getRelative(75, 'desktop')};
        }

        [class*='SectionProductDetail__Section'].plp-klk {
          top: ${getRelative(75, 'desktop')};;
          height: calc(100vh - ${getRelative(100, 'desktop')});
        }
      }
    }
  `}

  &.mounted {
    opacity: 1;
  }

  .burger,
  .search,
  .account,
  .cart {
    align-items: center;
    height: 100%;
    position: relative;
  }
  .log {
    max-width:unset;
    width:unset;
    ${mq.greaterThan('tablet')`
      max-width: ${getRelative(130, 'desktop')};
    `}
  }
  .search,
  .account,
  .cart {
    display: flex;
  }

  .nav-list {
    display: none;

    ${mq.greaterThan('tablet')`
      display: flex;
      grid-column: 4 / span 3;
    `}

    button,
    a {
      ${mq.greaterThan('tablet')`
        font-weight: bold;
        padding: 0 ${getRelative(30, 'desktop')} 0 0;
      `}
    }
  }

  .nav-links {
    display: flex;
    order: 3;

    ${mq.greaterThan('tablet')`
      grid-column: 12 / span 1;
      justify-content: flex-end;
      margin-right: ${getRelative(30, 'desktop')};
      align-items:center;
    `}

    li:not(:last-of-type) {
      margin-right: ${getRelative(10, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-right: ${getRelative(33, 'desktop')};
      `}
    }
    
    .log{
      img {
        width: ${getRelative(20, 'mobile')};
        height: ${getRelative(22, 'mobile')};

        ${mq.greaterThan('tablet')`
          height: ${getRelative(18, 'desktop')};
          width: ${getRelative(18, 'desktop')};
        `}
      }
    }

    img {
      width: ${getRelative(20, 'mobile')};
      height: ${getRelative(22, 'mobile')};

      ${mq.greaterThan('tablet')`
        height: ${getRelative(20, 'desktop')};
        width: ${getRelative(22, 'desktop')};
      `}
    }

    .cart.empty img {
      width: ${getRelative(26, 'mobile')};

      ${mq.greaterThan('tablet')`
        width: ${getRelative(26, 'desktop')};
        height: auto;
      `}
    }

    .cart.not_empty img {
      width: ${getRelative(26, 'mobile')};

      ${mq.greaterThan('tablet')`
        width: ${getRelative(20, 'desktop')};
        height: ${getRelative(14, 'desktop')};
      `}
    }
    .log {
      .user_name {
        ${getP12_1()}
        display:none;

        ${mq.greaterThan('tablet')`
         white-space: nowrap;
         display: inline-block;
         color: ${({ theme }) => theme.colors.orange};
         margin-left: ${getRelative(10, 'desktop')};
        `}
      }
    }
    .account_s {
        ${getP12_1()}
        display:none;

        ${mq.greaterThan('tablet')`
         white-space: nowrap;
         display: inline-block;
         color: ${({ theme }) => theme.colors.greenlight};
         margin-left: ${getRelative(10, 'desktop')};
        `}
      }
  }
`

const transition = {
  ...getTransition()
}

const variants = {
  mobile: {
    height: getRelative(50, 'mobile'),
    y: 0,
    transition
  },
  'mobile-top': {
    height: getRelative(60, 'mobile'),
    y: '50px',
    transition
  },
  expanded: {
    height: getRelative(120, 'desktop'),
    y: getRelative(30, 'desktop'),
    transition
  },
  collapsed: {
    height: getRelative(80, 'desktop'),
    y: 0,
    transition
  },
  scrolling_up: {
    height: getRelative(80, 'desktop'),
    y: 0,
    transition
  }
}

const variantsWithoutBanner = {
  mobile: {
    height: getRelative(50, 'mobile'),
    y: 0,
    transition
  },
  'mobile-top': {
    height: getRelative(60, 'mobile'),
    y: '0px',
    transition
  },
  expanded: {
    height: getRelative(120, 'desktop'),
    y: 0,
    transition
  },
  collapsed: {
    height: getRelative(80, 'desktop'),
    y: 0,
    transition
  },
  scrolling_up: {
    height: getRelative(80, 'desktop'),
    y: 0,
    transition
  }
}

interface INavbar {
  readonly categories: TypeCategoryList,
  theresPacks: boolean,
  hasBanners: boolean
  // pages: any[] | null
  setShowCart?: any,
  showCart?: boolean
}

const hasTransparentNavbar = (route) => {
  switch(route) {
    case '/':
    case '/brand':
    case '/brand/[slug]':
      return true
    default:
      return false
  }
}

const colorSetter = ({ isMenuActive, route, mobile, scrollTop }, icon) => {
  if(mobile && isMenuActive || icon === 'burger-logo' && isMenuActive) return 'white'
  if(route === '/product/[slug]') {
    if(mobile) return 'greenlight'
    // Desktop
    if(icon === 'burger-logo' && scrollTop) return 'greenlight'
    return 'greenlight'
  }
  if(!scrollTop) return 'greenlight'
  if(!mobile && icon === 'search') return 'greenlight'
  if(hasTransparentNavbar(route)) return 'greenlight'
  return 'greenlight'
}

const locales = {
  "title": "Mi cuenta",
  "personalinfo": "Información personal",
  "orderhistory": "Pedidos",
  "addressbook": "Direcciones",
  "logout": "Cerrar sesión"
}

export const Navbar = memo<INavbar>(({ categories, theresPacks, hasBanners, setShowCart, showCart }) => {
  const { data: userData }  = useCustomer()
  const { data: dataCart } = useCart()
  const scrollState = useScrollViewer(1)
  const [isMenuActive, setIsMenuActive] = useState(false)
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const [isCartEmpty, setIsCartEmpty] = useState(false)
  const [isMenuAccountActive, setMenuActive] = useState(false)
  const isMounted = useMount()
  const navbarRef= useRef(null)
  const mediaType = useMediaType()
  const { route, pathname } = useRouter() || { route: '', pathname: '' }

  const mobile = useMemo(() => mediaType === 'mobile', [mediaType])
  const scrollTop = useMemo(() => scrollState === 'TOP', [scrollState])
  const animate = useMemo(() => mobile ? scrollState === 'TOP' ? 'mobile-top' : 'mobile' : scrollState === 'SCROLLING_UP' ? 'scrolling_up' : scrollTop ? 'expanded' : 'collapsed', [mediaType, scrollTop, scrollState])

  const [fillColor, setFillColor] = useState('greenlight')
  const [cartColor, setCartColor] = useState('greenlight')
  const [LogoFillColor, setLogoFillColor] = useState('greenlight')
  const [searchFillColor, setSearchFillColor] = useState('greenlight')

  useEffect(() => {
    if(!isMounted) return
    // isMenuActive ? disableBodyScroll(navbarRef.current) : enableBodyScroll(navbarRef.current)
    // return () => clearAllBodyScrollLocks()
  }, [isMenuActive, isMounted])

  useEffect(() => {
    if(isMenuActive) setIsMenuActive(false)
  }, [pathname])

  useEffect(() => {
    setFillColor(colorSetter({ isMenuActive, route, mobile, scrollTop }, 'default'))
    setLogoFillColor(colorSetter({ isMenuActive, route, mobile, scrollTop }, 'burger-logo'))
    setSearchFillColor(colorSetter({ isMenuActive, route, mobile, scrollTop }, 'search'))
  }, [route, scrollTop, isMenuActive])

  useEffect(() => {
    if(isMenuActive) setIsMenuActive(false)
  }, [pathname])

  useEffect(() => {
    if(userData?.entityId) {
      setIsUserLoggedIn(true)
      setFillColor('orange')
    } else {
      setFillColor('greenlight')
    }
  }, [userData, fillColor])

  useEffect(()=> {
    if(dataCart?.cart_amount){
      setCartColor('orange')
      setIsCartEmpty(false)

    } else if(dataCart?.cart_amount === undefined) {
      setCartColor('greenlight')
      setIsCartEmpty(true)

    }
  },[dataCart?.cart_amount, cartColor, isCartEmpty])

  return (isMounted ? <>
      <Nav ref={navbarRef} className={`${isMounted ? 'mounted' : ''}  ${animate === 'scrolling_up' ? 'scrolling_up' : ''} ${animate.includes('mobile') ? 'mobile' : ''}`} variants={hasBanners ? variants : variantsWithoutBanner} initial={'expanded'} animate={animate} scrollTop={scrollTop} menuActive={isMenuActive} boxShadow={pathname !== '/shop/[...slug]'}>
        <Burger onClick={() => setIsMenuActive(!isMenuActive)} menuActive={isMenuActive} />
        <Logo fillColor={LogoFillColor} />
        {/* <NavList key={theresPacks + ''}  theresPacks={theresPacks} firstCategory={categories?.[0]}/> */}
        <DynamicSearch fillColor={searchFillColor} categories={categories} isUserLoggedIn={isUserLoggedIn} isCartEmpty={isCartEmpty}/>
        <ul className='nav-links'>
          <li key={'account'} className='account-wrapper'>
            <NavLinkAccount onClick={() => setMenuActive(true)} fillColor={LogoFillColor} isUserLoggedIn={isUserLoggedIn}/>
          </li>
          <li key={'bag'} className='cart-wrapper' onMouseEnter={() => setShowCart(true)}>
            <NavLinkCart fillColor={cartColor} isMenuActive={isMenuActive} showCart={showCart} />
          </li>
        </ul>
      </Nav>
      <ModalMenuMobile theresPacks={theresPacks} hasBanners={hasBanners}ref={navbarRef} onClickClose={() => setIsMenuActive(false)} isActive={isMenuActive} categories={categories}/>
      <MenuAccount locales={locales} onClose={() => setMenuActive(false)} isActive={isMenuAccountActive} />
    </> : <></>)
})
