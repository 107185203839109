import { SeoInterface, SlugInterface, ContentInterface } from '../types/global/global.types'

export interface PageInterface {
  seo: SeoInterface,
  slug: SlugInterface,
  title: string,
  contents: ContentInterface[]
}

export const FETCH_PAGE_PENDING = 'PAGE/FETCH_PENDING'
export const FETCH_PAGE_SUCCESS = 'PAGE/FETCH_SUCCESS'
export const FETCH_PAGE_ERROR   = 'PAGE/FETCH_ERROR'

export interface PageFetchSuccessAction {
  type: typeof FETCH_PAGE_SUCCESS,
  payload: PageInterface
}

export interface PageFetchPendingAction {
  type: typeof FETCH_PAGE_PENDING,
  payload: boolean
}

export interface PageFetchErrorAction {
  type: typeof FETCH_PAGE_ERROR,
  payload: TypeError
}