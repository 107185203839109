import { memo } from 'react'
import styled from 'styled-components'
import { FormNewsletter } from '@dy/commons/components'
import { usei18n } from '@dy/commons/i18n'
import { useMount } from '@/hooks'
import { getP43, mq, getRelative } from '@/styles'
//trigger

const Aside = styled.aside`
  background-color: ${({ theme }) => theme.colors.orange};
  display: flex;
  flex-direction: column;
  height: ${getRelative(280, 'mobile')};
  padding: ${getRelative(30, 'mobile')} ${getRelative(15, 'mobile')};
  justify-content: center;
  align-items: flex-start;

  ${mq.greaterThan('tablet')`
    flex-direction: row;
    height: ${getRelative(140, 'desktop')};
    padding: ${getRelative(31, 'desktop')} ${getRelative(40, 'desktop')} ${getRelative(27, 'desktop')} ${getRelative(40, 'desktop')};
    justify-content: space-between;
    align-items: center;
  `}

  h2 {
    ${getP43()}
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: ${getRelative(20, 'mobile')};
    text-align: left;

    ${mq.greaterThan('tablet')`
      margin-bottom: 0;
    `}
    
  }
  form {
    width: 100%;
    /* height: 100%; */
    ${mq.greaterThan('tablet')`
      width: ${getRelative(450, 'desktop')};
    `}
  }
  input {
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  }
`


export const PreFooter = memo<any>(() => {
  const { layout } = usei18n()
  const isMount = useMount()

  return ( isMount ? 
    <Aside>
      <h2>{layout?.footer?.newsletter?.title} <br/> {layout?.footer?.newsletter?.sub_title}</h2>
      <FormNewsletter showLegend={false} showSmallPrint={true}/>
    </Aside> : <></>
  )
})