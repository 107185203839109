"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSelect = void 0;
var react_1 = require("react");
var useSelect = function (initialValue) {
    if (initialValue === void 0) { initialValue = ''; }
    var _a = __read((0, react_1.useState)(initialValue), 2), value = _a[0], setValue = _a[1];
    var selectRef = (0, react_1.useRef)(null);
    var reset = function () { return setValue(initialValue); };
    var updateSelectedOption = function (target) {
        var e_1, _a;
        var items = target.parentElement.children;
        try {
            for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                var item = items_1_1.value;
                item.dataset.selected = false;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        target.dataset.selected = true;
    };
    var onChange = function (_a) {
        var target = _a.target;
        setValue(target.dataset.value);
        selectRef.current.value = target.dataset.value;
        updateSelectedOption(target);
    };
    return [selectRef, value, onChange, reset];
};
exports.useSelect = useSelect;
