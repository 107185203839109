
import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player/lazy'
import { useInView } from 'react-intersection-observer'
import { useModal, useMediaType } from '@/hooks'
import { ModalVideo, modalVideoId } from '@/modals'
import { mq, getRelative, hex2Rgba, getMargin20, getP10, getP12_1 } from '@/styles'

const Figure = styled.figure<any>`
  height: auto;
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }

  figcaption {
    ${getP12_1()}
    ${getMargin20(true)}
    border-bottom: ${({ theme }) => hex2Rgba(theme.colors.black, .1)};
    padding-bottom: ${getRelative(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-bottom: ${getRelative(20, 'desktop')};
    `}
  }

  > div {
    height:  ${({ longModal }) => longModal ? '56.25vw' : `${getRelative(232, 'mobile')}`} !important;
    pointer-events: ${({ longModal }) => longModal ? 'auto' : 'none'};
    width: 100% !important;

    ${mq.greaterThan<any>('tablet')`
      height:  ${({ longModal }) => longModal ? '56.25vw' : `${getRelative(540, 'desktop')}`} !important;
      max-height: 100vh;
    `}
  }
`

const PlayIcon = styled.button<any>`
  align-items: center;
  border-radius: 100%;
  display: flex;
  height: ${getRelative(90, 'mobile')};
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: height 300ms ${({ theme }) => theme.ease}, width 300ms ${({ theme }) => theme.ease};
  width: ${getRelative(80, 'mobile')};
  z-index: 2;

  ${mq.greaterThan('tablet')`
    height: ${getRelative(120, 'desktop')};
    width: ${getRelative(110, 'desktop')};


    &:hover {
      img {
        &:first-child {
          width: ${getRelative(110, 'desktop')};
        }
        &:nth-child(2) {
          width: ${getRelative(82, 'desktop')};
        }
      }
    }
  `}

  span {
    ${getP10()}
    color: #ffffff;
    left: 50%;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  div {
    height: 100%;
    position: relative;
    width: 100%;
  }

  img {
    left: 50%;
    opacity: .5;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 300ms width ${({ ease }) => ease };
    user-select: none;
    width: ${getRelative(73, 'mobile')};

    &:first-child {
      transition-delay: 150ms;
    }

    ${mq.greaterThan('tablet')`
      width: ${getRelative(91, 'desktop')};
    `}

    &.xs {
      opacity: 1;
      width: ${getRelative(50, 'mobile')};
      z-index: 2;

      ${mq.greaterThan('tablet')`
        width: ${getRelative(63, 'desktop')};
      `}
    }
  }
`

type ContentTypeVideo = {
  col?: { from: number, expand: number},
  row?: { from: number, expand: number},
  media: {
    type: 'video' | string,
    alt?: string,
    short_video?: string | number,
    long_video?: string | number,
    platform: 'vimeo' | 'youtube' | string
  },
  caption?: string,
  type: 'media' | string,
}

interface IContentVideo {
  data: ContentTypeVideo,
  longModal?: boolean,
  playsinline?: boolean,
  modalActive?: boolean
}

export const ContentVideo = memo(({ data, longModal = false, modalActive = false, playsinline = false }:IContentVideo) => {
  const mediaType = useMediaType()
  const { media: { platform, long_video, short_video } } = data
  const [playing, setPlaying] = useState(!longModal)
  const [ref, inView] = useInView({
    rootMargin: '0px', // TOP  RIGHT
    threshold: 0,
  })

  const url = `https://www.${platform}.com/${platform === 'youtube' ? 'watch?v=' : ''}${longModal ? long_video : short_video}`

  const { open } = useModal(`${modalVideoId}_${platform}_${long_video}`)
  const onClick = () => {
    open()
  }

  useEffect(() => {
    if(longModal) return
    setPlaying(inView)
  }, [inView])

  useEffect(() => {
    if(longModal) setPlaying(modalActive)
  }, [modalActive])

  return (
    <>
      <Figure ref={ref} longModal={longModal} className='figure--video'>
        <ReactPlayer url={url} playsinline={mediaType === 'mobile' ? true : playsinline} controls={longModal} volume={longModal ? .5 : 0} muted={!longModal} loop={!longModal} title={''} playing={mediaType === 'mobile' ? true : playing} />
        {
          long_video && !longModal &&
          <PlayIcon onClick={onClick}>
            <div>
              <img src='/images/svg/i--hexagon-rounded.svg' alt='Icon Hexagon'  width='63' height='69' />
              <img className='xs' src='/images/svg/i--hexagon-rounded.svg' alt='Icon Hexagon small'  width='63' height='69'/>
              <span>Play</span>
            </div>
          </PlayIcon>
        }
      </Figure>
      {long_video && !longModal && <ModalVideo data={data} id={`${long_video}`} platform={platform} />}
    </>
  )
})
