"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFeaturedFilters = exports.slugMatch = exports.getFiltersFromQuery = exports.generateQueryParams = exports.updateActiveFilters = exports.getFilters = exports.getFilteredProducts = exports.hasQueryParams = void 0;
var hasQueryParams = function (asPath, param) {
    if (param === void 0) { param = null; }
    return !!asPath.split("?".concat(param ? "".concat(param, "=") : ''))[1];
};
exports.hasQueryParams = hasQueryParams;
var sortProducts = function (products, sortBy) {
    if (sortBy === void 0) { sortBy = null; }
    var _products = Array.from(products);
    if (!sortBy || Object.keys(sortBy)[0] === 'recommended') {
        return _products;
    }
    var ascending = Object.keys(sortBy)[0] === 'low-price';
    _products.sort(function (a, b) {
        var a_price_per_unit = a.variant.prices.price_ex_tax;
        var b_price_per_unit = b.variant.prices.price_ex_tax;
        return ascending ? a_price_per_unit - b_price_per_unit : b_price_per_unit - a_price_per_unit;
    });
    return _products;
};
var getFilteredProducts = function (products, appliedFilters) {
    var e_1, _a;
    var _b;
    if (products === void 0) { products = []; }
    if ((products === null || products === void 0 ? void 0 : products.length) < 1)
        return [];
    if (Object.keys(appliedFilters).length < 1)
        return sortProducts(products);
    // Parse appliedFilters into keyparams as array of true values
    // sample: { size: ['s', 'm'], color: ['white', 'blue', 'yellow']}
    var parsedFilters = {};
    for (var filterParam in appliedFilters) {
        if (typeof appliedFilters[filterParam] === 'boolean') {
            parsedFilters[filterParam] = true;
        }
        else {
            if (filterParam !== 'sort') {
                var values = appliedFilters[filterParam];
                if (typeof values === 'object' && Object.keys(values).length > 0)
                    parsedFilters[filterParam] = values;
            }
        }
    }
    if (Object.keys(parsedFilters).length < 1)
        return sortProducts(products, appliedFilters.sort);
    // If something to filter
    var filteredProducts = [];
    try {
        for (var products_1 = __values(products), products_1_1 = products_1.next(); !products_1_1.done; products_1_1 = products_1.next()) {
            var product = products_1_1.value;
            var matchAllFilters = false;
            var _loop_1 = function (filterParam) {
                var e_2, _c;
                if ((_b = product === null || product === void 0 ? void 0 : product.filters) === null || _b === void 0 ? void 0 : _b.some(function (filter) { return filter.param === filterParam; })) {
                    var _loop_2 = function (filter) {
                        if (filter.param === filterParam) {
                            if (filter.type === 'simple') {
                                matchAllFilters = true;
                            }
                            else {
                                var values_1 = filter.values.map(function (value) { return value.value; });
                                //@ts-ignore
                                var matchedValues = __spreadArray([], __read(new Set(Object.keys(parsedFilters[filterParam]).filter(function (val) { return values_1.includes(val); }))), false);
                                matchAllFilters = matchedValues.length > 0;
                            }
                            return "break";
                        }
                    };
                    try {
                        // product has this filter param, now lets check if match at least one param value
                        for (var _d = (e_2 = void 0, __values(product.filters)), _e = _d.next(); !_e.done; _e = _d.next()) {
                            var filter = _e.value;
                            var state_2 = _loop_2(filter);
                            if (state_2 === "break")
                                break;
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_e && !_e.done && (_c = _d.return)) _c.call(_d);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
                else {
                    matchAllFilters = false;
                    return "break";
                }
                if (!matchAllFilters)
                    return "break";
            };
            // Product must match all filterParam
            for (var filterParam in parsedFilters) {
                var state_1 = _loop_1(filterParam);
                if (state_1 === "break")
                    break;
            }
            if (matchAllFilters)
                filteredProducts.push(product);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (products_1_1 && !products_1_1.done && (_a = products_1.return)) _a.call(products_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return sortProducts(filteredProducts, appliedFilters.sort);
};
exports.getFilteredProducts = getFilteredProducts;
var TRANSLATIONS_SORT_BY = {
    'en': {
        name: 'Sort',
        values: [
            { name: 'Recommended', value: 'recommended' },
            { name: 'Lowest price', value: 'low-price' },
            { name: 'Highest price', value: 'high-price' },
        ]
    },
    'es': {
        name: 'Ordenar',
        values: [
            { name: 'Recomendados', value: 'recommended' },
            { name: 'Precio más bajo', value: 'low-price' },
            { name: 'Precio más alto', value: 'high-price' },
        ]
    },
    'fr': {
        name: 'Trier',
        values: [
            { name: 'Conseillé', value: 'recommended' },
            { name: 'Prix le plus bas', value: 'low-price' },
            { name: 'Prix le plus élevé', value: 'high-price' },
        ]
    },
    'it': {
        name: 'Ordina',
        values: [
            { name: 'Consigliato', value: 'recommended' },
            { name: 'Prezzo più basso', value: 'low-price' },
            { name: 'Prezzo più alto', value: 'high-price' },
        ]
    },
};
var getFilters = function (products, locale) {
    if (products === void 0) { products = []; }
    if ((products === null || products === void 0 ? void 0 : products.length) === 0)
        return null;
    var finalFilters = {};
    if (locale) {
        finalFilters['sort'] = __assign(__assign({}, TRANSLATIONS_SORT_BY[locale]), { type: 'multiple' });
    }
    var mergeFilters = function (filters) {
        var e_3, _a;
        try {
            for (var filters_1 = __values(filters), filters_1_1 = filters_1.next(); !filters_1_1.done; filters_1_1 = filters_1.next()) {
                var filter = filters_1_1.value;
                var param = filter.param, name_1 = filter.name, type = filter.type, values = filter.values;
                if (!(param in finalFilters)) {
                    finalFilters[param] = type === 'multiple' ? { name: name_1, type: type, values: values } : { name: name_1, type: type };
                }
                else {
                    if (type === 'multiple') {
                        var mergedValues = __spreadArray(__spreadArray([], __read(finalFilters[param].values), false), __read(values), false);
                        // @ts-ignore
                        var uniqValues = __spreadArray([], __read(new Map(mergedValues.map(function (item) { return [item.value, item]; })).values()), false);
                        finalFilters[param].values = Array.from(uniqValues);
                    }
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (filters_1_1 && !filters_1_1.done && (_a = filters_1.return)) _a.call(filters_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    products.map(function (product) {
        var filters = product.filters;
        if ((filters === null || filters === void 0 ? void 0 : filters.length) > 0)
            mergeFilters(filters);
    });
    return [finalFilters, Object.keys(finalFilters)];
};
exports.getFilters = getFilters;
var updateActiveFilters = function (currentFilters, filter) {
    var _a, _b, _c;
    var incomingFilters = __assign({}, currentFilters);
    var param = incomingFilters[filter.param];
    if (filter.param === 'sort') {
        if (param && param[filter.value]) {
            delete incomingFilters[filter.param];
        }
        else {
            incomingFilters = __assign(__assign({}, incomingFilters), (_a = {}, _a[filter.param] = (_b = {}, _b[filter.value] = true, _b), _a));
        }
    }
    else if (param) {
        if (filter.type === 'simple') {
            delete incomingFilters[filter.param];
        }
        else {
            param[filter.value] ? delete param[filter.value] : param[filter.value] = true;
        }
    }
    else {
        if (filter.type === 'simple') {
            incomingFilters[filter.param] = true;
        }
        else {
            incomingFilters[filter.param] = (_c = {}, _c[filter.value] = true, _c);
        }
    }
    return incomingFilters;
};
exports.updateActiveFilters = updateActiveFilters;
var generateQueryParams = function (filters) {
    if (Object.keys(filters).length < 1)
        return {};
    var queryParams = {};
    var stringParams = '';
    for (var key in filters) {
        if (typeof filters[key] === 'boolean') {
            queryParams[key] = 'true';
            stringParams += "".concat(stringParams.length > 1 ? '&' : '').concat(key, "=true");
        }
        else {
            var values = [];
            for (var value in filters[key]) {
                values.push("".concat(value));
            }
            queryParams[key] = values;
            stringParams += "".concat(stringParams.length > 1 ? '&' : '').concat(key, "=").concat(values.join(','));
        }
    }
    return { queryParams: queryParams, stringParams: stringParams };
};
exports.generateQueryParams = generateQueryParams;
var getFiltersFromQuery = function (url, params) {
    var _a, e_4, _b, _c, e_5, _d, _e;
    var _f, _g;
    var queryFilters = {};
    var urlParams = new URL("https://frontend.rules".concat(url)).searchParams;
    queryFilters['sort'] = (_a = {}, _a[(_f = urlParams.get('sort')) !== null && _f !== void 0 ? _f : 'recommended'] = true, _a);
    try {
        for (var params_1 = __values(params), params_1_1 = params_1.next(); !params_1_1.done; params_1_1 = params_1.next()) {
            var param = params_1_1.value;
            var values = (_g = urlParams.get(param)) === null || _g === void 0 ? void 0 : _g.split(',');
            if (values) {
                if ((values === null || values === void 0 ? void 0 : values.length) === 1) {
                    queryFilters[param] = values[0] === 'true' ? true : (_c = {}, _c[values[0]] = true, _c);
                }
                else {
                    try {
                        for (var values_2 = (e_5 = void 0, __values(values)), values_2_1 = values_2.next(); !values_2_1.done; values_2_1 = values_2.next()) {
                            var value = values_2_1.value;
                            if (queryFilters[param]) {
                                queryFilters[param][value] = true;
                            }
                            else {
                                queryFilters[param] = (_e = {}, _e[value] = true, _e);
                            }
                        }
                    }
                    catch (e_5_1) { e_5 = { error: e_5_1 }; }
                    finally {
                        try {
                            if (values_2_1 && !values_2_1.done && (_d = values_2.return)) _d.call(values_2);
                        }
                        finally { if (e_5) throw e_5.error; }
                    }
                }
            }
        }
    }
    catch (e_4_1) { e_4 = { error: e_4_1 }; }
    finally {
        try {
            if (params_1_1 && !params_1_1.done && (_b = params_1.return)) _b.call(params_1);
        }
        finally { if (e_4) throw e_4.error; }
    }
    return queryFilters;
};
exports.getFiltersFromQuery = getFiltersFromQuery;
var slugMatch = function (arr1, arr2) {
    var arr = arr1;
    if (typeof arr1 === 'string')
        arr = [arr1];
    if (arr.length !== arr2.length)
        return false;
    for (var i = 0; i < arr.length; i++) {
        if (arr[i] !== arr2[i])
            return false;
    }
    return true;
};
exports.slugMatch = slugMatch;
var getFeaturedFilters = function (activeFilters, filters) {
    var incomingfeaturedFilters = [];
    for (var keyparam in activeFilters) {
        var filter = filters === null || filters === void 0 ? void 0 : filters[keyparam];
        if (filter) {
            var _loop_3 = function (keyvalue) {
                var matchFilter = filter.values.filter(function (value) { return value.value === keyvalue; })[0];
                if (matchFilter && matchFilter.media)
                    incomingfeaturedFilters.push(__assign(__assign({}, matchFilter), { param: keyparam }));
            };
            for (var keyvalue in activeFilters[keyparam]) {
                _loop_3(keyvalue);
            }
        }
    }
    return incomingfeaturedFilters;
};
exports.getFeaturedFilters = getFeaturedFilters;
