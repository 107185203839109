import { CategoryInterface, CategoryFetchSuccessAction, CategoryFetchPendingAction, CategoryFetchErrorAction, FETCH_CATEGORY_SUCCESS, FETCH_CATEGORY_PENDING, FETCH_CATEGORY_ERROR } from './category.types'

interface CategoryState {
  pending: boolean,
  data: CategoryInterface,
  error: TypeError
} 

export const InitialState: CategoryState = {
  pending: false,
  data: null,
  error: null
}

const articleFetchPending = (state: CategoryState, _: CategoryFetchPendingAction) => {
  return {
    ...state,
    pending: true,
    error: null
  }
}

const articleFetchSuccess = (state: CategoryState, action: CategoryFetchSuccessAction) => {
  return {
    ...state,
    pending: false,
    error: null,
    data: action.payload
  }
}

const articleFetchError = (state: CategoryState, action: CategoryFetchErrorAction) => {
  return {
    ...state,
    pending: false,
    error: action.payload
  }
}

export const reducers = {
  [FETCH_CATEGORY_PENDING]: articleFetchPending,
  [FETCH_CATEGORY_SUCCESS]: articleFetchSuccess,
  [FETCH_CATEGORY_ERROR]: articleFetchError
}