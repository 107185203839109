import Link from 'next/link'
import { memo } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { AnimatePresence } from 'framer-motion'
import { mq, getRelative, createUnderline } from '@/styles'
import { Header, Main, Body } from './ModalCookiesLayout'
import { useModal, useMount } from '@/hooks'
import { cookie } from '@dy/commons/utils'
import { usei18n } from '@dy/commons/i18n'
// import { Button } from '@/commons'

const Div = styled.div`
  display: flex;
  padding: 0 ${getRelative(40, 'mobile')} ${getRelative(40, 'mobile')};

  ${mq.greaterThan('tablet')`
    padding: 0 ${getRelative(40, 'desktop')} ${getRelative(40, 'desktop')};
  `}

  a {
    color: ${({ theme }) => theme.colors.background};
    cursor: pointer;
    pointer-events: auto;

    ${createUnderline({ reverse: true, color: 'white' })}
  }

  p {
    margin-bottom: 15px;
    padding-right: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  button {
    align-self: center;
    margin: 0 auto;
    white-space: nowrap;
    width: auto;
  }
`

const _Body = styled(Body)`
  max-width: ${getRelative(300, 'mobile')};

  ${mq.greaterThan('tablet')`
    max-width: ${getRelative(400, 'desktop')};
  `}
`

interface IModalCookies {}

const URLS = {
  'es': '/page/politica-de-cookies',
  'en': '/page/politica-de-cookies',
  'it': '/page/politica-de-cookies',
  'fr': '/page/politique-de-confidentialite'
}

const NEXT_LOCALE = process.env.NEXT_PUBLIC_LOCALE
export const modalCookiesId = 'modal-cookies'
export const ModalCookies = memo<IModalCookies>(() => {
  const { render, close, isActive } = useModal(modalCookiesId, false)
  const isMounted = useMount()
  const { layout } = usei18n()
  const { locale } = useRouter() || { locale: NEXT_LOCALE }

  const onClick = () => {
    cookie.set('acceptedCookie', 'true')
    close()
  }

  return isMounted ? render(
    <AnimatePresence exitBeforeEnter>
      <_Body isActive={isActive} >
        <Header text={''} onClick={onClick} />
        <Main>
          <Div>
            <div>
              <p>
              {layout?.modales?.cookies?.cookie_message} <Link href={URLS[locale] || '/'}><a onClick={onClick}>{layout?.modales?.cookies?.here}<span/></a></Link> {layout?.modales?.cookies?.cookie_message_two}.
              </p>
            </div>
            {/* <Button onClick={onClick} bgType={'outline'}>De acuerdo</Button> */}
          </Div>
        </Main>
      </_Body>
    </AnimatePresence>
  ) : <></>
})
