import { memo, useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { Header, Main, Body } from './Layout'
import { useModal, useMount } from '@/hooks'
import { FormNewsletter, parsePlaceHolder } from '@dy/commons/components'
import { mq, getRelative, getMargin20, getP15_1B, getP12_1, getP26, getP35, hex2Rgba } from '@/styles'

const _Body = styled(Body)`
  background-color: white;
  overflow: hidden;

  ${mq.greaterThan('tablet')`
    max-width: ${getRelative(480, 'desktop')};
  `}

  .bg {
    height: 100%;
    left: 0;
    opacity: .2;
    pointer-events: none;
    position: absolute;
    right: 0;
    user-select: none;
    width: 100%;
  }

  header {
    border-bottom: none;
    height: ${getRelative(115, 'mobile')};
    ${getMargin20()}

    ${mq.greaterThan('tablet')`
      height: ${getRelative(130, 'desktop')};
    `}

    h2 {
      align-self: flex-end;
      color: ${({ theme }) => theme.colors.greendark};
      ${getP26()}
      font-family: ${({ theme }) => theme.fonts.platform};

      ${mq.greaterThan('tablet')`
        letter-spacing: .29px;
      `}
    }

    .close {
      align-items: center;
      display: flex;
      background-color: ${({ theme }) => theme.colors.background};
      border-radius: 50%;
      height: ${getRelative(40, 'mobile')};
      justify-content: center;
      margin-top: ${getRelative(20, 'mobile')};
      padding: 0;
      width: ${getRelative(40, 'mobile')};

      ${mq.greaterThan('tablet')`
        height: ${getRelative(40, 'desktop')};
        margin-top: ${getRelative(20, 'desktop')};
        width: ${getRelative(40, 'desktop')};
      `}

      img {
        width: ${getRelative(16, 'mobile')};

        ${mq.greaterThan('tablet')`
          width: ${getRelative(16, 'desktop')};
        `}
      }
    }
  }
`

const _Main = styled(Main)`
  overflow: auto;
  padding-bottom: ${getRelative(70, 'mobile')};

  ${mq.greaterThan('tablet')`
    padding-bottom: ${getRelative(90, 'desktop')};
  `}

  form {
    padding: 0 ${getRelative(40, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding: 0 ${getRelative(50, 'desktop')};
    `}

    legend {
      display: none;
    }

    p {
      ${getP12_1()}
      margin-bottom: ${getRelative(60, 'mobile')} !important;
      margin-top: ${getRelative(20, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-bottom: ${getRelative(100, 'desktop')} !important;
        margin-top: ${getRelative(30, 'desktop')};
      `}
    }

    .input--newsletter input {
      color: ${({ theme }) => theme.colors.greenlight};
    }

    div {
      > span {
        background-color: ${({ theme }) => theme.colors.greenlight};
      }
    }

    input {
      border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.greenlight, .4)};
      color: ${({ theme }) => theme.colors.greenlight};
      ${getP15_1B()}
      font-family: ${({ theme }) => theme.fonts.basier};
      font-weight: 500;

      ${({ theme }) => parsePlaceHolder(`opacity: .4;`, theme.colors.greenlight)}

      &:hover,
      &:active {
        ${({ theme }) => parsePlaceHolder(`opacity: 1;`, theme.colors.greenlight)}
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
        box-shadow: 0 0 0px 1000px white inset;
        -webkit-text-fill-color: ${({ theme }) => theme.colors.greenlight};
        transition: background-color 5000s ease-in-out 0s;
      }

    }
  }

  button,
  .link {
    align-items: center;
    display: flex;
    background-color: ${({ theme }) => theme.colors.greenlight};
    color: white;
    ${getP15_1B()}
    font-family: ${({ theme }) => theme.fonts.basier};
    font-weight: 500;
    height: ${getRelative(36, 'mobile')};
    justify-content: center;
    margin: 0 auto;
    max-width: calc(100% - ${getRelative(30, 'mobile')});
    min-width: ${getRelative(105, 'mobile')};
    padding: 0 ${getRelative(20, 'mobile')};
    width: fit-content;

    ${mq.greaterThan('tablet')`
      height: ${getRelative(43, 'desktop')};
      min-width: ${getRelative(105, 'desktop')};
      max-width: calc(100% - ${getRelative(40, 'desktop')});
      padding: 0 ${getRelative(20, 'desktop')};
    `}
  }

  .link {
    border-radius: 90px;
  }

  .button__bg {
    background-color: ${({ theme }) => theme.colors.greenlight};
  }
`

const Div = styled.div`
  margin-bottom: ${getRelative(30, 'mobile')};
  padding: 0 ${getRelative(30, 'mobile')};
  width: 100%;

  ${mq.greaterThan('tablet')`
    margin-bottom: ${getRelative(30, 'desktop')};
    padding: 0 ${getRelative(30, 'desktop')};
  `}

  h1, h2, h3, h4, h5, h6,
  p, q, ul, ol, dl {
    color: ${({ theme }) => theme.colors.orange};
    ${getP35()}
    font-family: ${({ theme }) => theme.fonts.basier};
  }

  h1, h2, h3, h4, h5, h6,
  p, q {
    text-align: center;
  }
`

type TypeModalCustom = {
  id?: string,
  data: {
    type: 'regular' | 'newsletter',
    target: 'global',
    title: string,
    text: string,
    link?: {
      title: string,
      path: string
    }
  }
}

export const modalCustomId = 'modal-custom'
export const ModalCustom = memo<TypeModalCustom>(({ id = modalCustomId, data }) => {
  const { title, text, type, link } = data

  const { render, close, isActive } = useModal(id)
  const isMounted = useMount()
  const [currentLevel] = useState('initial')

  return isMounted ? render(
    <AnimatePresence exitBeforeEnter>
      <_Body framerKey={currentLevel + isActive} isActive={isActive}>
        <figure className='bg'>
          <img src='/images/svg/modal-custom-bg.svg' alt='Background' />
        </figure>
        <Header text={title} onClick={close} />
        <_Main>
          <Div dangerouslySetInnerHTML={{ __html: text }}></Div>
          {link && <a className='link' href={link.path}>{link.title}</a>}
          {type === 'newsletter' && <FormNewsletter />}
        </_Main>
      </_Body>
    </AnimatePresence>
  ) : <></>
})
