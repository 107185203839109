import { memo, ReactNode } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, getRelative, getP20, getP15, getTransition } from '@/styles'

const _Header = styled.header`
  height: ${getRelative(60, 'mobile')};
  display: grid;
  grid: auto / 1fr 4fr 1fr;
  height: ${getRelative(40, 'mobile')};
  position: relative;
  padding: 0 ${getRelative(20, 'mobile')};
  pointer-events: none;
  width: 100%;

  ${mq.greaterThan('tablet')`
    height: ${getRelative(40, 'desktop')};
    padding: 0 ${getRelative(20, 'desktop')};
  `}


  h2 {
    ${getP20()}
    grid-area: 1 / 2 / span 1 / span 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .prev {
    grid-area: 1 / 1 / span 1 / span 1;
    justify-self: flex-start;
    padding-right: 50px;
  }

  .close {
    cursor: pointer;
    grid-area: 1 / 3 / span 1 / span 1;
    justify-self: flex-end;
    margin-top: ${getRelative(20, 'mobile')};
    pointer-events: auto;

    ${mq.greaterThan('tablet')`
      margin-top: ${getRelative(20, 'desktop')};
    `}

    figure {
      align-items: center;
      display: flex;
      height: 100%;
      min-width: 10px;
      width: auto;
    }

    img {
      min-width: 10px;
      width: ${getRelative(10, 'mobile')};

      ${mq.greaterThan('tablet')`
        width: ${getRelative(10, 'desktop')};
      `}
    }
  }
`

export const Aside = styled.aside<any>`
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 150;

  ${({ isActive  }) => !isActive && `pointer-events: none; user-select: none`}
`

interface IHeader {
  text?: string,
  className?: string,
  onClick: any,
  onClickPrev?: any,
}

export const Header = memo<IHeader>(({ text, onClick, className = '', onClickPrev = null }) => {
  return (
    <_Header className={className}>
      {onClickPrev ? <button className='prev' onClick={onClickPrev}><img src='/images/svg/i--go-back.svg' alt='crbackoss icon'/></button> : <></>}
      {text && <h2>{text}</h2>}
      <button className='close' onClick={onClick}><img src='/images/svg/i--close--white.svg' alt='Icon close modal' width='16' height='16'/></button>
    </_Header>)
})

const _Main = styled.main`
  background-color: ${({ theme }) => theme.colors.greendark};
  flex-grow: 2;
  overflow: auto;
  pointer-events: auto;
  width: 100%;
`

interface IMain {
  className?: string,
  children: ReactNode
}

export const Main = memo<IMain>(({ children, className = '' }) => {
  return (
    <_Main className={className}>
      {children}
    </_Main>)
})

const _Body = styled(motion.div)<any>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.greendark};
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  margin-bottom: 40px;
  margin-top: 0;
  max-width: 90vw;
  overflow: auto;
  pointer-events: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 100%;
  z-index: 10;

  ${mq.greaterThan('tablet')`
    margin-bottom: 0;
    max-width: ${getRelative(650, 'desktop')};
    width: 100%;
  `}

  main {
    opacity: ${({ isActive }) => isActive ? 1 : 0};
    transition: opacity 300ms 50ms ${({ theme }) => theme.ease};
  }

  p {
    ${getP15()}
    font-family: ${({ theme }) => theme.fonts.basier};
    font-weight: 400;
  }
`

const variants_div = {
  animate: {
    x: '0%',
    transition: {
      ...getTransition(),
    }
  },
  exit: {
    x: '100%',
    transition: {
      ...getTransition(),
    }
  },
}

interface IBody {
  className?: string,
  children: ReactNode,
  isActive: boolean
}

export const Body = memo<IBody>(({ children, className = '', isActive }) => {
  return (
    <_Body isActive={isActive} initial='exit' animate={isActive ? 'animate' : 'exit'} variants={variants_div} className={className}>
      {children}
    </_Body>)
})
