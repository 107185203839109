"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.INPUT_PATTERNS = void 0;
__exportStar(require("./InputCheckbox"), exports);
__exportStar(require("./InputSearch"), exports);
__exportStar(require("./InputSelect"), exports);
__exportStar(require("./Select"), exports);
__exportStar(require("./useCheckbox"), exports);
__exportStar(require("./useInput"), exports);
__exportStar(require("./Input"), exports);
__exportStar(require("./useSelect"), exports);
__exportStar(require("./InputSwitch"), exports);
__exportStar(require("./InputSwitchTwo"), exports);
__exportStar(require("./InputValidator"), exports);
__exportStar(require("./PasswordEye"), exports);
__exportStar(require("./TextArea"), exports);
__exportStar(require("./RadioCheckBoxes"), exports);
exports.INPUT_PATTERNS = {
    'password': /(?=.*[a-zA-Z])((?=.*[0-9])|(?=.*([[:print:]][^[[:alnum:]]]))).{8,}/,
    'email': /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    'phone': /([+][3][4][679]{1}[0-9]{8})/,
    'password_message': 'Debe tener entre más de 6 caracteres, y debe incluir al menos un número y una letra',
    'email_message': 'Por favor, introduzca un email válido',
    'phone_message': 'Por favor, introduzca un teléfono español sin espacios (p.e. +346xxxxxxxx)'
};
