import { MediaInterface } from '../types/media/media.types'

enum LinkType {
  simple = 'simple',
  dropdown = 'dropdown',
  button = 'button'
}

enum UrlType {
  internal = 'internal',
  external = 'external'
}

export interface UrlInterface {
  type: UrlType,
  path?: string,
  page?: string,
  slug?: string,
  params?: {
    [paramName: string]: number | string;
  }
}

export interface LinkInterface {
  type: LinkType,
  title: string,
  url?: UrlInterface,
  links: [LinkInterface]
}

export interface PrefooterInterface {
  title: string,
  description: string,
  url: string
}

export interface LayoutInterface {
  navbar: {
    logo: MediaInterface,
    links: [LinkInterface]
  },
  prefooter: [PrefooterInterface],
  footer: {
    logo: MediaInterface,
    text: string
  },
  floating: {
    type: string,
    alt: string,
    url: string,
    media: MediaInterface
  }
}

export const FETCH_LAYOUT_PENDING = 'LAYOUT/FETCH_PENDING'
export const FETCH_LAYOUT_SUCCESS = 'LAYOUT/FETCH_SUCCESS'
export const FETCH_LAYOUT_ERROR   = 'LAYOUT/FETCH_ERROR'

export interface LayoutFetchSuccessAction {
  type: typeof FETCH_LAYOUT_SUCCESS,
  payload: LayoutInterface
}

export interface LayoutFetchPendingAction {
  type: typeof FETCH_LAYOUT_PENDING,
  payload: boolean
}

export interface LayoutFetchErrorAction {
  type: typeof FETCH_LAYOUT_ERROR,
  payload: TypeError
}