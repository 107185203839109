import { useMemo } from 'react'
import styled from 'styled-components'
import { ContentImage, ContentText, ContentVideo } from './content-types'

const Article = styled.article`
  display: flex;
  justify-content: center;

  &.media {
    margin: 0 auto;
  }
`

const MediaTypes = {
  'image': (data) => (<ContentImage data={data} />),
  'video': (data) => (<ContentVideo data={data} />)
}

const ContentsTypes = {
  'text': (data) => (<ContentText data={data} />),
  'media': (data) => (MediaTypes[data.media.type](data)),
  'empty': () => (<div className='empty'></div>),
  'spacer': () => (<div className='spacer'></div>)
}

const getContentByType = (content) => {
  const { type } = content || {}
  if (!content || !type || !ContentsTypes[type]) return <></>
  return (ContentsTypes[type](content))
}

export const Content = ({ content }) => {
  const ContentByType = useMemo(() => getContentByType(content), [content])
  return (
    <Article className={content.type}>
      {ContentByType}
    </Article>
  )
}
