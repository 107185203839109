import { memo } from 'react'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { Main, Body } from './Layout'
import { useModal, useMount } from '@/hooks'
import { mq, getRelative } from '@/styles'
import { ContentVideo } from '../contents/content-types/ContentVideo'

const ButtonClose = styled.button`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  padding: ${getRelative(20, 'mobile')};
  right: ${getRelative(40, 'mobile')};
  top: 0;
  position: absolute;
  width: auto;
  z-index: 5;

  ${mq.greaterThan('tablet')`
    top: ${getRelative(40, 'desktop')};
    right: ${getRelative(40, 'desktop')};
    padding: ${getRelative(40, 'desktop')};
  `}

  img {
    display: inline-block;
    height: 20px;
    width: 20px;
  }
`

const _Body = styled(Body)`
  background: transparent;
  height: 56.25vw;
  margin: auto;
  max-height: none;
  max-width: none;
  min-height: auto;
  min-width: auto;
  width: 100%;

  ${mq.greaterThan('tablet')`
    margin: ${getRelative(80, 'desktop')};
  `}
`

const _Main = styled(Main)`
  max-height: none;
`


interface IModalVideo {
  id: string,
  platform: string,
  data: any
}

export const modalVideoId = 'modal-video'
export const ModalVideo = memo<IModalVideo>(({ id, platform, data }) => {
  const { render, close, isActive } = useModal(`${modalVideoId}_${platform}_${id}`)
  const isMounted = useMount()

  const onClick = () => {
    close()
  }

  return isMounted ? render(
    <AnimatePresence exitBeforeEnter>
      <_Body framerKey={id as string} isActive={isActive}>
        <_Main>
          <ButtonClose key={id} className='close' onClick={onClick}>
            <img src='/images/svg/i--close--white.svg' alt='cross icon' width='16' height='16'/>
          </ButtonClose>
          <ContentVideo data={data} longModal={true} modalActive={isActive}/>
        </_Main>
      </_Body>
    </AnimatePresence>
  ) : <></>
})
