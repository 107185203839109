const d = new Date()
let year = d.getFullYear()
const priceValidUntil = new Date(`31 December ${year} 23:50 UTC`).toISOString()
const NEXT_PUBLIC_HOSTNAME_CLIENT = process.env.NEXT_PUBLIC_HOSTNAME_CLIENT

export const product = (data, region = 'es', lang = 'es', productPage = true, gridUrl = null) => {
  const { brand, name, description, filters, variant, variants, slug:dataSlug, sku, pack } = data;
  const currentVariant = variant ?? variants[0]
  // const media = currentVariant?.media
  const image = currentVariant?.media?.[0]?.grid
  const slug = typeof dataSlug === 'string' ? dataSlug : dataSlug[lang]
  const url = `https://${NEXT_PUBLIC_HOSTNAME_CLIENT}/${productPage ? `product/${slug}` : `shop/${gridUrl}#${slug}`}`

  const offers = (item) => ({
    "@type": "Offer",
    ...(slug && { "url": url }),
    "priceCurrency": `${region === 'GB' ? 'GBP' : item?.prices?.currency ?? 'EUR'}`,
    ...(item.prices && { "price": `${item.prices.price_inc_tax}` }),
    "priceValidUntil": `${item.price?.validUntil}` ?? priceValidUntil,
    "itemCondition": "https://schema.org/NewCondition",
    "availability": `https://schema.org/${item.stock < 1 ? 'OutOfStock' : 'InStock'}`,
    "inventoryLevel": `${item.stock}`,
    "sku": `${item.sku}`,
    ...(item.ean && item.ean !== 'undefined' && { "gtin13": `${item.ean}` }),
    "seller": {
      "@type": "Organization",
      "name": "Hellomamma",
    }
  })

  const productSchema = {
    ...(productPage && { "@context": "https://schema.org/" }),
    "@type": `${pack ? 'ProductGroup' : 'Product'}`,
    "name": `${name}`,
    ...(image && { "image": `${image.default['2x'].regular}` }),
    // ...(media && { "image": media.map((img) => `${img.grid.default['2x'].regular}`) }),
    ...(description && { "description": `${description}` }),
    ...(filters && { "filters": `${filters}` }),
    ...(data.mpn && { "mpn": `${data.mpn}` }),
    ...(data.gtin && data.gtin !== 'undefined' && { "gtin13": `${data.gtin}` }),
    ...(slug && { "url": url }),
    ...(brand && { "brand": { "@type": "Brand", "name": `${brand.name}` }}),
    "sku": `${variant?.sku ?? sku}`,
    // ...(variant && { "isVariantOf": `${bigcommerce_id}` }),                               // variant product only
    ...(variants && { "hasVariant": variants.map((item) => `${item.bigcommerce_id}` )}),  // parent product only
    ...(variant && { "offers": offers(variant) }),                                        // variant product only
    ...(variants && { "offers": variants.map((item) => offers(item)) })                   // parent product only
  }

  return productPage ? JSON.stringify(productSchema) : productSchema
}
